import { useState, useEffect, useMemo } from 'react';
import {
    Badge,
    Box,
    spacing,
    Button,
    Paragraph,
    ActionMenu,
    ConfirmationDialog,
} from '@pelpr/pelpr-ui';
import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { isEmpty } from 'src/utilities/helpers';
import { AssessmentListDataType, Filters } from 'src/modal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    getAllAssessments,
    publishAssessment,
    removeAssessment,
    unpublishAssessment,
    getUpdatedAssessment,
} from 'src/redux/assessments/assessmentsApi';
import AssessmentsTableSkeleton from './AssessmentsTableSkeleton';

import { ReactComponent as EyeOpened } from 'src/assets/vectors/commons/eye-opened-icon.svg';
import { ReactComponent as EyeClosed } from 'src/assets/vectors/commons/eye-closed-icon.svg';
import Table from '../common/tables/table';

type AssessmentStatus = 'published' | 'un-published' | 'delete';

const AssessmentsTable = () => {
    const { assessmentCategories } = useAppSelector((state) => state.app.lookups);
    const { assessments, loading, assessmentPublishing, assessmentUpdating } =
        useAppSelector((state) => state.assessments);
    const [showDeletedAssessments, setShowDeletedAssessments] = useState(false);
    const [openedMenu, setOpenedMenu] = useState('');
    const [showConfirmAlert, setShowConfirmAlert] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [assessmentData, setAssessmentData] = useState<{
        id: string;
        type: AssessmentStatus;
    } | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            getAllAssessments({
                includeDeleted: showDeletedAssessments,
            }),
        );
    }, [showDeletedAssessments]);

    const handleConfirmDialogOpen = (id: string, type: AssessmentStatus) => {
        setAssessmentData({
            id,
            type,
        });
        setShowConfirmAlert(true);
    };

    const handleDeleteDialogOpen = (id: string, type: AssessmentStatus) => {
        setAssessmentData({
            id,
            type,
        });
        setIsDeleteModalOpened(true);
    };

    const handlePublishUnPublishedAssessment = () => {
        const assessmentVersion = assessments?.find(
            (ass) => ass?.id === assessmentData?.id,
        )?.version;
        if (assessmentVersion) {
            if (assessmentData?.type === 'published') {
                dispatch(
                    publishAssessment({
                        quizId: assessmentData.id as string,
                        version: assessmentVersion,
                        onSuccess: () => {
                            dispatch(getUpdatedAssessment(assessmentData?.id));
                            setAssessmentData(null);
                            setShowConfirmAlert(false);
                        },
                    }),
                );
            } else if (assessmentData?.type === 'un-published') {
                dispatch(
                    unpublishAssessment({
                        quizId: assessmentData.id as string,
                        version: assessmentVersion,
                        onSuccess: () => {
                            dispatch(getUpdatedAssessment(assessmentData?.id));
                            setAssessmentData(null);
                            setShowConfirmAlert(false);
                        },
                    }),
                );
            }
        }
    };

    const handleDeleteAssessment = () => {
        const assessmentVersion = assessments?.find(
            (ass) => ass?.id === assessmentData?.id,
        )?.version;
        if (assessmentData?.type === 'delete' && assessmentVersion) {
            dispatch(
                removeAssessment({
                    quizId: assessmentData.id as string,
                    version: assessmentVersion,
                    onSuccess: () => {
                        setAssessmentData(null);
                        setIsDeleteModalOpened(false);
                    },
                }),
            );
        }
    };

    const handleCloseAlert = () => {
        setAssessmentData(null);
        setIsDeleteModalOpened(false);
        setShowConfirmAlert(false);
    };

    const statusFilterFn: FilterFn<AssessmentListDataType> = (
        row,
        columnId,
        filterValue,
    ) => {
        if (!isEmpty(filterValue)) {
            if (filterValue.includes(row.renderValue(columnId))) return true;
            else return false;
        }
        return true;
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const columns = useMemo<ColumnDef<AssessmentListDataType, any>[]>(
        () => [
            {
                accessorFn: (row) => row.name,
                id: 'title',
                cell: (info) => (
                    <Box>
                        <Paragraph
                            weight='semi-bold'
                            sx={{
                                width: '100%',
                                maxWidth: '270px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {info.getValue()}
                        </Paragraph>
                    </Box>
                ),
                header: 'Title',
                accessorKey: 'title',
                enableResizing: false,
                size: 300,
            },
            {
                accessorFn: (row) =>
                    assessmentCategories?.find(
                        (assessmentCategory) =>
                            assessmentCategory?.value === row?.quizCategory,
                    )?.label,
                id: 'category',
                header: 'Category',
                cell: (info) => info.getValue(),
                filterFn: statusFilterFn,
                accessorKey: 'category',
                enableResizing: false,
                size: 150,
            },
            {
                accessorFn: (row) => row?.totalQuestions,
                id: 'questions',
                cell: (info) => info.getValue(),
                header: 'Questions',
                accessorKey: 'questions',
                enableResizing: false,
                size: 100,
            },
            {
                accessorFn: (row) => (row.isPublished ? 'Published' : 'Unpublished'),
                id: 'status',
                header: 'Status',
                cell: (info) =>
                    info?.getValue() === 'Published' ? (
                        <Badge variant='green' text='Published' CustomIcon={EyeOpened} />
                    ) : (
                        <Badge
                            text='Unpublished'
                            variant='neutral'
                            CustomIcon={EyeClosed}
                        />
                    ),
                filterFn: statusFilterFn,
                filterable: true,
                enableSorting: false,
                accessorKey: 'status',
                enableResizing: false,
                size: 100,
            },
            {
                accessorFn: (row) => row.tags,
                id: 'tags',
                header: () => <div style={{ textAlign: 'center' }}>Tags</div>,
                cell: (info) => (
                    <Box
                        sx={{
                            width: 300,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: spacing(1),
                        }}>
                        {info
                            ?.getValue()
                            ?.map((tag: string, index: number) => (
                                <Badge key={index} text={tag} variant='neutral' />
                            ))}
                    </Box>
                ),

                filterable: true,
                enableSorting: false,
                accessorKey: 'tags',
                enableResizing: false,
                size: 250,
            },
            {
                accessorFn: (row) => [row?.id, row?.isPublished, row?.isDeleted],
                id: 'actions',
                header: () => 'Actions',
                cell: (info) => {
                    const value = [...info.getValue()];
                    const id = value[0];
                    const isPublished = value[1];
                    const isDeleted = value[2];
                    return (
                        <ActionMenu
                            alignment='bottom-left'
                            isOpen={openedMenu === id}
                            openMenuHandler={() => setOpenedMenu(id)}
                            closeMenuHandler={(event: MouseEvent | TouchEvent) => {
                                const isMenuIcon = (
                                    event?.target as unknown as HTMLInputElement
                                )?.classList.contains('open-menu');

                                if (id === openedMenu && !isMenuIcon) setOpenedMenu('');
                            }}>
                            <Button
                                variant='ghost'
                                disabled={isDeleted}
                                CustomIcon={isPublished ? EyeClosed : ''}
                                icon={isPublished ? '' : 'view'}
                                onClick={() =>
                                    handleConfirmDialogOpen(
                                        id,
                                        isPublished ? 'un-published' : 'published',
                                    )
                                }>
                                {isPublished ? 'Un-publish' : 'Publish'}
                            </Button>
                            <Button
                                disabled={isDeleted}
                                variant='ghost'
                                href={`/assessments/${id}`}
                                icon='edit'>
                                Edit Assessment
                            </Button>
                            <Button
                                variant='ghost'
                                icon='delete'
                                disabled={isPublished || isDeleted}
                                onClick={() => handleDeleteDialogOpen(id, 'delete')}>
                                Delete Assessment
                            </Button>
                        </ActionMenu>
                    );
                },
                enableResizing: false,
                size: 100,
            },
        ],
        [openedMenu],
    );

    if (loading) return <AssessmentsTableSkeleton />;

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filters: Filters<any>[] = [
        {
            id: 'status',
            label: 'Status',
            column: 'isPublished',
            size: 'small',
            data: [
                { value: 1, label: 'Published', deprecated: false },
                { value: 0, label: 'Unpublished', deprecated: false },
            ],
        },
        {
            id: 'category',
            label: 'Category',
            column: 'quizCategory',
            size: 'small',
            data: assessmentCategories,
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Table
                data={assessments}
                columns={columns}
                filters={filters}
                showDeleted={showDeletedAssessments}
                setShowDeleted={setShowDeletedAssessments}
            />
            {showConfirmAlert && (
                <ConfirmationDialog
                    uniqueKey='publish-assessment'
                    actionType='submit'
                    onConfirm={handlePublishUnPublishedAssessment}
                    onDeny={handleCloseAlert}
                    loading={assessmentPublishing}
                    show={showConfirmAlert}
                    title={
                        assessmentData?.type === 'published'
                            ? 'Publish assessment'
                            : 'Un-publish assessment'
                    }
                    cancelBtnLabel='Cancel'
                    submitBtnLabel={
                        assessmentData?.type === 'published' ? 'Publish' : 'Un-publish'
                    }>
                    {assessmentData?.type === 'published'
                        ? 'Are you want to publish the assessment?'
                        : 'Are you want to un-published the assessment?'}
                </ConfirmationDialog>
            )}
            {isDeleteModalOpened && (
                <ConfirmationDialog
                    uniqueKey='delete-member-confirmation'
                    actionType='delete'
                    onConfirm={handleDeleteAssessment}
                    onDeny={handleCloseAlert}
                    loading={assessmentUpdating}
                    show={isDeleteModalOpened}
                    title='Are you want to delete?'
                    cancelBtnLabel='Cancel'
                    submitBtnLabel='Delete'>
                    {`Are you sure to delete this assessment?`}
                </ConfirmationDialog>
            )}
        </Box>
    );
};

export default AssessmentsTable;
