import { useState, useMemo, useEffect } from 'react';
import {
    ActionMenu,
    Badge,
    Box,
    Button,
    Headline,
    Paper,
    Paragraph,
    Select,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { formatDate } from 'src/utilities/date';
import { CandidateExperienceLevel } from 'src/config/lookups';
import {
    setCandidateExperienceLevel,
    setCandidateTopTalent,
} from 'src/redux/candidate/candidateApi';
import CandidateAddToJobModal from './CandidateAddToJobModal';

//icons
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';
import { ReactComponent as MedalOutlineIcon } from 'src/assets/vectors/commons/medal-star-icon.svg';
import { ReactComponent as MedalFillIcon } from 'src/assets/vectors/commons/medal-star-fill-icon.svg';
import { ReactComponent as ResetIcon } from 'src/assets/vectors/commons/reset-icon.svg';
import CandidateProfileStatusResetModal from '../CandidateProfileStatusResetModal';
import { roundNumber } from 'src/utilities/formattingHelpers';
import CopyToClipboard from 'src/components/common/CopyToClipboard';

const experienceOptions = CandidateExperienceLevel.map((item) => ({
    ...item,
    value: item.value.toString(),
}));

type modalType = 'reset';

const CandidateDetailsSidePanel = () => {
    const { candidateDetails, candidateProfileUpdating, updatingCandidateProfileStatus } =
        useAppSelector((state) => state.candidate);
    const [imageLoadError, setImageLoadError] = useState(false);
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
    const [experienceLevel, setExperienceLevel] = useState('');
    const [openAddJobModal, setOpenAddJobModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [candidateData, setCandidateData] = useState<{
        id: string;
        type: modalType;
    } | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (candidateDetails?.experienceLevel) {
            const experienceLabelValue = CandidateExperienceLevel?.find(
                (item) =>
                    item?.label?.toLowerCase() ===
                    candidateDetails?.experienceLevel?.toLowerCase(),
            )?.value;

            setExperienceLevel(experienceLabelValue?.toString() || '');
        }
    }, [candidateDetails?.experienceLevel]);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    const experienceLabel = useMemo(() => {
        return (
            candidateDetails?.experienceLevel ||
            CandidateExperienceLevel?.find(
                (item) => item?.value === Number(experienceLevel),
            )?.label ||
            ''
        );
    }, [experienceLevel, candidateDetails]);

    const handleSetTopTalent = () => {
        candidateDetails?.id &&
            dispatch(
                setCandidateTopTalent({
                    candidateId: candidateDetails?.id,
                    isTopTalent: candidateDetails?.isTopTalent,
                }),
            );
    };

    const handleExperienceChange = (newValue: string) => {
        setExperienceLevel(newValue);
        handleSetExperienceLevel(newValue);
    };

    const handleSetExperienceLevel = (newExperienceLevel: string) => {
        candidateDetails?.id &&
            dispatch(
                setCandidateExperienceLevel({
                    candidateId: candidateDetails?.id,
                    experienceLevel: Number(newExperienceLevel),
                }),
            );
    };

    const handleConfirmModalOpen = (id: string, type: modalType) => {
        setCandidateData({
            id,
            type,
        });
        setShowConfirmModal(true);
    };

    const handleClose = () => {
        setCandidateData(null);
        setShowConfirmModal(false);
    };

    return (
        <>
            {' '}
            <Paper
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    minWidth: '400px',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                    padding: spacing(6),
                    borderRadius: spacing(1.25),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(4),
                        }}>
                        <img
                            alt='User picture'
                            src={imageLoadError ? Avatar : candidateDetails?.photoUrl}
                            width={88}
                            height={88}
                            style={{
                                borderRadius: '5px',
                            }}
                            onError={handleImageError}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            }}>
                            <Headline variant='h5'>
                                {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
                            </Headline>
                            <Paragraph
                                sx={{
                                    marginTop: spacing(1),
                                }}>
                                {candidateDetails?.basicInformation?.headline}
                            </Paragraph>
                            <Paragraph>
                                {`Registered on ${formatDate(
                                    (candidateDetails?.metadata?.[
                                        'Joining date'
                                    ] as string) || `${new Date()}`,
                                )}`}
                            </Paragraph>
                        </Box>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            padding: `${spacing(1)} ${spacing(1)} 0 ${spacing(1)}`,
                        }}
                        variant='ghost'
                        disabled={candidateProfileUpdating}
                        onClick={handleSetTopTalent}>
                        {!!candidateDetails?.isTopTalent ? (
                            <MedalFillIcon
                                style={{
                                    color: color.neonBlue500,
                                }}
                            />
                        ) : (
                            <MedalOutlineIcon
                                style={{
                                    color: color.midNeutral500,
                                }}
                            />
                        )}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        <Select
                            size='small'
                            sx={{
                                minWidth: '145px',
                            }}
                            placeholder='Experience'
                            name='experienceLevel'
                            options={experienceOptions}
                            selectedOptions={[experienceLevel]}
                            onChange={(value) => handleExperienceChange(value[0])}
                            disabled={candidateProfileUpdating}
                        />
                        <Button
                            disabled={
                                candidateProfileUpdating ||
                                !experienceLevel ||
                                candidateDetails?.metadata?.Featured === 'No'
                            }
                            onClick={() => setOpenAddJobModal(true)}>
                            Add to job
                        </Button>
                    </Box>
                    <ActionMenu
                        alignment='bottom-right'
                        isOpen={isUserMenuOpened}
                        openMenuHandler={() => setIsUserMenuOpened(true)}
                        closeMenuHandler={() => setIsUserMenuOpened(false)}>
                        <Button
                            variant='ghost'
                            CustomIcon={ResetIcon}
                            disabled={updatingCandidateProfileStatus}
                            onClick={() =>
                                handleConfirmModalOpen(
                                    candidateDetails?.id ?? '',
                                    'reset',
                                )
                            }>
                            Reset
                        </Button>
                    </ActionMenu>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Experience
                        </Paragraph>
                        <Paragraph
                            sx={{
                                textTransform: 'capitalize',
                            }}>
                            {experienceLabel || '-'}
                        </Paragraph>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Salary
                        </Paragraph>
                        <Paragraph>
                            {candidateDetails?.jobPreferences.preferredSalaryBase &&
                                `$${roundNumber(
                                    candidateDetails.jobPreferences.preferredSalaryBase,
                                ).toLocaleString('en-IN')}`}{' '}
                            {candidateDetails?.jobPreferences?.preferredHourlyRateBase &&
                                `($${roundNumber(
                                    candidateDetails.jobPreferences
                                        .preferredHourlyRateBase,
                                ).toLocaleString('en-IN')}/hr)`}
                        </Paragraph>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Status
                        </Paragraph>
                        <Paragraph>{candidateDetails?.jobSearchStatus}</Paragraph>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: color.lightNeutral500,
                    }}
                />

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(1),
                    }}>
                    <Paragraph size='medium' weight='semi-bold'>
                        {candidateDetails?.basicInformation?.headline}
                    </Paragraph>
                    <Paragraph weight='regular'>
                        {candidateDetails?.basicInformation?.summary}
                    </Paragraph>
                </Box>

                {candidateDetails?.skills && candidateDetails?.skills?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '1px',
                                backgroundColor: color.lightNeutral500,
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexWrap: 'wrap',
                                gap: spacing(2),
                            }}>
                            {candidateDetails?.skills?.map((skill, index) => (
                                <Badge
                                    key={index}
                                    text={skill}
                                    variant='neonBlue'
                                    sx={{
                                        color: color.darkNeutral300,
                                        backgroundColor: color.neonBlue50,
                                    }}
                                />
                            ))}
                        </Box>
                    </>
                )}
                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: color.lightNeutral500,
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Headline variant='h6'>Contact Details</Headline>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Email
                        </Paragraph>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: spacing(3),
                            }}>
                            <Paragraph>{candidateDetails?.email}</Paragraph>
                            {candidateDetails?.email && (
                                <CopyToClipboard
                                    value={candidateDetails?.email}
                                    label='Copy email to clipboard'
                                    tooltipPlace='left'
                                />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Mobile
                        </Paragraph>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: spacing(3),
                            }}>
                            <Paragraph>
                                {candidateDetails?.mobileNo
                                    ? candidateDetails?.mobileNo
                                    : '-'}
                            </Paragraph>
                            {candidateDetails?.mobileNo && (
                                <CopyToClipboard
                                    value={candidateDetails?.mobileNo}
                                    label='Copy mobile to clipboard'
                                    tooltipPlace='left'
                                />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph
                            size='small'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            Location
                        </Paragraph>
                        <Paragraph>{`${candidateDetails?.city}, ${candidateDetails?.country}`}</Paragraph>
                    </Box>
                    {candidateDetails?.website && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            }}>
                            <Paragraph
                                size='small'
                                sx={{
                                    color: color.midNeutral500,
                                }}>
                                Website
                            </Paragraph>
                            <Paragraph>{candidateDetails?.website}</Paragraph>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: color.lightNeutral500,
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Headline variant='h6'>Additional Properties</Headline>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(2),
                        }}>
                        {candidateDetails?.metadata &&
                            Object.entries(candidateDetails?.metadata)?.map(
                                ([key, value]) => (
                                    <Box
                                        key={key}
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: spacing(4),
                                        }}>
                                        <Paragraph
                                            sx={{
                                                width: '50%',
                                                color: color.midNeutral500,
                                            }}>
                                            {key}
                                        </Paragraph>
                                        <Paragraph
                                            sx={{
                                                width: '50%',
                                            }}>
                                            {value}
                                        </Paragraph>
                                    </Box>
                                ),
                            )}
                    </Box>
                </Box>
            </Paper>
            {openAddJobModal && candidateDetails?.id && (
                <CandidateAddToJobModal
                    open={openAddJobModal}
                    candidateId={candidateDetails?.id}
                    onClose={() => setOpenAddJobModal(false)}
                />
            )}
            {showConfirmModal && candidateData && candidateData?.type === 'reset' && (
                <CandidateProfileStatusResetModal
                    page='candidate-details-page'
                    open={showConfirmModal}
                    onClose={handleClose}
                    candidateId={candidateData?.id}
                />
            )}
        </>
    );
};

export default CandidateDetailsSidePanel;
