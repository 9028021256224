import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    addNewQuestion,
    getAllQuestions,
    getQuestion,
    removeQuestion,
    updateQuestion,
} from './questionApi';
import { QuestionListDataType, QuestionDataType } from 'src/modal';

export interface InitialStateType {
    questions: QuestionListDataType[];
    question: QuestionDataType | null;
    activeQuestionId: number | null;
    questionEditMode: 'add' | 'edit' | null;
    fetchingQuestion: boolean;
    questionUpdating: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: InitialStateType = {
    questions: [],
    question: null,
    activeQuestionId: null,
    questionEditMode: null,
    fetchingQuestion: false,
    questionUpdating: false,
    loading: false,
    error: null,
};

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        resetEditQuestion: (state) => {
            state.question = null;
            state.activeQuestionId = null;
            state.questionEditMode = null;
        },
        setAddEditQuestionMode: (
            state,
            action: PayloadAction<{
                activeQuestionId: number | null;
                questionEditMode: 'add' | 'edit' | null;
            }>,
        ) => {
            state.activeQuestionId = action.payload.activeQuestionId;
            state.questionEditMode = action.payload.questionEditMode;
            if (action.payload.questionEditMode === 'add') {
                state.question = null;
            }
        },
    },
    extraReducers: (builder) => {
        // fetch all questions
        builder.addCase(getAllQuestions.pending, (state) => {
            state.loading = true;
            state.questions = [];
            state.error = null;
        });
        builder.addCase(getAllQuestions.fulfilled, (state, action) => {
            state.questions = action.payload;
            state.loading = false;
        });
        builder.addCase(getAllQuestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch a question
        builder.addCase(getQuestion.pending, (state) => {
            state.fetchingQuestion = true;
            state.error = null;
        });
        builder.addCase(getQuestion.fulfilled, (state, action) => {
            state.question = action.payload;
            state.fetchingQuestion = false;
        });
        builder.addCase(getQuestion.rejected, (state, action) => {
            state.fetchingQuestion = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // add new question
        builder.addCase(addNewQuestion.pending, (state) => {
            state.questionUpdating = true;
            state.error = null;
        });
        builder.addCase(addNewQuestion.fulfilled, (state) => {
            state.questionUpdating = false;
        });
        builder.addCase(addNewQuestion.rejected, (state, action) => {
            state.questionUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // update question
        builder.addCase(updateQuestion.pending, (state) => {
            state.questionUpdating = true;
            state.error = null;
        });
        builder.addCase(updateQuestion.fulfilled, (state) => {
            state.questionUpdating = false;
        });
        builder.addCase(updateQuestion.rejected, (state, action) => {
            state.questionUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // delete question
        builder.addCase(removeQuestion.pending, (state) => {
            state.questionUpdating = true;
            state.error = null;
        });
        builder.addCase(removeQuestion.fulfilled, (state, action) => {
            state.questions = state.questions?.filter(
                (question) => question?.id !== action.payload,
            );
            state.questionUpdating = false;
        });
        builder.addCase(removeQuestion.rejected, (state, action) => {
            state.questionUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });
    },
});

export const { resetEditQuestion, setAddEditQuestionMode } = questionSlice.actions;

export default questionSlice.reducer;
