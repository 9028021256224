import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AddToJobPayloadType, QueryParamType } from 'src/modal';
import {
    addCandidateProfileNoteAPi,
    addCandidateToJob,
    approveCandidateProfileApi,
    fetchCandidates,
    fetchCandidatesApprovals,
    fetchCandidatesProfileNote,
    fetchCandidateAssessments,
    getCandidateDetails,
    rejectCandidateProfileApi,
    resetCandidateProfileStatusApi,
    setCandidateRank,
    updateCandidateExperienceLevel,
} from 'src/services/candidate';

//API method to fetch candidates
export const getCandidatesList = createAsyncThunk(
    'candidate/getCandidatesList',
    async ({ queryParams }: { queryParams: QueryParamType[] }) => {
        try {
            const response = await fetchCandidates(queryParams);
            return response?.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch candidates.');
            throw error.response.data;
        }
    },
);

//API method to fetch candidates approvals list
export const getCandidatesApprovals = createAsyncThunk(
    'candidate/getCandidatesApprovals',
    async () => {
        try {
            const response = await fetchCandidatesApprovals();
            return response?.data?.requests;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch candidates approvals.');
            throw error.response.data;
        }
    },
);

//API method to approve candidate profile
export const approveCandidateProfile = createAsyncThunk(
    'candidate/approveCandidateProfile',
    async ({
        candidateId,
        onSuccess,
    }: {
        candidateId: string;
        onSuccess: () => void;
    }) => {
        try {
            await approveCandidateProfileApi(candidateId);
            onSuccess();
            return candidateId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to approve candidate profile.');
            throw error.response.data;
        }
    },
);

//API method to reject candidate profile
export const rejectCandidateProfile = createAsyncThunk(
    'candidate/rejectCandidateProfile',
    async ({
        candidateId,
        reasonCode,
        onSuccess,
    }: {
        candidateId: string;
        reasonCode: string;
        onSuccess: () => void;
    }) => {
        try {
            await rejectCandidateProfileApi(candidateId, reasonCode);
            onSuccess();
            return candidateId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to reject candidate profile.');
            throw error.response.data;
        }
    },
);

//API method to reset candidate profile's status
export const resetCandidateProfileStatus = createAsyncThunk(
    'candidate/resetCandidateProfileStatus',
    async ({
        candidateId,
        onSuccess,
    }: {
        candidateId: string;
        onSuccess: () => void;
    }) => {
        try {
            await resetCandidateProfileStatusApi(candidateId);
            onSuccess();
            return candidateId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to reset the approval workflow.');
            throw error.response.data;
        }
    },
);

//API method to fetch candidate details
export const fetchCandidateDetails = createAsyncThunk(
    'candidate/fetchCandidateDetails',
    async ({ candidateId }: { candidateId: string }) => {
        try {
            const response = await getCandidateDetails(candidateId);
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch candidate details.');
            throw error.response.data;
        }
    },
);

//API method to set candidate experience level
export const setCandidateExperienceLevel = createAsyncThunk(
    'candidate/setCandidateExperienceLevel',
    async ({
        candidateId,
        experienceLevel,
    }: {
        candidateId: string;
        experienceLevel: number;
    }) => {
        try {
            await updateCandidateExperienceLevel(candidateId, experienceLevel);
            toast.success('Candidate experience level updated.');
            return {
                candidateId,
                experienceLevel,
            };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to update candidate experience level.');
            throw error.response.data;
        }
    },
);

//API method to set candidate as top talent
export const setCandidateTopTalent = createAsyncThunk(
    'candidate/setCandidateTopTalent',
    async ({
        candidateId,
        isTopTalent,
    }: {
        candidateId: string;
        isTopTalent: boolean;
    }) => {
        try {
            await setCandidateRank(candidateId);
            if (isTopTalent) {
                toast.warning('Candidate profile is removed from top talent.');
            } else {
                toast.success('Candidate profile is set as top talent.');
            }

            return {
                candidateId,
            };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            if (isTopTalent) {
                toast.error('Failed to remove candidate profile from top talent.');
            } else {
                toast.error('Failed to set candidate profile as top talent.');
            }

            throw error.response.data;
        }
    },
);

//API method to add candidate to job
export const addCandidateProfileToJob = createAsyncThunk(
    'candidate/addCandidateProfileToJob',
    async ({
        payload,
        onSuccess,
    }: {
        payload: AddToJobPayloadType;
        onSuccess: () => void;
    }) => {
        try {
            const response = await addCandidateToJob(payload);
            toast.success('Candidate Added to job.');
            onSuccess();
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to add candidate to job.');
            throw error.response.data;
        }
    },
);

//API method to get notes on candidate profile
export const getCandidateProfileNote = createAsyncThunk(
    'candidate/getCandidateProfileNote',
    async ({ candidateId }: { candidateId: string }) => {
        try {
            const response = await fetchCandidatesProfileNote(candidateId);
            return response.data.notes;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch notes.');
            throw error.response.data;
        }
    },
);

//API method to add note on candidate profile
export const addCandidateProfileNote = createAsyncThunk(
    'candidate/addCandidateProfileNote',
    async ({
        candidateId,
        note,
        onSuccess,
    }: {
        candidateId: string;
        note: string;
        onSuccess: () => void;
    }) => {
        try {
            const response = await addCandidateProfileNoteAPi(candidateId, note);
            onSuccess();
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to add note.');
            throw error.response.data;
        }
    },
);

//API method to get the list of all the assessments completed by candidate
export const getCandidateAssessments = createAsyncThunk(
    'candidate/getCandidateAssessments',
    async ({
        candidateId,
        onSuccess,
    }: {
        candidateId: string;
        onSuccess?: () => void;
    }) => {
        try {
            const response = await fetchCandidateAssessments(candidateId);
            if (onSuccess) onSuccess();
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch assessments.');
            throw error.response.data;
        }
    },
);
