import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Headline, Paragraph, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchJobApplications } from 'src/redux/job/jobApi';
import { jobApplicationStagesEnum } from 'src/modal';
import JobApplicantCardSkeleton from './JobApplicantCardSkeleton';
import JobApplicantCard from './JobApplicantCard';
import JobApplicantApproveModal from './JobApplicantApproveModal';
import JobApplicantRejectModal from './JobApplicantRejectModal';

type modalType = 'approved' | 'rejected';

const JobApplicationsTab = () => {
    const { jobId } = useParams();
    const { jobApplications, fetchingJobApplications } = useAppSelector(
        (state) => state.job,
    );
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [updateData, setUpdateData] = useState<{
        id: string;
        version: string;
        type: modalType;
    } | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        jobId &&
            dispatch(
                fetchJobApplications({
                    jobId,
                }),
            );
    }, []);

    const handleConfirmModalOpen = (id: string, type: modalType, version: string) => {
        setUpdateData({
            id,
            version: version,
            type,
        });
        setShowConfirmModal(true);
    };

    const handleClose = () => {
        setUpdateData(null);
        setShowConfirmModal(false);
    };

    const systemMatchedApplicants = jobApplications?.filter(
        (application) =>
            application?.jobApplicationStageId === jobApplicationStagesEnum.Matched,
    );
    const qualifiedApplicants = jobApplications?.filter(
        (application) =>
            application?.jobApplicationStageId !== jobApplicationStagesEnum.Matched,
    );

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(1),
                        paddingTop: spacing(6),
                        paddingBottom: spacing(6),
                    }}>
                    <Headline variant='h6'>Pre-Qualified Talent</Headline>
                    <Paragraph>
                        Review system matched talent for accuracy and better results.
                    </Paragraph>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        gap: spacing(6),
                    }}>
                    {fetchingJobApplications &&
                        [1, 2, 3, 4, 5].map((item) => (
                            <JobApplicantCardSkeleton key={item} />
                        ))}
                    {!fetchingJobApplications &&
                        systemMatchedApplicants?.length > 0 &&
                        systemMatchedApplicants?.map((applicant) => (
                            <JobApplicantCard
                                key={applicant?.id}
                                applicantData={applicant}
                                onApprove={handleConfirmModalOpen}
                                onReject={handleConfirmModalOpen}
                            />
                        ))}
                </Box>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(1),
                        paddingTop: spacing(6),
                        paddingBottom: spacing(6),
                    }}>
                    <Headline variant='h6'>Qualified Talent</Headline>
                    <Paragraph>Includes talent matched by system and manually.</Paragraph>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        gap: spacing(6),
                    }}>
                    {fetchingJobApplications &&
                        [1, 2, 3, 4, 5].map((item) => (
                            <JobApplicantCardSkeleton key={item} />
                        ))}
                    {!fetchingJobApplications &&
                        qualifiedApplicants?.length > 0 &&
                        qualifiedApplicants?.map((applicant) => (
                            <JobApplicantCard
                                key={applicant?.id}
                                applicantData={applicant}
                            />
                        ))}
                </Box>
            </Box>

            {showConfirmModal && updateData && updateData?.type === 'approved' && (
                <JobApplicantApproveModal
                    open={showConfirmModal}
                    onClose={handleClose}
                    jobApplicationId={updateData?.id}
                    version={updateData?.version}
                />
            )}
            {showConfirmModal && updateData && updateData?.type === 'rejected' && (
                <JobApplicantRejectModal
                    open={showConfirmModal}
                    onClose={handleClose}
                    jobApplicationId={updateData?.id}
                    version={updateData?.version}
                />
            )}
        </Box>
    );
};

export default JobApplicationsTab;
