export interface JobListDataType {
    id: string;
    reference: string;
    title: string;
    postingDate: string;
    jobType: string;
    workplaceType: string;
    jobLevel: string;
    country: string;
    locations: string[];
    minimumSalary: number;
    maximumSalary: number;
    organization: string;
    organizationLogoUrl: string;
    jobApplicationsCount: number;
    jobApplicationsLimit: number;
}

export interface JobMetadata {
    page: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
}

export interface JobsTopSearchFiltersType {
    search: string;
    country: number;
    city: string;
    sortBy: string;
    page: number | null;
    pageSize: number | null;
}

export interface JobsLeftSearchFiltersType {
    experienceLevel: string[];
    jobType: string[];
    workplaceType: string[];
    companyProfile: string[];
    companySize: string[];
    salaryRange: number[];
}

export interface JobCollaboratorDataType {
    id: string;
    userId: string;
    userName: string;
    userPhotoUrl: string;
    roles: string[];
    createdAt: string;
}
export interface JobDetails {
    id: string;
    code: string;
    reference: string;
    title: string;
    postingDate: string;
    jobTypeId: number;
    jobType: string;
    workplaceTypeId: number;
    workplaceType: string;
    jobLevelId: number;
    jobLevel: string;
    experienceInYears: number;
    countryId: number;
    country: string;
    locations: string[];
    description: string;
    skills: string[];
    salaryTypeId: number;
    salaryType: string;
    salaryTypeCode: string;
    minimumSalary: number;
    maximumSalary: number;
    minimumAnnualSalaryBase: number;
    maximumAnnualSalaryBase: number;
    salaryCurrencyId: number;
    salaryCurrencyCode: string;
    jobPostStatusId: number;
    jobPostStatus: string;
    createdByUser: string;
    organization: string;
    organizationLogoUrl: string;
    packageName: string;
    qualifiedTalentLimit: number;
    createdAt: string;
    updatedAt: string;
    expiresAt: string;
    version: string;
}

export interface JobApplicantDataType {
    id: string;
    candidateId: string;
    candidateName: string;
    candidatePhoto: string;
    shortlisted: boolean;
    matchType: number;
    jobApplicationStageId: number;
    jobApplicationStage: string;
    createdAt: string;
    version: string;
}

export interface JobListingDataType {
    id: string;
    reference: string;
    title: string;
    organizationId: string;
    organization: string;
    postingDate: string;
    workplaceTypeId: number;
    countryId: number;
    locations: string[];
    jobPostStatusId: number;
    jobPostPackage: string;
    createdAt: string;
    updatedAt: string;
}

export enum jobApplicationStagesEnum {
    Qualified = 1,
    Invited = 2,
    Declined = 3,
    Interview = 4,
    Hired = 5,
    Rejected = 6,
    Matched = 7,
}

export enum JobStatusesEnum {
    'Draft' = 1,
    'Pending' = 2,
    'Active' = 3,
    'Expired' = 4,
    'Closed' = 5,
    'Archived' = 6,
}
