import { Box, ConfirmationDialog, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { approveMatchedJobApplication } from 'src/redux/job/jobApi';

interface JobApplicantApproveModalProps {
    open: boolean;
    onClose: () => void;
    jobApplicationId: string;
    version: string;
}

const JobApplicantApproveModal = ({
    open,
    onClose,
    jobApplicationId,
    version,
}: JobApplicantApproveModalProps) => {
    const { updatingJobApplicant } = useAppSelector((state) => state.job);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleApprove = () => {
        dispatch(
            approveMatchedJobApplication({
                jobApplicationId,
                version,
                onSuccess: () => {
                    onClose();
                },
            }),
        );
    };

    return (
        <ConfirmationDialog
            uniqueKey='approve-applicant'
            actionType='submit'
            onConfirm={handleApprove}
            onDeny={handleCancel}
            loading={updatingJobApplicant}
            show={open}
            title='Approve Applicant'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Approve'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Paragraph
                    size='medium'
                    weight='semi-bold'
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    Are sure to approve the applicant?
                </Paragraph>
            </Box>
        </ConfirmationDialog>
    );
};

export default JobApplicantApproveModal;
