import { createSlice } from '@reduxjs/toolkit';
import {
    JobCollaboratorDataType,
    JobListDataType,
    JobMetadata,
    JobDetails,
    JobApplicantDataType,
    JobListingDataType,
} from 'src/modal';
import {
    fetchJobCollaborators,
    fetchJobMetaDataByRef,
    fetchJobsBySearch,
    fetchJobDetails,
    fetchJobApplications,
    approveMatchedJobApplication,
    rejectMatchedJobApplication,
    fetchJobsListing,
} from './jobApi';

export interface InitialStateType {
    jobMetaData: JobListDataType | null;
    jobsSearchData: JobListDataType[];
    metadata: JobMetadata | null;
    jobCollaborators: JobCollaboratorDataType[];
    jobDetails: JobDetails | null;
    jobApplications: JobApplicantDataType[];
    jobsListing: JobListingDataType[];
    fetchingJobMeta: boolean;
    fetchingJobDetails: boolean;
    fetchingJobsSearch: boolean;
    fetchingJobCollaborators: boolean;
    fetchingJobApplications: boolean;
    updatingJobApplicant: boolean;
    fetchingJobsListing: boolean;
    error: string | null;
}

const initialState: InitialStateType = {
    jobMetaData: null,
    jobsSearchData: [],
    metadata: null,
    jobCollaborators: [],
    jobDetails: null,
    jobApplications: [],
    jobsListing: [],
    fetchingJobMeta: false,
    fetchingJobDetails: false,
    fetchingJobsSearch: false,
    fetchingJobCollaborators: false,
    fetchingJobApplications: false,
    updatingJobApplicant: false,
    fetchingJobsListing: false,
    error: null,
};

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //fetch job meta data by job reference
        builder.addCase(fetchJobMetaDataByRef.pending, (state) => {
            state.jobMetaData = null;
            state.fetchingJobMeta = true;
            state.error = null;
        });
        builder.addCase(fetchJobMetaDataByRef.fulfilled, (state, action) => {
            state.jobMetaData = action.payload;
            state.fetchingJobMeta = false;
        });
        builder.addCase(fetchJobMetaDataByRef.rejected, (state, action) => {
            state.fetchingJobMeta = false;
            state.error = action.error.message || 'Something went wrong';
        });

        //fetch jobs by search
        builder.addCase(fetchJobsBySearch.pending, (state) => {
            state.fetchingJobsSearch = true;
            state.error = null;
        });
        builder.addCase(fetchJobsBySearch.fulfilled, (state, action) => {
            state.jobsSearchData = action.payload.items;
            state.jobMetaData = action.payload.metadata;
            state.fetchingJobsSearch = false;
        });
        builder.addCase(fetchJobsBySearch.rejected, (state, action) => {
            state.fetchingJobsSearch = false;
            state.error = action.error.message || 'Something went wrong';
        });

        //fetch job collaborators
        builder.addCase(fetchJobCollaborators.pending, (state) => {
            state.fetchingJobCollaborators = true;
            state.error = null;
        });
        builder.addCase(fetchJobCollaborators.fulfilled, (state, action) => {
            state.jobCollaborators = action.payload;
            state.fetchingJobCollaborators = false;
        });
        builder.addCase(fetchJobCollaborators.rejected, (state, action) => {
            state.fetchingJobCollaborators = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch job details
        builder.addCase(fetchJobDetails.pending, (state) => {
            state.fetchingJobDetails = true;
            state.error = null;
        });
        builder.addCase(fetchJobDetails.fulfilled, (state, action) => {
            state.jobDetails = action.payload;
            state.fetchingJobDetails = false;
        });
        builder.addCase(fetchJobDetails.rejected, (state, action) => {
            state.fetchingJobDetails = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch job applications
        builder.addCase(fetchJobApplications.pending, (state) => {
            state.fetchingJobApplications = true;
            state.error = null;
        });
        builder.addCase(fetchJobApplications.fulfilled, (state, action) => {
            state.jobApplications = action.payload;
            state.fetchingJobApplications = false;
        });
        builder.addCase(fetchJobApplications.rejected, (state, action) => {
            state.fetchingJobApplications = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // approve matched job application
        builder.addCase(approveMatchedJobApplication.pending, (state) => {
            state.updatingJobApplicant = true;
            state.error = null;
        });
        builder.addCase(approveMatchedJobApplication.fulfilled, (state, action) => {
            const previousData = [...state.jobApplications];
            const index = previousData.findIndex((item) => item?.id === action.payload);
            if (index !== -1) {
                previousData[index] = {
                    ...previousData[index],
                    jobApplicationStageId: 1,
                };
                state.jobApplications = previousData;
            }

            state.updatingJobApplicant = false;
        });
        builder.addCase(approveMatchedJobApplication.rejected, (state, action) => {
            state.updatingJobApplicant = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // reject matched job application
        builder.addCase(rejectMatchedJobApplication.pending, (state) => {
            state.updatingJobApplicant = true;
            state.error = null;
        });
        builder.addCase(rejectMatchedJobApplication.fulfilled, (state, action) => {
            const previousData = [...state.jobApplications];
            state.jobApplications = previousData.filter(
                (item) => item?.id !== action.payload,
            );

            state.updatingJobApplicant = false;
        });
        builder.addCase(rejectMatchedJobApplication.rejected, (state, action) => {
            state.updatingJobApplicant = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch jobs Listing
        builder.addCase(fetchJobsListing.pending, (state) => {
            state.fetchingJobsListing = true;
            state.error = null;
        });
        builder.addCase(fetchJobsListing.fulfilled, (state, action) => {
            state.jobsListing = action.payload;
            state.fetchingJobsListing = false;
        });
        builder.addCase(fetchJobsListing.rejected, (state, action) => {
            state.fetchingJobsListing = false;
            state.error = action.error.message || 'Something went wrong';
        });
    },
});

export default jobSlice.reducer;
