import { useState, useRef, ChangeEvent, useEffect } from 'react';
import styled from '@emotion/styled';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    spacing,
    color,
    Headline,
} from '@pelpr/pelpr-ui';

//icons
import IdeaIcon from 'src/assets/vectors/commons/idea-icon.svg';
import { uploadAssessmentPhoto } from 'src/redux/assessments/assessmentsApi';
import { useAppDispatch, useAppSelector } from 'src/hooks';

const AssessmentPhotoEditContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${spacing(4)};
`;

const AssessmentPhotoEditSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(5)};
`;
const AssessmentPhotoEditActionBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${spacing(4)};
`;

const PreviewPhotoContainer = styled.div`
    border-radius: 5px;
    position: relative;
    width: 88px;
    height: 88px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid ${color.lightNeutral500};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export interface AssessmentPhotoEditProps {
    open: boolean;
    onClose: () => void;
}

const EditAssessmentImageModal = ({ open, onClose }: AssessmentPhotoEditProps) => {
    const { assessmentImageUploading, assessment } = useAppSelector(
        (state) => state.assessments,
    );
    const [image, setImage] = useState<string | null>(
        `${assessment?.imageUrl}?nocache=${Date.now()}`,
    );
    const [file, setFile] = useState<File | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setImage(`${assessment?.imageUrl}?nocache=${Date.now()}`);
    }, []);

    const handleCapture = async (e: ChangeEvent<HTMLInputElement>) => {
        setImage(null);
        setImageLoadError(false);
        e.target?.files && setFile(e.target?.files[0]);
        const fileReader = new FileReader();
        e.target?.files && fileReader.readAsDataURL(e.target?.files[0]);
        fileReader.onload = async (f) => {
            const imageData = f.target?.result;
            if (imageData) {
                setIsEdit(true);
                setImage(imageData as string);
            }
        };
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current?.click();
        }
    };

    const handleUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('image', file); // Assuming image is a File or Blob object

            assessment &&
                isEdit &&
                dispatch(
                    uploadAssessmentPhoto({
                        quizId: assessment.id as string,
                        imageData: formData,
                        onSuccess: () => onClose(),
                    }),
                );
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Modal
            uniqueKey='assessment-photo-modal'
            show={open}
            sx={{
                width: '100%',
                maxWidth: '550px',
            }}>
            <ModalHeader>
                <Headline variant='h6'>Edit Assessment Photo</Headline>
            </ModalHeader>
            <ModalBody
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(5),
                }}>
                <AssessmentPhotoEditContainer>
                    <AssessmentPhotoEditSection>
                        <PreviewPhotoContainer>
                            {image && !imageLoadError ? (
                                <img
                                    alt='Assessment Photo'
                                    src={image}
                                    width={88}
                                    height={88}
                                    style={{
                                        borderRadius: '5px',
                                    }}
                                    onError={handleImageError}
                                />
                            ) : (
                                <img
                                    alt='Assessment Photo'
                                    src={IdeaIcon}
                                    width={88}
                                    height={88}
                                    style={{
                                        borderRadius: '5px',
                                    }}
                                />
                            )}
                        </PreviewPhotoContainer>

                        <AssessmentPhotoEditActionBox>
                            <input
                                accept='image/png'
                                ref={fileInputRef}
                                onChange={handleCapture}
                                type='file'
                                style={{ display: 'none' }}
                            />

                            <Button
                                variant='secondary'
                                label='Choose Photo'
                                disabled={assessmentImageUploading}
                                onClick={handleButtonClick}
                            />
                        </AssessmentPhotoEditActionBox>
                    </AssessmentPhotoEditSection>
                </AssessmentPhotoEditContainer>
            </ModalBody>
            <ModalFooter
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: spacing(2),
                }}>
                <Button variant='ghost' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    loading={!!assessmentImageUploading}
                    disabled={assessmentImageUploading}
                    onClick={handleUpload}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditAssessmentImageModal;
