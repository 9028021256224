import * as yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';
import { Box, ConfirmationDialog, Select, Textarea, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { rejectCandidateProfile } from 'src/redux/candidate/candidateApi';

const reasonOptions = [
    { label: 'Not Complete', value: '1' },
    { label: 'Not Suitable', value: '2' },
    { label: 'Not Experienced', value: '3' },
    { label: 'Need Improvement', value: '4' },
    { label: 'Not Genuine', value: '5' },
    { label: 'Other', value: '6' },
];

const validationSchema = yup.object({
    reason: yup.string().required('Reason is required'),
    reasonNote: yup.string().when('reason', {
        is: (reason: string) => reason === '6',
        then: (schema) => schema.required('Reason note is required for "Other"'),
        otherwise: (schema) => schema,
    }),
});

interface formDataType {
    reason: string;
    reasonNote: string;
}

interface CandidateProfileRejectModalProps {
    open: boolean;
    onClose: () => void;
    candidateId: string;
}

const CandidateProfileRejectModal = ({
    open,
    onClose,
    candidateId,
}: CandidateProfileRejectModalProps) => {
    const { updatingCandidateProfileStatus } = useAppSelector((state) => state.candidate);

    const dispatch = useAppDispatch();

    const handleCancel = () => {
        formik.resetForm();
        onClose();
    };

    const submitForm = async (
        values: formDataType,
        { setSubmitting, resetForm }: FormikHelpers<formDataType>,
    ) => {
        dispatch(
            rejectCandidateProfile({
                candidateId,
                reasonCode: values.reason,
                onSuccess: () => {
                    resetForm();
                    setSubmitting(false);
                    onClose();
                },
            }),
        );
    };

    const formik = useFormik({
        initialValues: {
            reason: '',
            reasonNote: '',
        },
        validationSchema: validationSchema,
        onSubmit: submitForm,
    });

    const handleReject = () => {
        formik.handleSubmit();
    };

    return (
        <ConfirmationDialog
            uniqueKey='reject-candidate-profile'
            actionType='delete'
            onConfirm={handleReject}
            onDeny={handleCancel}
            loading={updatingCandidateProfileStatus}
            show={open}
            title='Reject Profile'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Reject'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(5),
                }}>
                <Select
                    sx={{
                        width: '200px',
                    }}
                    label='Rejection Reason'
                    placeholder='Select Option'
                    name='reason'
                    options={reasonOptions}
                    selectedOptions={[formik.values.reason]}
                    onChange={(value) => {
                        formik.setFieldValue('reason', value[0], true);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                    helperText={(formik.touched.reason && formik.errors.reason) || ''}
                    required
                />
                <Textarea
                    sx={{
                        width: '100%',
                        maxWidth: '470px',
                    }}
                    name='reasonNote'
                    rows={5}
                    label='If Other, please specify the details below:'
                    value={formik.values.reasonNote}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                        formik.touched.reasonNote && Boolean(formik.errors.reasonNote)
                    }
                    helperText={
                        (formik.touched.reasonNote && formik.errors.reasonNote) || ''
                    }
                />
            </Box>
        </ConfirmationDialog>
    );
};

export default CandidateProfileRejectModal;
