import { Box, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

interface FilterDataType {
    label: string;
    value: string;
    count: number;
}

interface CandidateApprovalsFiltersProps {
    loading?: boolean;
    statusFilters: FilterDataType[];
    selectedFilter: string;
    onSelect: (value: string) => void;
}

const CandidateApprovalsFilters = ({
    loading,
    statusFilters,
    selectedFilter,
    onSelect,
}: CandidateApprovalsFiltersProps) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: spacing(4),
                paddingBottom: spacing(6),
            }}>
            {loading
                ? [1, 2, 3, 4]?.map((item) => (
                      <Skeleton key={item} width={96} height={34} />
                  ))
                : statusFilters?.map((filter, index) => (
                      <Box
                          key={index}
                          sx={{
                              width: 'fit-content',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: spacing(1),
                              cursor: 'pointer',
                              '&:hover': {
                                  '>div': {
                                      backgroundColor: color.neonBlue500,
                                  },
                              },
                          }}
                          onClick={() => onSelect(filter.value)}>
                          <Paragraph
                              sx={{
                                  lineHeight: spacing(5),
                                  padding: `${spacing(1)} ${spacing(2)}`,
                                  color:
                                      selectedFilter === filter?.value
                                          ? color.neonBlue500
                                          : color.darkNeutral300,
                              }}>
                              {`${filter.label} (${filter?.count})`}
                          </Paragraph>
                          <Box
                              sx={{
                                  width: '100%',
                                  height: spacing(0.5),
                                  backgroundColor:
                                      selectedFilter === filter?.value
                                          ? color.neonBlue500
                                          : 'transparent',
                              }}
                          />
                      </Box>
                  ))}
        </Box>
    );
};

export default CandidateApprovalsFilters;
