import { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Headline,
    PageContainer,
    PageHeader,
    Paper,
    Paragraph,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import CandidateApprovalsFilters from 'src/components/candidates/CandidateApprovalsFilters';
import { getCandidatesApprovals } from 'src/redux/candidate/candidateApi';
import CandidateApprovalsTable from 'src/components/candidates/CandidateApprovalsTable';
import CandidateApprovalsTableSkeleton from 'src/components/candidates/CandidateApprovalsTableSkeleton';

const CandidatesApprovals = () => {
    const { candidatesApprovals, fetchingCandidatesApprovals } = useAppSelector(
        (state) => state.candidate,
    );
    const [statusFilter, setStatusFilter] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCandidatesApprovals());
    }, []);

    const statusFilters = useMemo(() => {
        return [
            {
                label: 'All',
                value: '',
                count: candidatesApprovals?.length,
            },
            {
                label: 'Pending',
                value: 'Pending',
                count: candidatesApprovals?.filter((x) => x.status == 'Pending').length,
            },
            {
                label: 'Approved',
                value: 'Approved',
                count: candidatesApprovals?.filter((x) => x.status == 'Approved').length,
            },
            {
                label: 'Rejected',
                value: 'Rejected',
                count: candidatesApprovals?.filter((x) => x.status == 'Rejected').length,
            },
        ];
    }, [candidatesApprovals]);

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Candidates / Approvals' />}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: spacing(12),
                }}>
                <CandidateApprovalsFilters
                    loading={fetchingCandidatesApprovals}
                    statusFilters={statusFilters}
                    selectedFilter={statusFilter}
                    onSelect={setStatusFilter}
                />
                <Paper
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                        padding: spacing(6),
                        borderRadius: spacing(1.25),
                    }}>
                    <Box>
                        <Headline variant='h6'>Profile Approval Requests</Headline>
                        <Paragraph>Review candidate profiles for approval</Paragraph>
                    </Box>
                    {fetchingCandidatesApprovals ? (
                        <CandidateApprovalsTableSkeleton />
                    ) : (
                        <CandidateApprovalsTable statusFilter={statusFilter} />
                    )}
                </Paper>
            </Box>
        </PageContainer>
    );
};

export default CandidatesApprovals;
