import { useState, useLayoutEffect, createContext, useCallback, ReactNode } from 'react';
import axios from 'axios';
import { useSearchParams, useLocation } from 'react-router-dom';
import qs from 'qs';
import { jwtDecode } from 'jwt-decode';
import { generateCodeChallenge, generateCodeVerifier } from 'src/utilities/helpers';

// URL for authentication
const AUTH_URL = process.env.REACT_APP_AUTH_URI;

// Scopes for authorization
const scopes = (process.env.REACT_APP_USER_SCOPE || '')
    .split(',')
    .map(
        (scope) =>
            !!scope &&
            encodeURIComponent(
                `${process.env.REACT_APP_API_RESOURCE_URI}/${scope.trim()}`,
            ),
    )
    .join('+');

// Function to fetch authentication token
const fetchAuthToken = async (code: string) => {
    try {
        const codeVerifier = localStorage.getItem('codeVerifier');
        if (!codeVerifier) throw new Error('Code verifier not found');
        const data = qs.stringify({
            client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
            code_verifier: codeVerifier,
        });
        const response = await axios.post(`${AUTH_URL}/oauth2/token`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const decodedData = jwtDecode(response.data?.id_token);
        return { ...response.data, ...decodedData };
        /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
        throw error;
    }
};

// Function to fetch refresh authentication token
/* eslint-disable  @typescript-eslint/no-explicit-any */
export let refreshAuthToken: any;

// Function to handle sign in
export const handleSignIn = async () => {
    // Generate code verifier
    const codeVerifier = generateCodeVerifier();
    localStorage.setItem('codeVerifier', codeVerifier);
    // Generate code challenge
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    // Login URL for authentication
    const loginUrl = `${process.env.REACT_APP_AUTH_URI}/oauth2/authorize?client_id=${
        process.env.REACT_APP_COGNITO_CLIENT_ID
    }&response_type=code&scope=aws.cognito.signin.user.admin+openid+${scopes}+profile&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_COGNITO_REDIRECT_URI as string,
    )}&code_challenge_method=S256&code_challenge=${encodeURIComponent(codeChallenge)}`;
    window.location.href = loginUrl;
};

// Function to handle sign out
export const handleSignOut = async () => {
    window.location.href = `${AUTH_URL}/logout?client_id=${
        process.env.REACT_APP_COGNITO_CLIENT_ID
    }&logout_uri=${encodeURIComponent(
        process.env.REACT_APP_COGNITO_LOGOUT_URI as string,
    )}`;

    // Clear localStorage
    window.localStorage.clear();
    window.sessionStorage.clear();
};

export type OnBoardingStatusType = 'Pending' | 'Complete';
export type AccountType = 'Management' | 'Candidate' | 'Recruiter';

// Interface for authentication info data
export interface AuthInfoDataType {
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
    at_hash: string;
    sub: string;
    'cognito:groups': string[];
    email_verified: boolean;
    'custom:organization_id': string;
    iss: string;
    'cognito:username': string;
    given_name: string;
    origin_jti: string;
    aud: string;
    'custom:account_type': string;
    'custom:onboarding_status': string;
    event_id: string;
    token_use: string;
    auth_time: number;
    exp: number;
    iat: number;
    family_name: string;
    jti: string;
    email: string;
}

// Interface for Cognito user data
export interface CognitoUserDataType {
    fullName: string;
    email: string | undefined;
    firstName: string;
    lastName: string;
    username: string;
    accessToken: string | undefined;
    refreshToken: string | undefined;
    idToken: string | undefined;
    accountType: AccountType;
    organizationId: string | undefined;
    onboardingStatus: OnBoardingStatusType;
}

// Interface for authentication info
export interface AuthInfo {
    user: CognitoUserDataType;
    isAuthenticated: boolean;
}

// Interface for authentication state
export interface AuthStateType {
    user: CognitoUserDataType | null;
    isAuthenticated: boolean;
    isLoading: boolean;
    handleSignIn: () => void;
    handleSignOut: () => void;
    updateOnBoardStatus: (status: OnBoardingStatusType) => void;
}

// Context for authentication
export const authContext = createContext<AuthStateType | undefined>(undefined);

// Props for authentication provider
export interface AuthProviderProps {
    children: ReactNode;
}

let isTokenFetching = false;

// Authentication provider component
export const AuthProvider = ({ children }: AuthProviderProps) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [user, setUser] = useState<CognitoUserDataType | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    refreshAuthToken = async () => {
        const authInfo = JSON.parse(localStorage.getItem('authInfo') as string);
        const refreshToken = authInfo?.user?.refreshToken;
        if (!refreshToken) throw new Error();
        try {
            const codeVerifier = localStorage.getItem('codeVerifier');
            if (!codeVerifier) throw new Error('Code verifier not found');
            const data = qs.stringify({
                client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
                code_verifier: codeVerifier,
            });
            const response = await axios.post(`${AUTH_URL}/oauth2/token`, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            const refreshData = response.data;

            if (user) {
                const updatedUser = { ...user, accessToken: refreshData?.access_token };
                setUser(updatedUser);
                localStorage.setItem(
                    'authInfo',
                    JSON.stringify({
                        user: updatedUser,
                        isAuthenticated,
                    }),
                );
            }
            localStorage.setItem('accessToken', refreshData?.access_token);
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            throw error;
        }
    };
    // Function to update onboarding status
    const updateOnBoardStatus = useCallback(
        (status: OnBoardingStatusType) => {
            if (user) {
                const updatedUser = { ...user, onboardingStatus: status };
                setUser(updatedUser);
                localStorage.setItem(
                    'authInfo',
                    JSON.stringify({
                        user: updatedUser,
                        isAuthenticated,
                    }),
                );
            }
        },
        [user, setUser, isAuthenticated],
    );

    // Function to fetch authentication user info
    const handleFetchAuthUserInfo = useCallback(async () => {
        if (isTokenFetching) {
            return; // If fetch is already in progress, return early
        }
        isTokenFetching = true;
        try {
            const response = await fetchAuthToken(searchParams?.get('code') as string);
            const newUser = {
                email: response?.email ?? '',
                firstName: response?.given_name ?? '',
                lastName: response?.family_name ?? '',
                fullName: response?.given_name + ' ' + response?.family_name,
                username: response['cognito:username'],
                idToken: response?.id_token?.toString() ?? '',
                accessToken: response?.access_token?.toString() ?? '',
                refreshToken: response?.refresh_token?.toString() ?? '',
                accountType: response['custom:account_type'] ?? '',
                organizationId: response['custom:organization_id'] ?? '',
                onboardingStatus: response['custom:onboarding_status'] ?? '',
            };
            localStorage.setItem('accessToken', response?.access_token);
            localStorage.setItem(
                'authInfo',
                JSON.stringify({
                    user: newUser,
                    isAuthenticated: true,
                }),
            );

            setUser(newUser);
            setIsAuthenticated(true);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
            isTokenFetching = false; // Reset token is fetching
        }
    }, [fetchAuthToken, searchParams]);

    // Effect for handling initial render and authentication
    useLayoutEffect(() => {
        setIsLoading(true);
        const authInfo = localStorage.getItem('authInfo');
        if (authInfo) {
            const parsedAuthInfo: AuthInfo = JSON.parse(authInfo);
            setUser(parsedAuthInfo.user);
            setIsAuthenticated(parsedAuthInfo.isAuthenticated);
            setIsLoading(false);
        } else if (searchParams?.get('error_description')) {
            handleSignOut();
        } else if (location.pathname === '/' && searchParams?.get('code')) {
            handleFetchAuthUserInfo();
        } else {
            setIsLoading(false);
        }
    }, [handleFetchAuthUserInfo, searchParams, setIsLoading]);

    // Values for authentication context
    const values = {
        user,
        isAuthenticated,
        isLoading,
        handleSignIn,
        handleSignOut,
        updateOnBoardStatus,
    };
    return <authContext.Provider value={values}>{children}</authContext.Provider>;
};
