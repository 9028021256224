import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Box,
    Paragraph,
    StepperNavigator,
    Paper,
    color,
    spacing,
    RichTextEditor,
    AutoComplete,
    SectionHeader,
    Button,
    EditableSelect,
    sanitizeHTML,
} from '@pelpr/pelpr-ui';
import { AssessmentDataType } from 'src/modal';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { updateAssessment } from 'src/redux/assessments/assessmentsApi';
import { isValidHtml, stripHtmlAndSpaces } from 'src/utilities/helpers';

const validationSchema = yup.object().shape({
    description: yup
        .string()
        .required('Description is required')
        .test(
            'is-valid-description',
            'Description can only contain alphanumeric characters, spaces, and these special characters: : , . # - \' " ! ? & % = _ + / () ;',
            (value) => isValidHtml(value),
        )
        .test('min-char-count', 'Description must be at least 4 characters', (value) => {
            const text = stripHtmlAndSpaces(value);
            const charCount = text.length;
            return charCount >= 4;
        })
        .test(
            'max-char-count',
            'Description must be less than 2000 characters',
            (value) => {
                const text = stripHtmlAndSpaces(value);
                const charCount = text.length;
                return charCount <= 2000;
            },
        ),
    jobRoles: yup
        .array()
        .of(yup.number())
        .min(1, 'At least one role must be selected')
        .required('Roles are required'),
    tags: yup.array().of(yup.string()).max(5, 'You can add up to 5 tags'),
});

const initialValues = {
    description: '',
    jobRoles: [],
    tags: [],
};

interface AssessmentDetailsProps {
    contentType: 'tab' | 'step';
    steps?: number;
    currentStep?: number;
    onPrevious?: () => void;
    assessmentData: AssessmentDataType;
    onSubmit: (assessmentData: AssessmentDataType, step?: number) => void;
}

const AssessmentDetails = ({
    contentType,
    steps,
    currentStep,
    onPrevious,
    assessmentData,
    onSubmit,
}: AssessmentDetailsProps) => {
    const { rolesTypes } = useAppSelector((state) => state.app.lookups);
    const { assessmentUpdating } = useAppSelector((state) => state.assessments);
    const dispatch = useAppDispatch();

    const onFormSubmit = useCallback(async (values: typeof initialValues) => {
        if (contentType === 'step') {
            onSubmit(
                {
                    ...assessmentData,
                    ...values,
                    description: sanitizeHTML(values?.description) as unknown as string,
                } as AssessmentDataType,
                3,
            );
        } else if (contentType === 'tab') {
            dispatch(
                updateAssessment({
                    assessmentData: {
                        ...(assessmentData as AssessmentDataType),
                        ...values,
                        description: sanitizeHTML(
                            values?.description,
                        ) as unknown as string,
                    },
                }),
            );
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: onFormSubmit,
        validateOnMount: false,
        validateOnChange: true,
    });

    useEffect(() => {
        if (assessmentData) {
            formik.setFieldValue(
                'description',
                sanitizeHTML(assessmentData?.description) || '',
            );
            formik.setFieldValue('tags', assessmentData?.tags || []);
            formik.setFieldValue(
                'jobRoles',

                assessmentData?.jobRoles || [],
            );
            formik.validateForm();
        }
    }, [assessmentData]);

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: spacing(8),
            }}>
            <Paper
                sx={{
                    width: '100%',
                    padding: spacing(6),
                    marginBottom: spacing(6.5),
                }}>
                <SectionHeader
                    title='Assessment Details'
                    subtitle='This will help in recommending the assessment to relevant tech
                    roles.'
                />
                <Paragraph
                    sx={{ color: color.darkNeutral300, margin: `${spacing(6)} 0` }}>
                    * indicates required fields
                </Paragraph>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: spacing(6),
                    }}>
                    <InputWrapper
                        isRequired={true}
                        label='Description'
                        description='Add details to describe what areas or skills this assessment covers.'>
                        <RichTextEditor
                            showToolBar
                            charLimit={2000}
                            plugins={[
                                'alignment',
                                'fontSize',
                                'heading',
                                'bold',
                                'code-highlight',
                                'italic',
                                'list',
                                'strikethrough',
                                'underline',
                            ]}
                            fullWidth={true}
                            required
                            value={
                                formik.values.description ||
                                assessmentData?.description ||
                                ''
                            }
                            onChange={(value: string) => {
                                formik.setFieldValue('description', value);
                            }}
                            isInvalid={
                                formik.touched.description && !!formik.errors.description
                            }
                            helperText={
                                formik.touched.description && formik.errors.description
                                    ? formik.errors.description
                                    : ''
                            }
                            disabled={assessmentUpdating}
                        />
                    </InputWrapper>

                    <InputWrapper
                        isRequired={true}
                        label='Add Roles'
                        description='Add roles this assessment is relevant to'>
                        <AutoComplete
                            fullWidth
                            name='jobRoles'
                            options={rolesTypes}
                            selectedOptions={formik.values?.jobRoles}
                            onChange={(values) => {
                                formik.setFieldValue('jobRoles', values, true);
                            }}
                            error={formik.touched.jobRoles && !!formik.errors.jobRoles}
                            helperText={
                                formik.touched.jobRoles && formik.errors.jobRoles
                                    ? (formik.errors.jobRoles as string)
                                    : ''
                            }
                            disabled={assessmentUpdating}
                        />
                    </InputWrapper>
                    <InputWrapper
                        label='Add Tags'
                        description='Add tags related to this assessment (upto 5 tags allowed)'>
                        <EditableSelect
                            fullWidth
                            name='tags'
                            placeholder='Type and enter to create new tag'
                            selectedOptions={formik.values.tags}
                            onChange={(values) => {
                                formik.setFieldError('tags', '');
                                formik.setFieldValue('tags', values);
                            }}
                            error={formik.touched.tags && !!formik.errors.tags}
                            helperText={
                                formik.errors.tags ? (formik.errors.tags as string) : ''
                            }
                            disabled={assessmentUpdating}
                        />
                    </InputWrapper>
                    {contentType === 'tab' && (
                        <Button
                            sx={{
                                width: 'max-content',
                                marginTop: spacing(2.5),
                                marginBottom: spacing(2.5),
                            }}
                            onClick={formik.handleSubmit}
                            loading={assessmentUpdating}
                            disabled={assessmentUpdating}>
                            Save
                        </Button>
                    )}
                </Box>
            </Paper>

            {contentType === 'step' && (
                <StepperNavigator
                    loading={assessmentUpdating}
                    steps={steps || 2}
                    currentStep={currentStep || 2}
                    previousButtonLabel='Previous'
                    onPrevious={onPrevious ? onPrevious : () => {}}
                    onNext={formik.handleSubmit}
                />
            )}
        </Box>
    );
};

export default AssessmentDetails;
