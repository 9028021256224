import {
    Box,
    Button,
    CheckboxGroup,
    Input,
    Paragraph,
    Slider,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppSelector } from 'src/hooks';
import { JobsLeftSearchFiltersType } from 'src/modal';

/* Option IDs for CheckboxGroup must be unique for the current render screen to avoid check-uncheck conflicts. The order of IDs does not matter. */

const experienceLevels = [
    {
        id: 1,
        label: 'Entry level',
        value: 'Entry',
    },
    {
        id: 2,
        label: 'Junior',
        value: 'Junior',
    },
    {
        id: 3,
        label: 'Mid level',
        value: 'Mid-level',
    },
    {
        id: 4,
        label: 'Senior',
        value: 'Senior',
    },
    {
        id: 5,
        label: 'Executive',
        value: 'Executive',
    },
];

const jobType = [
    {
        id: 6,
        label: 'Full-time',
        value: 'Full-time',
    },
    {
        id: 7,
        label: 'Part-time',
        value: 'Part-time',
    },
    {
        id: 8,
        label: 'Contract',
        value: 'Contract',
    },
    {
        id: 9,
        label: 'Internship',
        value: 'Internship',
    },
    {
        id: 10,
        label: 'Temporary',
        value: 'Temporary',
    },
];

const workplaceType = [
    {
        id: 11,
        label: 'Onsite',
        value: 'Onsite',
    },
    {
        id: 12,
        label: 'Hybrid',
        value: 'Hybrid',
    },
    {
        id: 13,
        label: 'Startup',
        value: 'Startup',
    },
];

const companyProfile = [
    {
        id: 14,
        label: 'Small business',
        value: 'Small business',
    },
    {
        id: 15,
        label: 'Large company',
        value: 'Large company',
    },
    {
        id: 15,
        label: 'Startup',
        value: 'Startup',
    },
    {
        id: 16,
        label: 'Self-employed',
        value: 'Self-employed',
    },
    {
        id: 17,
        label: 'Partnership',
        value: 'Partnership',
    },
    {
        id: 18,
        label: 'Educational',
        value: 'Educational',
    },
    {
        id: 19,
        label: 'Non-profit',
        value: 'Non-profit',
    },
    {
        id: 20,
        label: 'Government',
        value: 'Government',
    },
];

const companySize = [
    {
        id: 21,
        label: 'Individual',
        value: '1',
    },
    {
        id: 22,
        label: '2-10',
        value: '2-10',
    },
    {
        id: 23,
        label: '11-50',
        value: '11-50',
    },
    {
        id: 24,
        label: '51-100',
        value: '51-100',
    },
    {
        id: 25,
        label: '101-500',
        value: '101-500',
    },
    {
        id: 26,
        label: '500+',
        value: '500+',
    },
];

interface JobsLeftFiltersProps {
    filters: JobsLeftSearchFiltersType;
    handleFilterChange: (value: string[] | number[], type: string) => void;
    onSearch: () => void;
    onResetFilters: () => void;
}

const JobsLeftFilters = ({
    filters,
    handleFilterChange,
    onSearch,
    onResetFilters,
}: JobsLeftFiltersProps) => {
    const { loading } = useAppSelector((state) => state.candidate);

    return (
        <Box
            sx={{
                width: '250px',
                minWidth: '250px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(9),
                padding: spacing(6),
                paddingLeft: 0,
                paddingTop: 0,
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Paragraph
                        sx={{
                            fontSize: spacing(5),
                            fontWeight: 600,
                            lineHeight: spacing(8),
                        }}>
                        Filters
                    </Paragraph>
                    <Button variant='link' disabled={loading} onClick={onResetFilters}>
                        Clear All
                    </Button>
                </Box>
                <Button
                    variant='secondary'
                    sx={{
                        width: '100%',
                    }}
                    disabled={loading}
                    onClick={onSearch}>
                    Refine
                </Button>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Experience Level
                </Paragraph>
                <CheckboxGroup
                    name='experienceLevel'
                    items={experienceLevels}
                    isMulti
                    defaultSelectedOptions={filters?.experienceLevel}
                    onSelect={(values) => handleFilterChange(values, 'experienceLevel')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Job Type
                </Paragraph>
                <CheckboxGroup
                    name='jobType'
                    items={jobType}
                    isMulti
                    defaultSelectedOptions={filters?.jobType}
                    onSelect={(values) => handleFilterChange(values, 'jobType')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Workplace
                </Paragraph>
                <CheckboxGroup
                    name='workplaceType'
                    items={workplaceType}
                    isMulti
                    defaultSelectedOptions={filters?.workplaceType}
                    onSelect={(values) => handleFilterChange(values, 'workplaceType')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Company Profile
                </Paragraph>
                <CheckboxGroup
                    name='companyProfile'
                    items={companyProfile}
                    isMulti
                    defaultSelectedOptions={filters?.companyProfile}
                    onSelect={(values) => handleFilterChange(values, 'companyProfile')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Company Size
                </Paragraph>
                <CheckboxGroup
                    name='companySize'
                    items={companySize}
                    isMulti
                    defaultSelectedOptions={filters?.companySize}
                    onSelect={(values) => handleFilterChange(values, 'companySize')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph
                    size='large'
                    weight='semi-bold'
                    sx={{ marginBottom: spacing(8) }}>
                    Salary Range
                </Paragraph>
                <Slider
                    range
                    minWidth={'100%'}
                    min={0}
                    max={500000}
                    allowCross={false}
                    step={1}
                    tipFormatter={(value) => `$${value.toLocaleString()}`}
                    value={filters?.salaryRange}
                    onChange={(value) =>
                        handleFilterChange(value as number[], 'salaryRange')
                    }
                />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(1),
                    }}>
                    <Input
                        size='small'
                        value={filters?.salaryRange?.[0] || 0}
                        onChange={(event) =>
                            handleFilterChange(
                                [
                                    Number(event.target.value),
                                    filters?.salaryRange[1] || 0,
                                ],
                                'salaryRange',
                            )
                        }
                        type='number'
                        placeholder='Min'
                        sx={{ width: '80px', margin: 0, flexGrow: 1 }}
                    />
                    -
                    <Input
                        size='small'
                        value={filters?.salaryRange?.[1] || 0}
                        onChange={(event) =>
                            handleFilterChange(
                                [
                                    filters?.salaryRange[0] || 0,
                                    Number(event.target.value),
                                ],
                                'salaryRange',
                            )
                        }
                        type='number'
                        placeholder='Max'
                        sx={{ width: '80px', margin: 0, flexGrow: 1 }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default JobsLeftFilters;
