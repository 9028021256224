import Skeleton from 'react-loading-skeleton';
import { Box, Paper, spacing } from '@pelpr/pelpr-ui';

const AssessmentPageSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: spacing(6),
                paddingRight: spacing(6),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Skeleton width={300} height={30} />
                <Box
                    sx={{
                        marginTop: spacing(1),
                        marginBottom: spacing(2),
                    }}>
                    <Skeleton width={200} height={20} />
                </Box>

                <Skeleton width={100} height={20} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(4),
                    paddingTop: spacing(6),
                    paddingBottom: spacing(6),
                }}>
                {[1, 2, 3, 4].map((item) => (
                    <Box
                        key={item}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(3),
                        }}>
                        <Skeleton width={20} height={20} />{' '}
                        <Skeleton width={100} height={20} />
                    </Box>
                ))}
            </Box>
            <Paper
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                    padding: spacing(6),
                }}>
                <Box>
                    <Skeleton width={250} height={30} />
                    <Skeleton width={400} height={20} />
                </Box>
                <Box>
                    <Skeleton width={150} height={20} />
                    <Skeleton circle={true} width={80} height={80} />
                </Box>
                <Skeleton width={400} height={40} />
                <Skeleton width={400} height={80} />
                <Skeleton width={400} height={40} />
                <Skeleton width={66} height={40} />
            </Paper>
        </Box>
    );
};

export default AssessmentPageSkeleton;
