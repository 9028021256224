import { useState } from 'react';
import { Box, color, Paper, spacing, Tabs } from '@pelpr/pelpr-ui';
import JobOverviewTab from '../JobOverviewTab';
import CollaboratorsTab from '../JobCollaborators';
import JobApplicationsTab from '../JobApplications';

const tabOptions = [
    {
        title: 'Overview',
    },
    {
        title: 'Collaborators',
    },
    {
        title: 'Applicants',
    },
    {
        title: 'Transactions',
    },
    { title: 'Timeline' },
];

const JobDetailsPanel = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
            <Paper
                sx={{
                    width: '100%',
                    borderRadius: 0,
                    borderTopLeftRadius: spacing(1.25),
                    borderTopRightRadius: spacing(1.25),
                }}>
                <Tabs
                    tabs={tabOptions}
                    tabType='tab'
                    activeTab={activeTab}
                    onTabClick={(value) => setActiveTab(value)}
                    sx={{
                        padding: spacing(6),
                        borderBottom: `2px solid ${color.lightNeutral500}`,
                    }}
                />
            </Paper>

            {activeTab === 0 && <JobOverviewTab />}
            {activeTab === 1 && <CollaboratorsTab />}
            {activeTab === 2 && <JobApplicationsTab />}
        </Box>
    );
};

export default JobDetailsPanel;
