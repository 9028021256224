import { Box, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const CandidateProfileTabSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <Skeleton width={100} height={24} />
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3, 4].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3, 4].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3, 4, 5].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3, 4, 5].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        {[1, 2, 3, 4, 5].map((item) => (
                            <Skeleton key={item} width={40} height={14} />
                        ))}
                    </Box>

                    <Skeleton width={80} height={14} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: color.lightNeutral500,
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(5),
                    }}>
                    <Skeleton width={100} height={24} />
                    {[1, 2, 3].map((item) => (
                        <Box
                            key={item}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: spacing(6),
                            }}>
                            <Skeleton width={24} height={24} />
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: spacing(1),
                                }}>
                                <Skeleton width={120} height={16} />
                                <Skeleton width={150} height={14} />
                                <Skeleton width={100} height={14} />
                                <Skeleton width={90} height={14} />

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: spacing(1),
                                        span: {
                                            display: 'block',
                                            width: '99%',
                                        },
                                    }}>
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='80%' height={14} />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: color.lightNeutral500,
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(5),
                    }}>
                    <Skeleton width={100} height={24} />
                    {[1, 2, 3].map((item) => (
                        <Box
                            key={item}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: spacing(6),
                            }}>
                            <Skeleton width={24} height={24} />
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: spacing(1),
                                }}>
                                <Skeleton width={120} height={16} />
                                <Skeleton width={150} height={14} />
                                <Skeleton width={100} height={14} />
                                <Skeleton width={90} height={14} />

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: spacing(1),
                                        span: {
                                            display: 'block',
                                            width: '99%',
                                        },
                                    }}>
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='100%' height={14} />
                                    <Skeleton width='80%' height={14} />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default CandidateProfileTabSkeleton;
