import Skeleton from 'react-loading-skeleton';
import { Box, Paragraph, color, spacing } from '@pelpr/pelpr-ui';

const headings = ['Name', 'Account', 'Country', 'Last Active', 'Status', 'Action'];
const contentWidths = {
    1: 120,
    2: 120,
    3: 120,
    4: 120,
    5: 50,
    6: 25,
};

const UsersTableSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Skeleton width={120} height={40} />
                    <Skeleton width={120} height={40} />
                    <Skeleton width={100} height={20} />
                </Box>
                <Skeleton width={200} height={40} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `2px solid ${color.lightNeutral500}`,
                        paddingBottom: spacing(2),
                    }}>
                    {headings?.map((heading, index: number) => (
                        <Paragraph
                            key={index}
                            weight='semi-bold'
                            sx={{
                                width: '100%',

                                textAlign: 'left',
                            }}>
                            {heading}
                        </Paragraph>
                    ))}
                </Box>

                {[1, 2, 3, 4].map((row) => (
                    <Box
                        key={`skeleton-row-${row}`}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: spacing(6),
                            borderBottom: `2px solid ${color.lightNeutral500}`,
                            paddingTop: spacing(5),
                            paddingBottom: spacing(5),
                        }}>
                        {[1, 2, 3, 4, 5, 6].map((item) => (
                            <Box
                                key={`skeleton-content-${item}`}
                                sx={{
                                    width: '100%',

                                    textAlign: 'left',
                                }}>
                                <Skeleton
                                    width={
                                        contentWidths[item as keyof typeof contentWidths]
                                    }
                                    height={item === 6 ? 10 : 20}
                                />
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default UsersTableSkeleton;
