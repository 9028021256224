import { Box, spacing } from '@pelpr/pelpr-ui';
import QuestionsList from './QuestonsList';
import QuestionDetails from './QuestionDetails';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { getAllQuestions } from 'src/redux/question/questionApi';

const QuestionsScreen = () => {
    const { assessmentId } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        assessmentId &&
            dispatch(
                getAllQuestions({
                    quizId: assessmentId,
                }),
            );
    }, [assessmentId]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <QuestionsList />
            <QuestionDetails />
        </Box>
    );
};

export default QuestionsScreen;
