import {
    Box,
    Button,
    CheckboxGroup,
    Input,
    Paragraph,
    Slider,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppSelector } from 'src/hooks';
import { CandidatesLeftSearchFiltersType } from 'src/modal';

/* Option IDs for CheckboxGroup must be unique for the current render screen to avoid check-uncheck conflicts. The order of IDs does not matter. */

const profileStatuses = [
    {
        id: 1,
        label: 'Live',
        value: 'live',
    },
    {
        id: 2,
        label: 'Offline',
        value: 'offline',
    },
];

const experienceLevels = [
    {
        id: 3,
        label: 'Entry level',
        value: 'Entry',
    },
    {
        id: 4,
        label: 'Junior',
        value: 'Junior',
    },
    {
        id: 5,
        label: 'Mid level',
        value: 'Mid-level',
    },
    {
        id: 6,
        label: 'Senior',
        value: 'Senior',
    },
    {
        id: 7,
        label: 'Executive',
        value: 'Executive',
    },
];

const jobSearchStatus = [
    {
        id: 8,
        label: 'Active',
        value: 'Actively looking',
    },
    {
        id: 9,
        label: 'Exploring',
        value: 'Exploring',
    },
    {
        id: 10,
        label: 'Not Looking',
        value: 'Not Looking',
    },
];

const workplace = [
    {
        id: 11,
        label: 'Onsite',
        value: 'Onsite',
    },
    {
        id: 12,
        label: 'Hybrid',
        value: 'Hybrid',
    },
    {
        id: 13,
        label: 'Remote',
        value: 'Remote',
    },
];

interface CandidatesLeftFiltersProps {
    filters: CandidatesLeftSearchFiltersType;
    handleFilterChange: (value: string[] | number[], type: string) => void;
    onSearch: () => void;
    onResetFilters: () => void;
}

const CandidatesLeftFilters = ({
    filters,
    handleFilterChange,
    onSearch,
    onResetFilters,
}: CandidatesLeftFiltersProps) => {
    const { loading } = useAppSelector((state) => state.candidate);

    return (
        <Box
            sx={{
                width: '250px',
                minWidth: '250px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(9),
                padding: spacing(6),
                paddingLeft: 0,
                paddingTop: 0,
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Paragraph
                        sx={{
                            fontSize: spacing(5),
                            fontWeight: 600,
                            lineHeight: spacing(8),
                        }}>
                        Filters
                    </Paragraph>
                    <Button variant='link' disabled={loading} onClick={onResetFilters}>
                        Clear All
                    </Button>
                </Box>
                <Button
                    variant='secondary'
                    sx={{
                        width: '100%',
                    }}
                    disabled={loading}
                    onClick={onSearch}>
                    Refine
                </Button>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Profile Status
                </Paragraph>
                <CheckboxGroup
                    name='profileStatus'
                    items={profileStatuses}
                    isMulti
                    defaultSelectedOptions={filters?.profileStatus}
                    onSelect={(values) => handleFilterChange(values, 'profileStatus')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Experience Level
                </Paragraph>
                <CheckboxGroup
                    name='experienceLevel'
                    items={experienceLevels}
                    isMulti
                    defaultSelectedOptions={filters?.experienceLevel}
                    onSelect={(values) => handleFilterChange(values, 'experienceLevel')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Job Search Status
                </Paragraph>
                <CheckboxGroup
                    name='candidateJobStatus'
                    items={jobSearchStatus}
                    isMulti
                    defaultSelectedOptions={filters?.candidateJobStatus}
                    onSelect={(values) =>
                        handleFilterChange(values, 'candidateJobStatus')
                    }
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph size='large' weight='semi-bold'>
                    Workplace
                </Paragraph>
                <CheckboxGroup
                    name='jobCategory'
                    items={workplace}
                    isMulti
                    defaultSelectedOptions={filters?.jobCategory}
                    onSelect={(values) => handleFilterChange(values, 'jobCategory')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Paragraph
                    size='large'
                    weight='semi-bold'
                    sx={{ marginBottom: spacing(8) }}>
                    Salary Range
                </Paragraph>
                <Slider
                    range
                    minWidth={'100%'}
                    min={0}
                    max={500000}
                    allowCross={false}
                    step={1}
                    tipFormatter={(value) => `$${value.toLocaleString()}`}
                    value={filters?.salaryRange}
                    onChange={(value) =>
                        handleFilterChange(value as number[], 'salaryRange')
                    }
                />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(1),
                    }}>
                    <Input
                        size='small'
                        value={filters?.salaryRange?.[0] || 0}
                        onChange={(event) =>
                            handleFilterChange(
                                [
                                    Number(event.target.value),
                                    filters?.salaryRange[1] || 0,
                                ],
                                'salaryRange',
                            )
                        }
                        type='number'
                        placeholder='Min'
                        sx={{ width: '80px', margin: 0, flexGrow: 1 }}
                    />
                    -
                    <Input
                        size='small'
                        value={filters?.salaryRange?.[1] || 0}
                        onChange={(event) =>
                            handleFilterChange(
                                [
                                    filters?.salaryRange[0] || 0,
                                    Number(event.target.value),
                                ],
                                'salaryRange',
                            )
                        }
                        type='number'
                        placeholder='Max'
                        sx={{ width: '80px', margin: 0, flexGrow: 1 }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CandidatesLeftFilters;
