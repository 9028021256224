import { useState, useEffect, useMemo } from 'react';
import {
    Badge,
    Box,
    Paragraph,
    color,
    spacing,
    Button,
    ActionMenu,
    ConfirmationDialog,
} from '@pelpr/pelpr-ui';
import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { isEmpty } from 'src/utilities/helpers';
import { UserAccountStatus, UserListDataType, Filters } from 'src/modal';
import { useAppDispatch, useAppSelector } from 'src/hooks';

//icons
import { ReactComponent as EyeOpened } from 'src/assets/vectors/commons/eye-opened-icon.svg';
import { ReactComponent as UserDisabled } from 'src/assets/vectors/commons/user-disable.svg';
import { ReactComponent as UserEnable } from 'src/assets/vectors/commons/user-enable.svg';

import {
    deleteUserAccount,
    getAllUsersAccounts,
    updateUserAccountStatus,
} from 'src/redux/user/userApi';

import { formatToReadableDate } from 'src/utilities/date';
import { AccountStatusTypes, AccountTypes, Countries } from 'src/config/lookups';
import UsersTableSkeleton from './UsersTableSkeleton';
import UserTableCard from './UserTableCard';
import UserAccountViewModal from './UserAccountViewModal';
import UserAccountEditModal from './UserAccountEditModal';
import Table from '../common/tables/table';

const statusBadge = {
    Active: <Badge text='Active' variant='green' />,
    Deleted: <Badge text='Deleted' variant='red' />,
    Disabled: <Badge text='Disabled' variant='neutral' />,
};

type userEditStatus = 'view' | 'edit' | 'delete' | 'disabled' | 'enabled';

export interface filtersProps {
    status: string[];
    category: string[];
}

const UsersTable = () => {
    const { usersAccounts, fetchingUsersAccounts, userLoading } = useAppSelector(
        (state) => state.user,
    );
    const [showDeletedUsers, setShowDeletedUsers] = useState(false);
    const [openedMenu, setOpenedMenu] = useState('');
    const [showConfirmAlert, setShowConfirmAlert] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [userData, setUserData] = useState<{
        id: string;
        type: userEditStatus;
    } | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            getAllUsersAccounts({
                includeDeleted: showDeletedUsers,
            }),
        );
    }, [showDeletedUsers]);

    const handleShowModal = (id: string, type: userEditStatus) => {
        setOpenedMenu('');
        setUserData({
            id,
            type,
        });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setUserData(null);
        setShowModal(false);
    };

    const handleConfirmDialogOpen = (id: string, type: userEditStatus) => {
        setOpenedMenu('');
        setUserData({
            id,
            type,
        });
        setShowConfirmAlert(true);
    };

    const handleStatusChange = () => {
        userData &&
            dispatch(
                updateUserAccountStatus({
                    userId: userData?.id,
                    actionId:
                        userData?.type === 'enabled'
                            ? UserAccountStatus.Active
                            : UserAccountStatus.Disabled,
                    onSuccess: () => {
                        setShowConfirmAlert(false);
                        setUserData(null);
                    },
                }),
            );
    };

    const handleDeleteDialogOpen = (id: string, type: userEditStatus) => {
        setOpenedMenu('');
        setUserData({
            id,
            type,
        });
        setIsDeleteModalOpened(true);
    };

    const handleDeleteUserAccount = () => {
        if (userData?.type === 'delete') {
            dispatch(
                deleteUserAccount({
                    userId: userData?.id,
                    onSuccess: () => {
                        setUserData(null);
                        setIsDeleteModalOpened(false);
                    },
                }),
            );
        }
    };

    const handleCloseAlert = () => {
        setUserData(null);
        setIsDeleteModalOpened(false);
        setShowConfirmAlert(false);
    };

    const columnFilterFn: FilterFn<UserListDataType> = (row, columnId, filterValue) => {
        if (!isEmpty(filterValue)) {
            if (filterValue?.includes(row.renderValue(columnId))) return true;
            else return false;
        }
        return true;
    };

    const searchFilterFn: FilterFn<UserListDataType> = (row, filterValue) => {
        if (!isEmpty(filterValue)) {
            const searchTerms = filterValue.toLowerCase();
            const fields = ['id', 'name', 'email'];

            for (const field of fields) {
                const fieldValue = row.original[field as keyof UserListDataType];
                if (fieldValue?.toString().toLowerCase().includes(searchTerms)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const columns = useMemo<ColumnDef<UserListDataType, any>[]>(
        () => [
            {
                accessorFn: (row) => `${row.name} ${row.email}`,
                id: 'Name',
                cell: (info) => <UserTableCard user={info.row.original} />,
                header: 'Name',
                filterFn: searchFilterFn,
                accessorKey: 'name',
                size: 350,
            },
            {
                accessorFn: (row) =>
                    AccountTypes?.find((account) => account?.value === row?.accountTypeId)
                        ?.label,
                id: 'account',
                header: 'Account',
                cell: (info) => info.getValue(),
                filterFn: columnFilterFn,
                accessorKey: 'account',
                size: 150,
            },
            {
                accessorFn: (row) =>
                    Countries?.find((country) => country?.value === row?.countryId)
                        ?.label,
                id: 'country',
                header: 'Country',
                cell: (info) => info.getValue(),
                accessorKey: 'country',
                size: 150,
            },
            {
                accessorFn: (row) => row?.createdAt,
                id: 'createdAt',
                cell: (info) => formatToReadableDate(info.getValue(), 'MMM dd, yyyy'),
                header: 'Joined on',
                accessorKey: 'createdAt',
                size: 150,
            },
            {
                accessorFn: (row) => row?.lastActive,
                id: 'lastActive',
                cell: (info) => formatToReadableDate(info.getValue(), 'MMM dd, yyyy'),
                header: 'Last Active',
                accessorKey: 'lastActive',
                size: 150,
            },
            {
                accessorFn: (row) =>
                    AccountStatusTypes?.find(
                        (accountStatus) => accountStatus?.value === row?.accountStatusId,
                    )?.label,
                id: 'status',
                header: 'Status',
                cell: (info) => statusBadge[info.getValue() as keyof typeof statusBadge],
                filterFn: columnFilterFn,
                filterable: true,
                enableSorting: false,
                accessorKey: 'status',
                size: 100,
            },

            {
                accessorFn: (row) => row,
                id: 'actions',
                header: () => 'Actions',
                size: 100,
                cell: (info) => {
                    const value = info.getValue();

                    return (
                        <ActionMenu
                            alignment='bottom-left'
                            isOpen={openedMenu === value?.id}
                            openMenuHandler={() => setOpenedMenu(value?.id)}
                            closeMenuHandler={(event: MouseEvent | TouchEvent) => {
                                const isMenuIcon = (
                                    event?.target as unknown as HTMLInputElement
                                )?.classList.contains('open-menu');

                                if (value?.id === openedMenu && !isMenuIcon)
                                    setOpenedMenu('');
                            }}>
                            <Button
                                variant='ghost'
                                disabled={userLoading}
                                CustomIcon={EyeOpened}
                                onClick={() => handleShowModal(value?.id, 'view')}>
                                View
                            </Button>
                            <Button
                                variant='ghost'
                                disabled={
                                    userLoading ||
                                    value?.accountStatusId ===
                                        UserAccountStatus.Disabled ||
                                    value?.accountStatusId === UserAccountStatus.Deleted
                                }
                                CustomIcon={UserDisabled}
                                onClick={() =>
                                    handleConfirmDialogOpen(value?.id, 'disabled')
                                }>
                                Disable
                            </Button>

                            <Button
                                variant='ghost'
                                icon='delete'
                                disabled={
                                    userLoading ||
                                    value?.accountStatusId === UserAccountStatus.Deleted
                                }
                                onClick={() =>
                                    handleDeleteDialogOpen(value?.id, 'delete')
                                }>
                                Delete
                            </Button>
                            <Button
                                variant='ghost'
                                CustomIcon={UserEnable}
                                disabled={
                                    userLoading ||
                                    value?.accountStatusId === UserAccountStatus.Active ||
                                    value?.accountStatusId === UserAccountStatus.Deleted
                                }
                                onClick={() =>
                                    handleConfirmDialogOpen(value?.id, 'enabled')
                                }>
                                Enable
                            </Button>
                            <Button
                                variant='ghost'
                                icon='edit'
                                disabled={
                                    userLoading ||
                                    value?.accountStatusId === UserAccountStatus.Deleted
                                }
                                onClick={() => handleShowModal(value?.id, 'edit')}>
                                Edit
                            </Button>
                        </ActionMenu>
                    );
                },
            },
        ],
        [openedMenu, userLoading],
    );

    if (fetchingUsersAccounts) return <UsersTableSkeleton />;

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filters: Filters<any>[] = [
        {
            id: 'status',
            label: 'Status',
            column: 'accountStatusId',
            size: 'small',
            data: AccountStatusTypes,
        },
        {
            id: 'account',
            label: 'Account',
            column: 'accountTypeId',
            size: 'small',
            data: AccountTypes,
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Table
                data={usersAccounts}
                columns={columns}
                filters={filters}
                showDeleted={showDeletedUsers}
                setShowDeleted={setShowDeletedUsers}
            />

            {!fetchingUsersAccounts && usersAccounts?.length === 0 && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: spacing(15),
                        paddingBottom: spacing(9),
                    }}>
                    <Paragraph
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        No Data to display
                    </Paragraph>
                </Box>
            )}

            {showModal && userData?.type === 'view' && (
                <UserAccountViewModal
                    userId={userData?.id}
                    show={showModal}
                    onClose={handleCloseModal}
                />
            )}
            {showModal && userData?.type === 'edit' && (
                <UserAccountEditModal
                    userId={userData?.id}
                    show={showModal}
                    onClose={handleCloseModal}
                />
            )}

            {isDeleteModalOpened && (
                <ConfirmationDialog
                    uniqueKey='delete-user-confirmation'
                    actionType='delete'
                    onConfirm={handleDeleteUserAccount}
                    onDeny={handleCloseAlert}
                    loading={userLoading}
                    show={isDeleteModalOpened}
                    title='Are you want to delete?'
                    cancelBtnLabel='Cancel'
                    submitBtnLabel='Delete'>
                    {`Are you sure to delete this User Account?`}
                </ConfirmationDialog>
            )}
            {showConfirmAlert && (
                <ConfirmationDialog
                    uniqueKey='enable-disable-user-confirmation'
                    actionType='submit'
                    onConfirm={handleStatusChange}
                    onDeny={handleCloseAlert}
                    loading={userLoading}
                    show={showConfirmAlert}
                    title={userData?.type === 'enabled' ? 'Enable user' : 'Disable user'}
                    cancelBtnLabel='Cancel'
                    submitBtnLabel={userData?.type === 'enabled' ? 'Enable' : 'Disable'}>
                    {userData?.type === 'enabled'
                        ? 'Are you want to enable this user?'
                        : 'Are you want to disable this user?'}
                </ConfirmationDialog>
            )}
        </Box>
    );
};

export default UsersTable;
