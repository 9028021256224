import { Box, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { QuestionIcons } from 'src/config/constant';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { QuestionListDataType } from 'src/modal';
import { setAddEditQuestionMode } from 'src/redux/question/questionSlice';

interface QuestionListItemProps {
    question: QuestionListDataType;
}

const QuestionListItem = ({ question }: QuestionListItemProps) => {
    const { activeQuestionId } = useAppSelector((state) => state.question);
    const IconComponent = QuestionIcons[question.questionType];
    const isActive = Number(activeQuestionId) === question.id;
    const dispatch = useAppDispatch();

    const handleQuestionSelect = () => {
        dispatch(
            setAddEditQuestionMode({
                activeQuestionId: question.id,
                questionEditMode: 'edit',
            }),
        );
    };

    return (
        <Box
            id={`question-${question.id}`}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
                padding: spacing(6),
                backgroundColor: isActive ? color.neonBlue50 : color.lightNeutral200,
                borderBottom: `1px solid ${color.lightNeutral500}`,
                boxSizing: 'border-box',
                cursor: 'pointer',
                hover: {
                    backgroundColor: color.neonBlue50,
                },
            }}
            onClick={handleQuestionSelect}>
            <Box
                sx={{
                    flexShrink: 0,
                }}>
                <IconComponent width={24} />
            </Box>

            <Paragraph
                weight={isActive ? 'semi-bold' : 'medium'}
                sx={{
                    wordWrap: 'break-word',

                    display: '-webkit-box',
                    ' -webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    MozBoxOrient: 'vertical',
                    msBoxOrient: 'vertical',
                    boxOrient: 'vertical',
                }}>
                {question.text.replace(/<\/?[^>]+(>|$)/g, '')}
            </Paragraph>
        </Box>
    );
};

export default QuestionListItem;
