import {
    Box,
    Button,
    CitySearchAutoComplete,
    DebounceInput,
    spacing,
} from '@pelpr/pelpr-ui';
import CountryPicker from 'src/components/common/inputs/CountryPicker';
import { JobsTopSearchFiltersType } from 'src/modal';
import { Countries } from 'src/config/lookups';
import { useAppSelector } from 'src/hooks';

interface JobsTopFiltersProps {
    filters: JobsTopSearchFiltersType;
    handleFilterChange: (value: string | number, type: string) => void;
    onSearch: () => void;
}

const JobsTopFilters = ({
    filters,
    handleFilterChange,
    onSearch,
}: JobsTopFiltersProps) => {
    const { loading } = useAppSelector((state) => state.candidate);
    const countryCode = Countries?.find((country) => country?.value === filters?.country)
        ?.countryCode;

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: spacing(4),
            }}>
            <DebounceInput
                sx={{
                    marginBottom: '0',
                }}
                fullWidth
                value={filters.search}
                onChange={(value) => handleFilterChange(value, 'search')}
                placeholder='Search'
            />
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '200px',
                }}>
                <CountryPicker
                    sx={{
                        marginTop: 0,
                    }}
                    fullWidth
                    name='country'
                    defaultValue={filters.country}
                    onSelected={(value) => handleFilterChange(value, 'country')}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '384px',
                }}>
                <CitySearchAutoComplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                    fullWidth
                    placeholder='City'
                    name='city'
                    countryCode={countryCode as string}
                    selectedOptions={filters?.city ? [filters?.city] : []}
                    onChange={(value) => handleFilterChange(value[0], 'city')}
                />
            </Box>
            <Button variant='primary' disabled={loading} onClick={onSearch}>
                Search
            </Button>
        </Box>
    );
};

export default JobsTopFilters;
