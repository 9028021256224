import { Badge, Box, Headline, Paper, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { format, parseJSON } from 'date-fns';
import { formatToReadableDate } from 'src/utilities/date';
import { useAppSelector } from 'src/hooks';
import CopyToClipboard from 'src/components/common/CopyToClipboard';

const JobDetailsSidePanel = () => {
    const { jobDetails } = useAppSelector((state) => state.job);

    return (
        <Paper
            sx={{
                width: '100%',
                maxWidth: '400px',
                minWidth: '400px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
                padding: spacing(6),
                borderRadius: spacing(1.25),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Headline variant='h6'>Job Details</Headline>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Reference
                    </Paragraph>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(3),
                        }}>
                        <Paragraph>{jobDetails?.reference || '-'}</Paragraph>
                        {jobDetails?.reference && (
                            <CopyToClipboard
                                value={jobDetails?.reference}
                                label='Copy job reference to clipboard'
                                tooltipPlace='left'
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Company Reference
                    </Paragraph>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(3),
                        }}>
                        <Paragraph>{jobDetails?.code || '-'}</Paragraph>
                        {jobDetails?.code && (
                            <CopyToClipboard
                                value={jobDetails?.code}
                                label='Copy job code to clipboard'
                                tooltipPlace='left'
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Apply before
                    </Paragraph>
                    <Paragraph>
                        {jobDetails?.expiresAt
                            ? formatToReadableDate(jobDetails?.expiresAt as string)
                            : '-'}
                    </Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Posted on
                    </Paragraph>
                    <Paragraph>
                        {jobDetails?.postingDate
                            ? formatToReadableDate(jobDetails?.postingDate)
                            : '-'}
                    </Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Job Type
                    </Paragraph>
                    <Paragraph>{jobDetails?.jobType}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Salary
                    </Paragraph>
                    <Paragraph>
                        {jobDetails?.minimumSalary.toLocaleString('en-IN')} -{' '}
                        {jobDetails?.maximumSalary.toLocaleString('en-IN')}{' '}
                        {jobDetails?.salaryCurrencyCode} /{' '}
                        {jobDetails?.salaryTypeCode?.toLowerCase()}
                    </Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Salary Base
                    </Paragraph>
                    <Paragraph>
                        {jobDetails?.minimumAnnualSalaryBase.toLocaleString('en-IN')} -{' '}
                        {jobDetails?.maximumAnnualSalaryBase.toLocaleString('en-IN')}{' '}
                        {jobDetails?.salaryCurrencyCode} / year
                    </Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Job Level
                    </Paragraph>
                    <Paragraph>{jobDetails?.jobLevel}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Experience in Years
                    </Paragraph>
                    <Paragraph>{jobDetails?.experienceInYears}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Workplace Type
                    </Paragraph>
                    <Paragraph>{jobDetails?.workplaceType}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Country
                    </Paragraph>
                    <Paragraph>{jobDetails?.country}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Job Package
                    </Paragraph>
                    <Paragraph>{jobDetails?.packageName}</Paragraph>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Last Modified
                    </Paragraph>
                    <Paragraph>
                        {jobDetails?.updatedAt
                            ? format(
                                  parseJSON(jobDetails?.updatedAt),
                                  'MMM dd, yyyy hh:mm aa',
                              )
                            : '-'}
                    </Paragraph>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Headline variant='h6'>Skills Required</Headline>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        gap: spacing(2),
                    }}>
                    {jobDetails?.skills?.map((skill) => {
                        return (
                            <Badge
                                key={skill}
                                text={skill}
                                variant='default'
                                sx={{
                                    backgroundColor: color.neonBlue50,
                                    color: color.darkNeutral300,
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    height: '28px',
                                }}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Paper>
    );
};

export default JobDetailsSidePanel;
