import styled from '@emotion/styled';
import { useState } from 'react';
import { Box, Paragraph, spacing, color, ActionMenu, Button } from '@pelpr/pelpr-ui';

import { AssessmentResult } from 'src/modal/candidate';
import { formatToReadableDate } from 'src/utilities/date';
import CandidateAssessmentAttemptDeleteModal from './CandidateAssessmentAttemptDeleteModal';
import arrowDown from 'src/assets/vectors/commons/arrow-down.svg';
import testPlaceholder from 'src/assets/vectors/commons/test-icon.svg';

interface CandidateAssessmentCardProps {
    assessmentData: AssessmentResult;
}

const AssessmentImage = styled.img`
    width: ${spacing(8)};
    height: ${spacing(8)};
    flex-shrink: 0;
`;

const CandidateAssessmentCard = ({ assessmentData }: CandidateAssessmentCardProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [imageLoadError, setImageLoadError] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [resetModalData, setResetModalData] = useState<{
        attemptId: string | null;
        quizId: string | null;
    }>({
        attemptId: null,
        quizId: null,
    });

    const handleOpen = (quizId: string, attemptId: string) => {
        setResetModalData({ quizId, attemptId });
    };

    const handleClose = () => {
        setResetModalData({ quizId: null, attemptId: null });
    };

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Box
                sx={{
                    paddingTop: spacing(4),
                    paddingBottom: spacing(4),
                    paddingLeft: spacing(6),
                    paddingRight: spacing(6),
                    borderRadius: '5px',
                    boxShadow: '0px 1px 3px 0px #0000001A',
                    background: color.lightNeutral200,
                    cursor: 'pointer',
                }}
                onClick={() => setIsExpanded(!isExpanded)}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(6),
                        'img.arrow': { transform: `rotateX(${isExpanded ? 180 : 0}deg)` },
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        <AssessmentImage
                            src={
                                imageLoadError
                                    ? testPlaceholder
                                    : assessmentData?.assessmentDetail?.imageUrl
                            }
                            alt='quiz'
                            onError={handleImageError}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: spacing(6),
                            }}>
                            <Box sx={{ width: '300px' }}>
                                <Paragraph
                                    sx={{ color: color.midNeutral500 }}
                                    size='small'>
                                    {assessmentData?.assessmentDetail?.category} /{' '}
                                    {assessmentData?.assessmentDetail.type}
                                </Paragraph>
                                <Paragraph
                                    sx={{ color: color.darkNeutral500 }}
                                    size='regular'>
                                    {assessmentData?.assessmentDetail?.name || '-'}
                                </Paragraph>
                            </Box>
                            <Box sx={{ width: '60px' }}>
                                <Paragraph
                                    sx={{ color: color.midNeutral500 }}
                                    size='small'>
                                    Score
                                </Paragraph>
                                <Paragraph
                                    sx={{ color: color.darkNeutral500 }}
                                    size='regular'>
                                    {assessmentData?.score !== null ? (
                                        <>
                                            {assessmentData?.score || 0}/
                                            {assessmentData?.totalScore || 100}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </Paragraph>
                            </Box>
                            <Box sx={{ width: '100px' }}>
                                <Paragraph
                                    sx={{ color: color.midNeutral500 }}
                                    size='small'>
                                    Percentile Rank
                                </Paragraph>
                                <Paragraph
                                    sx={{ color: color.darkNeutral500 }}
                                    size='regular'>
                                    {assessmentData?.percentileRank !== null
                                        ? `${assessmentData?.percentileRank}%`
                                        : '-'}
                                </Paragraph>
                            </Box>
                            <Box sx={{ width: '50px' }}>
                                <Paragraph
                                    sx={{ color: color.midNeutral500 }}
                                    size='small'>
                                    Status
                                </Paragraph>
                                <Paragraph
                                    sx={{
                                        color:
                                            assessmentData?.score !== null
                                                ? color.success500
                                                : color.darkNeutral500,
                                    }}
                                    size='regular'>
                                    {assessmentData?.score !== null ? 'Pass' : '-'}
                                </Paragraph>
                            </Box>
                            <Box sx={{ width: '160px' }}>
                                <Paragraph
                                    sx={{ color: color.midNeutral500 }}
                                    size='small'>
                                    Completed on
                                </Paragraph>
                                <Paragraph
                                    sx={{ color: color.darkNeutral500 }}
                                    size='regular'>
                                    {assessmentData?.createdAt
                                        ? formatToReadableDate(assessmentData?.createdAt)
                                        : '-'}
                                </Paragraph>
                            </Box>
                        </Box>
                    </Box>
                    <img className='arrow' src={arrowDown} alt='arrow down' />
                </Box>
            </Box>

            {isExpanded &&
                assessmentData?.attempts?.map((attempt, index) => {
                    return (
                        <Box
                            key={attempt?.id}
                            sx={{
                                paddingTop: spacing(2),
                            }}>
                            <Box
                                sx={{
                                    paddingTop: spacing(2),
                                    paddingBottom: spacing(2),
                                    paddingLeft: spacing(4),
                                    paddingRight: spacing(4),
                                    borderRadius: '5px',
                                    boxShadow: '0px 1px 3px 0px #0000001A',
                                    background: color.lightNeutral200,
                                    marginLeft: spacing(12),
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: spacing(6),
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: spacing(6),
                                        }}>
                                        <Box sx={{ width: '20px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                No.
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {index + 1}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '160px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Started At
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.startedAt
                                                    ? formatToReadableDate(
                                                          attempt?.startedAt,
                                                      )
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '160px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Completed At
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.completedAt
                                                    ? formatToReadableDate(
                                                          attempt?.completedAt,
                                                      )
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '80px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Time Limit
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.timeLimit
                                                    ? `${attempt?.timeLimit} min`
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '80px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Time Left
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.timeRemaining
                                                    ? `${attempt?.timeRemaining} min`
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '60px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Score
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.score
                                                    ? `${attempt?.score}/100`
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '50px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Status
                                            </Paragraph>
                                            <Paragraph
                                                sx={{
                                                    color: attempt?.completedAt
                                                        ? attempt?.isPassed
                                                            ? color.success500
                                                            : color.danger500
                                                        : color.darkNeutral500,
                                                }}
                                                size='regular'>
                                                {attempt?.completedAt
                                                    ? attempt?.isPassed
                                                        ? 'Pass'
                                                        : 'Fail'
                                                    : '-'}
                                            </Paragraph>
                                        </Box>
                                        <Box sx={{ width: '80px' }}>
                                            <Paragraph
                                                sx={{ color: color.midNeutral500 }}
                                                size='small'>
                                                Published
                                            </Paragraph>
                                            <Paragraph
                                                sx={{ color: color.darkNeutral500 }}
                                                size='regular'>
                                                {attempt?.isPublished ? 'Yes' : 'No'}
                                            </Paragraph>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: spacing(6) }}>
                                        <ActionMenu
                                            alignment='bottom-left'
                                            isOpen={isMenuOpened}
                                            openMenuHandler={() =>
                                                setIsMenuOpened(!isMenuOpened)
                                            }
                                            closeMenuHandler={() =>
                                                setIsMenuOpened(false)
                                            }>
                                            <Button
                                                variant='ghost'
                                                icon='delete'
                                                onClick={() =>
                                                    handleOpen(
                                                        assessmentData?.assessmentDetail
                                                            ?.id,
                                                        attempt?.id,
                                                    )
                                                }>
                                                Delete
                                            </Button>
                                        </ActionMenu>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            <CandidateAssessmentAttemptDeleteModal
                open={!!resetModalData?.attemptId && !!resetModalData?.quizId}
                onClose={handleClose}
                attemptId={resetModalData?.attemptId}
                quizId={resetModalData?.quizId}
            />
        </Box>
    );
};

export default CandidateAssessmentCard;
