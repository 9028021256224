import { BaseSelect, Box, Paragraph, spacing, Pagination } from '@pelpr/pelpr-ui';
import { useAppSelector } from 'src/hooks';
import { JobsTopSearchFiltersType } from 'src/modal';
import JobCardSkeleton from '../JobCardSkeleton';
import JobCard from '../JobCard';

const sortOptions = [
    {
        label: 'Date Posted',
        value: 'desc(postingDate)',
    },
    {
        label: 'Organization',
        value: 'asc(organization)',
    },
];

interface JobSearchListProps {
    filters: JobsTopSearchFiltersType;
    handleFilterChange: (value: string | number, type: string) => void;
}

const JobSearchList = ({ filters, handleFilterChange }: JobSearchListProps) => {
    const { fetchingJobsSearch, jobsSearchData, metadata } = useAppSelector(
        (state) => state.job,
    );

    const numberOfSkeletons: number[] = Array.from(
        { length: filters?.pageSize || 10 },
        (_, i) => i + 1,
    );

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(6),
                }}>
                <Paragraph size='large' weight='medium'>
                    {`${jobsSearchData?.length} job results`}
                </Paragraph>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Paragraph>Sort by:</Paragraph>
                    <BaseSelect
                        size='small'
                        name='sortBy'
                        options={sortOptions}
                        disabled={!!filters?.search}
                        defaultSelected={filters?.sortBy || 'desc(postingDate)'}
                        onChange={(value) => handleFilterChange(value, 'sortBy')}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                    marginBottom: spacing(12),
                }}>
                {fetchingJobsSearch
                    ? numberOfSkeletons?.map((item) => <JobCardSkeleton key={item} />)
                    : jobsSearchData?.map((jobData) => (
                          <JobCard
                              key={jobData?.id}
                              allowActionMenu={true}
                              jobData={jobData}
                          />
                      ))}
            </Box>
            {jobsSearchData?.length > 0 && (
                <Pagination
                    currentPage={filters?.page || 1}
                    totalPages={metadata?.totalPages || 1}
                    setCurrentPage={(value) => handleFilterChange(value, 'page')}
                    pageSize={filters?.pageSize}
                    onPageSizeChange={(value) => handleFilterChange(value, 'pageSize')}
                />
            )}
        </Box>
    );
};

export default JobSearchList;
