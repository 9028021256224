import { Box, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const CandidateAssessmentSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Box
                sx={{
                    paddingTop: spacing(4),
                    paddingBottom: spacing(4),
                    paddingLeft: spacing(6),
                    paddingRight: spacing(6),
                    borderRadius: '5px',
                    boxShadow: '0px 1px 3px 0px #0000001A',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(6),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: spacing(4),
                        }}>
                        <Skeleton width={32} height={32} borderRadius={5} />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: spacing(6),
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '300px',
                                }}>
                                <Skeleton width={50} height={14} />
                                <Skeleton width={200} height={18} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50px',
                                }}>
                                <Skeleton width={34} height={14} />
                                <Skeleton width={50} height={18} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100px',
                                }}>
                                <Skeleton width={100} height={14} />
                                <Skeleton width={34} height={18} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50px',
                                }}>
                                <Skeleton width={50} height={14} />
                                <Skeleton width={40} height={18} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '146px',
                                }}>
                                <Skeleton width={100} height={14} />
                                <Skeleton width={150} height={18} />
                            </Box>
                        </Box>
                    </Box>
                    <Skeleton width={24} height={36} />
                </Box>
            </Box>
        </Box>
    );
};

export default CandidateAssessmentSkeleton;
