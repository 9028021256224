import { AddAssessmentDataType, AssessmentDataType } from 'src/modal';
import { axiosInstance } from 'src/utilities/axiosInstance';

const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

// Quiz API methods

export const fetchAllAssessments = (includeDeleted: boolean) => {
    return axiosInstance.get(
        `${API_URL}/v1/admin/quizzes?includeDeleted=${includeDeleted}`,
    );
};

export const fetchAssessment = (quizId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/quizzes/${quizId}`);
};

export const addAssessment = (payload: AddAssessmentDataType) => {
    return axiosInstance.post(`${API_URL}/v1/admin/quizzes`, payload);
};

export const updateAssessmentRecord = (quizId: string, payload: AssessmentDataType) => {
    return axiosInstance.put(`${API_URL}/v1/admin/quizzes/${quizId}`, payload);
};

export const publicAssessmentRecord = (quizId: string, version: string) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/quizzes/${quizId}/publish?version=${version}`,
    );
};

export const unpublishedAssessmentRecord = (quizId: string, version: string) => {
    return axiosInstance.delete(
        `${API_URL}/v1/admin/quizzes/${quizId}/publish?version=${version}`,
    );
};

export const deleteAssessment = (quizId: string, version: string) => {
    return axiosInstance.delete(
        `${API_URL}/v1/admin/quizzes/${quizId}?version=${version}`,
    );
};

export const addAssessmentPhoto = (quizId: string, payload: FormData) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/quizzes/${quizId}/upload-image`,
        payload,
    );
};

export const deleteQuizAttempt = (quizId: string, attemptId: string) => {
    return axiosInstance.delete(
        `${API_URL}/v1/admin/quizzes/${quizId}/attempts/${attemptId}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')} `,
            },
        },
    );
};
