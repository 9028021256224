import { Box, Paragraph, spacing } from '@pelpr/pelpr-ui';

interface InputWrapperProps {
    label: string;
    description: string;
    isRequired?: boolean;
    children: React.ReactNode;
}

export const InputWrapper = ({
    label,
    description,
    isRequired,
    children,
}: InputWrapperProps) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                '.required': {
                    marginLeft: spacing(1),
                },
            }}>
            <Paragraph
                size='medium'
                weight='semi-bold'
                sx={{
                    lineHeight: spacing(6),
                    marginBottom: spacing(1),
                }}>
                {label}
                {isRequired && (
                    <span aria-hidden='true' className='required'>
                        *
                    </span>
                )}
            </Paragraph>
            <Paragraph
                size='regular'
                weight='regular'
                sx={{
                    lineHeight: '22px',
                    marginBottom: spacing(4),
                }}>
                {description}
            </Paragraph>
            <Box
                sx={{
                    width: '100%',
                }}>
                {children}
            </Box>
        </Box>
    );
};
