export interface QuestionDataType {
    id?: number;
    text: string;
    questionType: number;
    allowedChoiceCount?: number;
    difficultyIndex: number;
    points: number;
    version?: string;
    isPublished?: boolean;
    isDeleted: boolean;
    createdAt?: string;
    updatedAt?: string;
    choices: ChoiceDataType[];
}

export interface ChoiceDataType {
    id?: number;
    text: string;
    isCorrect?: boolean;
    choiceValue: number | null;
}

export type ChoicesData = {
    [key: number]: ChoiceDataType[];
};

export interface QuestionListDataType {
    id: number;
    text: string;
    questionType: number;
    createdAt: string;
    updatedAt: string;
    isPublished: boolean;
    isDeleted: boolean;
}

export enum QuestionCategory {
    'MultipleChoice' = 1,
    'SingleChoice' = 2,
    'TrueFalse' = 3,
    'YesNo' = 4,
    'Rating' = 5,
}
