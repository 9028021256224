import { useState, useMemo } from 'react';
import { ActionMenu, Badge, Box, Button, Paragraph, color } from '@pelpr/pelpr-ui';
import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { isEmpty } from 'src/utilities/helpers';
import { Filters, JobListingDataType } from 'src/modal';
import { useAppSelector } from 'src/hooks';
import { JobStatuses, WorkplaceTypes } from 'src/config/lookups';

//icons
import { formatToReadableDate } from 'src/utilities/date';
import { Countries } from './../../config/lookups';
import Table from '../common/tables/table';

const Labels = {
    Draft: <Badge variant='red' text='Draft' />,
    Pending: <Badge variant='orange' text='Pending' />,
    Active: <Badge variant='green' text='Active' />,
    Expired: <Badge variant='neutral' text='Expired' />,
    Closed: <Badge variant='neutral' text='Closed' />,
    Archived: <Badge variant='neutral' text='Archived' />,
};

const JobListingTable = () => {
    const { jobsListing } = useAppSelector((state) => state.job);

    const [openedMenu, setOpenedMenu] = useState('');

    const searchFilterFn: FilterFn<JobListingDataType> = (row, columnId, filterValue) => {
        if (!isEmpty(filterValue)) {
            const searchTerms = filterValue.toLowerCase();
            const fields = ['id', 'title', 'reference', 'organization', 'country'];

            for (const field of fields) {
                const fieldValue = row.original[field as keyof JobListingDataType];
                if (
                    fieldValue?.toString().toLowerCase().includes(searchTerms)
                ) {
                    return true;
                }
            }
            return false;
        }
        return true;
    };

    const columnFilterFn: FilterFn<JobListingDataType> = (row, columnId, filterValue) => {
        if (!isEmpty(filterValue)) {
            if (filterValue.includes(row.getValue(columnId))) return true;
            else return false;
        }
        return true;
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const columns = useMemo<ColumnDef<JobListingDataType, any>[]>(
        () => [
            {
                accessorFn: (row) => row,
                id: 'title',
                cell: (info) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',

                            maxWidth: '200px',
                            textOverflow: 'wrap',
                        }}>
                        <Paragraph>{info.getValue()?.title}</Paragraph>
                        <Paragraph
                            weight='regular'
                            sx={{
                                color: color.midNeutral500,
                            }}>
                            {info.getValue()?.reference}
                        </Paragraph>
                    </Box>
                ),
                filterFn: searchFilterFn,
                header: 'Title',
                accessorKey: 'title',
                size: 250,
            },
            {
                accessorFn: (row) => row.organization,
                id: 'organization',
                cell: (info) => (
                    <Box
                        sx={{
                            maxWidth: '200px',
                            textOverflow: 'wrap',
                        }}>
                        {info.getValue()}
                    </Box>
                ),
                header: 'Organization',
                accessorKey: 'organization',
                size: 150,
            },
            {
                accessorFn: (row) => row,
                id: 'location',
                cell: (info) => (
                    <Box
                        sx={{
                            maxWidth: '250px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {`${
                            WorkplaceTypes?.find(
                                (item) => item.value === info.getValue()?.workplaceTypeId,
                            )?.label
                        } (${info.getValue()?.locations?.join(', ')})`}
                    </Box>
                ),
                header: 'Location',
                accessorKey: 'location',
                size: 250,
            },
            {
                accessorFn: (row) => row.postingDate,
                id: 'postingData',
                cell: (info) => (
                    <Box
                        sx={{
                            maxWidth: '150px',
                            textOverflow: 'wrap',
                        }}>
                        {formatToReadableDate(info.getValue(), 'MMM dd, yyyy')}
                    </Box>
                ),
                header: 'Posting Date',
                accessorKey: 'postingData',
                size: 150,
            },
            {
                accessorFn: (row) =>
                    Countries?.find((country) => country.value === row?.countryId)?.label,
                id: 'country',
                cell: (info) => info.getValue(),
                filterFn: columnFilterFn,
                header: 'Country',
                accessorKey: 'country',
                size: 150,
            },
            {
                accessorFn: (row) =>
                    JobStatuses?.find((item) => item?.value === row?.jobPostStatusId)
                        ?.label,
                id: 'status',
                header: 'Status',
                cell: (info) => Labels[info.getValue() as keyof typeof Labels],
                filterFn: columnFilterFn,
                filterable: true,
                enableSorting: false,
                accessorKey: 'status',
            },
            {
                accessorFn: (row) => [row?.id, row?.jobPostStatusId],
                id: 'actions',
                header: () => 'Actions',
                size: 100,
                enableSorting: false,
                cell: (info) => {
                    const value = info?.getValue();
                    const id = value[0];

                    return (
                        <ActionMenu
                            isOpen={openedMenu === id}
                            alignment='bottom-left'
                            openMenuHandler={() => setOpenedMenu(id)}
                            closeMenuHandler={(event: MouseEvent | TouchEvent) => {
                                const isMenuIcon = (
                                    event?.target as unknown as HTMLInputElement
                                )?.classList.contains('open-menu');

                                if (id === openedMenu && !isMenuIcon) setOpenedMenu('');
                            }}>
                            <Button
                                sx={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                }}
                                variant='ghost'
                                icon='view'
                                href={`/jobs/${id}`}>
                                View
                            </Button>
                        </ActionMenu>
                    );
                },
            },
        ],
        [openedMenu],
    );

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filters: Filters<any>[] = [
        {
            id: 'status',
            label: 'Status',
            column: 'jobPostStatusId',
            size: 'small',
            data: JobStatuses,
        },
        {
            id: 'country',
            label: 'Country',
            column: 'countryId',
            size: 'small',
            data: Countries,
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Table
                data={jobsListing}
                columns={columns}
                filters={filters}
                searchFilterFn={searchFilterFn}
            />
        </Box>
    );
};

export default JobListingTable;
