import { useAppSelector } from 'src/hooks';
import {
    Box,
    Paper,
    PageHeader,
    PageContainer,
    spacing,
    Button,
    SectionHeader,
} from '@pelpr/pelpr-ui';
import AssessmentsTable from 'src/components/assessments/AssessmentsTable';
import Skeleton from 'react-loading-skeleton';

const Assessments = () => {
    const { loading } = useAppSelector((state) => state.assessments);

    return (
        <PageContainer
            pageHeader={<PageHeader title='Assessments' />}
            sx={{
                width: '100%',
                minHeight: '100vh',
                padding: 0,
                overflow: 'visible',
            }}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingLeft: spacing(6),
                    paddingRight: spacing(6),
                }}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: spacing(6),
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <SectionHeader
                            title='Assessments'
                            subtitle='Create, manage and publish assessments for candidates.'
                        />

                        {loading ? (
                            <Skeleton width={200} height={40} />
                        ) : (
                            <Button
                                icon='add'
                                variant='primary'
                                disabled={loading}
                                href='/assessments/create'>
                                Create assessment
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                        }}>
                        <AssessmentsTable />
                    </Box>
                </Paper>
            </Box>
        </PageContainer>
    );
};

export default Assessments;
