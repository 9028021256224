import { SkeletonTheme } from 'react-loading-skeleton';
import AppRoutes from 'src/routes/AppRoutes';
import * as Sentry from '@sentry/react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.scss';

if (
    process.env.REACT_APP_SENTRY_ENVIRONMENT &&
    (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Development' ||
        process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Test' ||
        process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Production')
) {
    Sentry.init({
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        debug: process.env.REACT_APP_SENTRY_DEBUG === 'true',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate:
            process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Development' ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

function App() {
    return (
        <>
            <ToastContainer
                transition={Slide}
                position='top-right'
                pauseOnHover
                closeOnClick
                hideProgressBar
                newestOnTop={false}
                draggable={false}
                autoClose={5000}
            />
            <SkeletonTheme baseColor='#EBECF0' highlightColor='#DFE1E6'>
                <AppRoutes />
            </SkeletonTheme>
        </>
    );
}

export default App;
