import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { QueryParamType } from 'src/modal';
import {
    fetchSearchJobs,
    getJobCollaborators,
    getJobMetaByRef,
    getJobDetails,
    getJobApplications,
    approveMatchedJobApplicationApi,
    rejectMatchedJobApplicationApi,
    getJobsListing,
} from 'src/services/job';

// API method to fetch job meta by  reference
export const fetchJobMetaDataByRef = createAsyncThunk(
    'job/fetchJobMetaDataByRef',
    async ({ reference }: { reference: string }) => {
        try {
            const response = await getJobMetaByRef(reference);
            return response?.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            throw error.response.data;
        }
    },
);

// API method to fetch jobs by search
export const fetchJobsBySearch = createAsyncThunk(
    'job/fetchJobsBySearch',
    async ({ queryParams }: { queryParams: QueryParamType[] }) => {
        try {
            const response = await fetchSearchJobs(queryParams);
            return response?.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch jobs.');
            throw error.response.data;
        }
    },
);

// API method to fetch jobs collaborators
export const fetchJobCollaborators = createAsyncThunk(
    'job/fetchJobCollaborators',
    async ({ jobId }: { jobId: string }) => {
        try {
            const response = await getJobCollaborators(jobId);
            return response?.data?.jobPostCollaborators;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch job collaborators.');
            throw error.response.data;
        }
    },
);

//API method to fetch job details
export const fetchJobDetails = createAsyncThunk(
    'job/fetchJobDetails',
    async ({ jobId }: { jobId: string }) => {
        try {
            const response = await getJobDetails(jobId);
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch job details.');
            throw error.response.data;
        }
    },
);

//API method to fetch job applications
export const fetchJobApplications = createAsyncThunk(
    'job/fetchJobApplications',
    async ({ jobId }: { jobId: string }) => {
        try {
            const response = await getJobApplications(jobId);
            return response.data?.jobApplications;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch job applications.');
            throw error.response.data;
        }
    },
);

//API method to approve matched job application
export const approveMatchedJobApplication = createAsyncThunk(
    'job/approveMatchedJobApplication',
    async ({
        jobApplicationId,
        version,
        onSuccess,
    }: {
        jobApplicationId: string;
        version: string;
        onSuccess: () => void;
    }) => {
        try {
            await approveMatchedJobApplicationApi(jobApplicationId, version);
            onSuccess();
            return jobApplicationId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to approve job application.');
            throw error.response.data;
        }
    },
);

//API method to reject matched job application
export const rejectMatchedJobApplication = createAsyncThunk(
    'job/rejectMatchedJobApplication',
    async ({
        jobApplicationId,
        version,
        onSuccess,
    }: {
        jobApplicationId: string;
        version: string;
        onSuccess: () => void;
    }) => {
        try {
            await rejectMatchedJobApplicationApi(jobApplicationId, version);
            onSuccess();
            return jobApplicationId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to reject job application.');
            throw error.response.data;
        }
    },
);

// API method to fetch jobs listing
export const fetchJobsListing = createAsyncThunk('job/fetchJobsListing', async () => {
    try {
        const response = await getJobsListing();
        return response?.data?.jobPosts;

        /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
        toast.error('Failed to fetch jobs Listing.');
        throw error.response.data;
    }
});
