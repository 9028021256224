import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { UpdateUserDataType } from 'src/modal';
import {
    deleteUserAccountRecord,
    fetchAllUsersAccountRecords,
    fetchAuthenticatedUserInfo,
    fetchUserAccountRecord,
    updateUserAccountRecord,
    updateUserAccountStatusRecord,
} from 'src/services/user';

export const getAuthenticatedUserInfo = createAsyncThunk(
    'user/getAuthenticatedUserInfo',
    async (payload: { userId: string }) => {
        try {
            const response = await fetchAuthenticatedUserInfo({ userId: payload.userId });

            return response.data;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch user info');
            throw error.response.data;
        }
    },
);

export const getAllUsersAccounts = createAsyncThunk(
    'user/getAllUsersAccounts',
    async ({ includeDeleted }: { includeDeleted: boolean }) => {
        try {
            const response = await fetchAllUsersAccountRecords(includeDeleted);

            return response.data?.users;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch users accounts');
            throw error.response.data;
        }
    },
);

export const getUserAccount = createAsyncThunk(
    'user/getUserAccount',
    async ({ userId }: { userId: string }) => {
        try {
            const response = await fetchUserAccountRecord(userId);
            return response.data;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch user account');
            throw error.response.data;
        }
    },
);

export const deleteUserAccount = createAsyncThunk(
    'user/deleteUserAccount',
    async ({ userId, onSuccess }: { userId: string; onSuccess: () => void }) => {
        try {
            await deleteUserAccountRecord(userId);
            onSuccess();
            return userId;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to delete user account');
            throw error.response.data;
        }
    },
);

export const updateUserAccountStatus = createAsyncThunk(
    'user/updateUserAccountStatus',
    async ({
        userId,
        actionId,
        onSuccess,
    }: {
        userId: string;
        actionId: number;
        onSuccess: () => void;
    }) => {
        try {
            await updateUserAccountStatusRecord(userId, actionId);
            toast.success('User account status updated successfully');
            onSuccess();
            return {
                userId,
                status: actionId,
            };
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to update user account status');
            throw error.response.data;
        }
    },
);

export const updateUserAccount = createAsyncThunk(
    'user/updateUserAccount',
    async (
        {
            userId,
            payload,
            onSuccess,
        }: {
            userId: string;
            payload: UpdateUserDataType;
            onSuccess: () => void;
        },
        thunkAPI,
    ) => {
        try {
            const response = await updateUserAccountRecord(userId, payload);
            toast.success('User account updated successfully');
            onSuccess();
            thunkAPI.dispatch(getAllUsersAccounts({ includeDeleted: false }));
            return response.data;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to update user account');
            throw error.response.data;
        }
    },
);
