import { Box, Paper, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

import JobOverviewTabSkeleton from './JobOverviewTab/JobOverviewTabSkeleton';

const JobDetailsPanelSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: spacing(6),
                borderRadius: spacing(1.25),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(4),
                    marginBottom: spacing(6),
                }}>
                {[1, 2, 3, 4, 5].map((item) => (
                    <Skeleton key={item} width={80} height={30} />
                ))}
            </Box>
            <JobOverviewTabSkeleton />
        </Paper>
    );
};

export default JobDetailsPanelSkeleton;
