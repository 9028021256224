import { Box, Select } from '@pelpr/pelpr-ui';
import { Countries } from 'src/config/lookups';

interface Props {
    label?: string;
    name: string;
    onSelected: (value: number) => void;
    defaultValue?: number;
    fullWidth?: boolean;
    required?: boolean;
    [x: string]: unknown;
}

const CountryPicker = ({
    label,
    name,
    onSelected,
    defaultValue,
    fullWidth,
    required,
    ...restProps
}: Props) => {
    return (
        <Box sx={{ minWidth: 120, width: fullWidth ? '100%' : 'auto' }}>
            <Select
                label={label}
                required={required}
                placeholder='Country'
                name={name}
                selectedOptions={[defaultValue?.toString() || '2']}
                onChange={(value) => onSelected(Number(value[0]))}
                options={Countries}
                {...restProps}
            />
        </Box>
    );
};

export default CountryPicker;
