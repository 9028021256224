import { useEffect } from 'react';
import {
    Box,
    PageContainer,
    PageHeader,
    Paper,
    SectionHeader,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchJobsListing } from 'src/redux/job/jobApi';
import JobListingTable from 'src/components/jobs/JobsListingTable';
import JobsListingTableSkeleton from 'src/components/jobs/JobsListingTableSkeleton';

const JobsListing = () => {
    const { fetchingJobsListing } = useAppSelector((state) => state.job);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchJobsListing());
    }, []);

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Jobs' />}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: spacing(12),
                }}>
                <Paper
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                        padding: spacing(6),
                        borderRadius: spacing(1.25),
                    }}>
                    <SectionHeader
                        title='Job Listings'
                        subtitle='List of job postings on the platform'
                    />
                    {fetchingJobsListing ? (
                        <JobsListingTableSkeleton />
                    ) : (
                        <JobListingTable />
                    )}
                </Paper>
            </Box>
        </PageContainer>
    );
};

export default JobsListing;
