import { useEffect } from 'react';
import { Box, Button, PageContainer, PageHeader, spacing } from '@pelpr/pelpr-ui';
import { useNavigate, useParams } from 'react-router-dom';
import CandidateDetailsPanel from 'src/components/candidates/CandidateDetails/CandidateDetailsPanel';
import CandidateDetailsSidePanel from 'src/components/candidates/CandidateDetails/CandidateDetailsSidePanel';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchCandidateDetails } from 'src/redux/candidate/candidateApi';
import CandidateDetailsSidePanelSkeleton from 'src/components/candidates/CandidateDetails/CandidateDetailsSidePanelSkeleton';
import CandidateDetailsPanelSkeleton from 'src/components/candidates/CandidateDetails/CandidateDetailsPanelSkeleton';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/vectors/commons/arrow-left-icon.svg';

const CandidateDetailsPage = () => {
    const navigate = useNavigate();
    const { candidateId } = useParams();
    const { fetchingCandidateDetails } = useAppSelector((state) => state.candidate);
    const dispatch = useAppDispatch();

    useEffect(() => {
        candidateId && dispatch(fetchCandidateDetails({ candidateId }));
    }, []);

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Candidates' />}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: spacing(12),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}>
                    <Button
                        siz='small'
                        variant='link'
                        CustomIcon={ArrowLeftIcon}
                        onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    {fetchingCandidateDetails ? (
                        <>
                            <CandidateDetailsSidePanelSkeleton />
                            <CandidateDetailsPanelSkeleton />
                        </>
                    ) : (
                        <>
                            <CandidateDetailsSidePanel />
                            <CandidateDetailsPanel />
                        </>
                    )}
                </Box>
            </Box>
        </PageContainer>
    );
};

export default CandidateDetailsPage;
