import { QuestionDataType } from 'src/modal';
import { axiosInstance } from 'src/utilities/axiosInstance';

const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

// Question API methods
export const fetchAllQuestions = (quizId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/quizzes/${quizId}/questions`);
};

export const fetchQuestion = (quizId: string, questionId: string) => {
    return axiosInstance.get(
        `${API_URL}/v1/admin/quizzes/${quizId}/questions/${questionId}`,
    );
};

export const addQuestion = (quizId: string, payload: QuestionDataType) => {
    return axiosInstance.post(`${API_URL}/v1/admin/quizzes/${quizId}/questions`, payload);
};

export const updateQuestionRecord = (
    quizId: string,
    questionId: string,
    payload: QuestionDataType,
) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/quizzes/${quizId}/questions/${questionId}`,
        payload,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')} `,
            },
        },
    );
};

export const deleteQuestion = (quizId: string, questionId: string) => {
    return axiosInstance.delete(
        `${API_URL}/v1/admin/quizzes/${quizId}/questions/${questionId}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')} `,
            },
        },
    );
};
