import { CurrencyLookupType, LookupItem } from 'src/modal';

export const JobsRolesLookups: LookupItem[] = [
    { label: 'Other', value: 1, deprecated: false },
    { label: 'Frontend Developer', value: 2, deprecated: false },
    { label: 'Web Developer', value: 3, deprecated: false },
    { label: 'Backend Developer', value: 4, deprecated: false },
    { label: 'Full-stack Developer', value: 5, deprecated: false },
    { label: 'Mobile Developer', value: 6, deprecated: false },
    { label: 'Software Developer', value: 7, deprecated: false },
    { label: 'Wordpress Developer', value: 8, deprecated: false },
    { label: 'Ruby on Rails Developer', value: 9, deprecated: false },
    { label: 'Python Developer', value: 10, deprecated: false },
    { label: 'Database Developer', value: 11, deprecated: false },
    { label: 'Systems Engineer', value: 12, deprecated: false },
    { label: 'Software Engineer', value: 13, deprecated: false },
    { label: 'Game Developer', value: 14, deprecated: false },
    { label: 'QA Engineer', value: 15, deprecated: false },
    { label: 'AI Engineer', value: 16, deprecated: false },
    { label: 'Cloud Engineer', value: 17, deprecated: false },
    { label: 'Site Reliability Engineer', value: 18, deprecated: false },
    { label: 'UX Designer', value: 19, deprecated: false },
    { label: 'UI Designer', value: 20, deprecated: false },
    { label: 'UI/UX Designer', value: 21, deprecated: false },
    { label: 'Web Designer', value: 22, deprecated: false },
    { label: 'UI/UX Researcher', value: 23, deprecated: false },
    { label: 'Graphic Designer', value: 24, deprecated: false },
    { label: 'Animation Specialist', value: 25, deprecated: false },
    { label: 'Business System Analyst', value: 26, deprecated: false },
    { label: 'Business Analyst', value: 27, deprecated: false },
    { label: 'Database Analyst', value: 28, deprecated: false },
    { label: 'Database Manager', value: 29, deprecated: false },
    { label: 'Database Administrator', value: 30, deprecated: false },
    { label: 'Data Architect', value: 31, deprecated: false },
    { label: 'Data Modeler', value: 32, deprecated: false },
    { label: 'Data Analyst', value: 33, deprecated: false },
    { label: 'Data Scientist', value: 34, deprecated: false },
    { label: 'Systems Administrator', value: 35, deprecated: false },
    { label: 'Systems Analyst', value: 36, deprecated: false },
    { label: 'Software Architect', value: 37, deprecated: false },
    { label: 'Solutions Architect', value: 38, deprecated: false },
    { label: 'Network Administrator', value: 39, deprecated: false },
    { label: 'Network Architect', value: 40, deprecated: false },
    { label: 'Network Engineer', value: 41, deprecated: false },
    { label: 'Cloud Architect', value: 42, deprecated: false },
    { label: 'Cloud Consultant', value: 43, deprecated: false },
    { label: 'Technical Lead', value: 44, deprecated: false },
    { label: 'DevOps Manager', value: 45, deprecated: false },
    { label: 'DevOps Engineer', value: 46, deprecated: false },
    { label: 'Infrastructure Engineer', value: 47, deprecated: false },
    { label: 'Agile Project Manager', value: 48, deprecated: false },
    { label: 'Product Manager', value: 49, deprecated: false },
    { label: 'Technical Account Manager', value: 50, deprecated: false },
    { label: 'IT Sales Executive', value: 51, deprecated: false },
    { label: 'IT Sales Director', value: 52, deprecated: false },
    { label: 'Technology Specialist', value: 53, deprecated: false },
    { label: 'Security Specialist', value: 54, deprecated: false },
    { label: 'Network Security Engineer', value: 55, deprecated: false },
    { label: 'Information Security Analyst', value: 56, deprecated: false },
    { label: 'Information Security Engineer', value: 57, deprecated: false },
    { label: 'Cyber Security Specialist', value: 58, deprecated: false },
    { label: 'Cyber Security Manager', value: 59, deprecated: false },
    { label: 'Cyber Security Analyst', value: 60, deprecated: false },
    { label: 'Computer Hardware Engineer', value: 61, deprecated: false },
    { label: 'SEO Consultant', value: 62, deprecated: false },
    { label: 'SEO Manager', value: 63, deprecated: false },
    { label: 'Web Analytics Developer', value: 64, deprecated: false },
    { label: 'Digital Marketing Manager', value: 65, deprecated: false },
    { label: 'Digital Marketing Specialist', value: 66, deprecated: false },
    { label: 'Brand Manager', value: 67, deprecated: false },
    { label: 'Social Media Manager', value: 68, deprecated: false },
    { label: 'Content Manager', value: 69, deprecated: false },
    { label: 'Content Strategist', value: 70, deprecated: false },
    { label: 'Content Marketer', value: 71, deprecated: false },
    { label: 'Copywriter', value: 72, deprecated: false },
    { label: 'Information Architect', value: 73, deprecated: false },
    { label: 'Interaction Designer', value: 74, deprecated: false },
    { label: 'Accessibility Specialist', value: 75, deprecated: false },
    { label: 'Help Desk Analyst', value: 76, deprecated: false },
    { label: 'IT Support Specialist', value: 77, deprecated: false },
    { label: 'IT Technician', value: 78, deprecated: false },
    { label: 'IT Manager', value: 79, deprecated: false },
    { label: 'IT Coordinator', value: 80, deprecated: false },
    { label: 'Chief Information Officer', value: 81, deprecated: false },
    { label: 'Chief Technology Officer', value: 82, deprecated: false },
    { label: 'IT Director', value: 83, deprecated: false },
    { label: 'IT Project Manager', value: 84, deprecated: false },
    { label: 'Director of Technology', value: 85, deprecated: false },
    { label: '.NET Developer', value: 86, deprecated: false },
    { label: 'Java Developer', value: 87, deprecated: false },
    { label: 'MERN Stack Developer', value: 88, deprecated: false },
    { label: 'MEAN Stack Developer', value: 89, deprecated: false },
];

export const AssessmentCategories = [
    { label: 'Other', value: 1, deprecated: false },
    { label: 'Technical', value: 2, deprecated: false },
    { label: 'Language', value: 3, deprecated: false },
    { label: 'Personality', value: 4, deprecated: false },
    { label: 'Aptitude', value: 5, deprecated: false },
    { label: 'Behavioral', value: 6, deprecated: false },
    { label: 'Cognitive', value: 7, deprecated: false },
];

export const QuestionCategories = [
    { label: 'MultipleChoice', value: 1, deprecated: false },
    { label: 'SingleChoice', value: 2, deprecated: false },
    { label: 'TrueFalse', value: 3, deprecated: false },
    { label: 'YesNo', value: 4, deprecated: false },
    { label: 'Rating', value: 5, deprecated: false },
];

export const QuestionDifficultyLevels = [
    { label: 'Easy', value: 1, deprecated: false },
    { label: 'Medium', value: 2, deprecated: false },
    { label: 'Hard', value: 3, deprecated: false },
];

export const Countries = [
    {
        value: 1,
        label: 'United States',
        currencyCode: 'USD',
        currencySign: '$',
        countryCode: 'US',
        deprecated: false,
    },
    {
        value: 2,
        label: 'Pakistan',
        currencyCode: 'PKR',
        currencySign: 'Rs',
        countryCode: 'PK',
        deprecated: false,
    },
    {
        value: 3,
        label: 'United Kingdom',
        currencyCode: 'GBP',
        currencySign: '£',
        countryCode: 'GB',
        deprecated: false,
    },
    {
        value: 4,
        label: 'India',
        currencyCode: 'INR',
        currencySign: '₹',
        countryCode: 'IN',
        deprecated: false,
    },
];

export const CandidateExperienceLevel: LookupItem[] = [
    {
        label: 'Entry-level',
        value: 1,
        deprecated: false,
    },
    {
        label: 'Junior',
        value: 2,
        deprecated: false,
    },
    {
        label: 'Mid-level',
        value: 3,
        deprecated: false,
    },
    {
        label: 'Senior',
        value: 4,
        deprecated: false,
    },
    {
        label: 'Executive',
        value: 5,
        deprecated: false,
    },
];

export const Currencies: CurrencyLookupType[] = [
    {
        value: 1,
        label: 'USD',
        currencySign: '$',
        deprecated: false,
    },
    {
        value: 2,
        label: 'PKR',
        currencySign: 'Rs',
        deprecated: false,
    },
    {
        value: 3,
        label: 'GBP',
        currencySign: '£',
        deprecated: false,
    },
];

export const jobApplicationStages: LookupItem[] = [
    {
        label: 'Qualified',
        value: 1,
        deprecated: false,
    },
    {
        label: 'Invited',
        value: 2,
        deprecated: false,
    },
    {
        label: 'Declined',
        value: 3,
        deprecated: false,
    },
    {
        label: 'Interview',
        value: 4,
        deprecated: false,
    },
    {
        label: 'Hired',
        value: 5,
        deprecated: false,
    },
    {
        label: 'Rejected',
        value: 6,
        deprecated: false,
    },
    {
        label: 'Matched',
        value: 7,
        deprecated: false,
    },
];

export const AccountTypes: LookupItem[] = [
    {
        value: 1,
        label: 'Candidate',
        deprecated: false,
    },
    {
        value: 2,
        label: 'Recruiter',
        deprecated: false,
    },
];

export const AccountStatusTypes: LookupItem[] = [
    {
        value: 1,
        label: 'Active',
        deprecated: false,
    },

    {
        value: 2,
        label: 'Disabled',
        deprecated: false,
    },
    {
        value: 3,
        label: 'Deleted',
        deprecated: false,
    },
];

export const JobRoles: LookupItem[] = [
    { label: 'Other', value: 1, deprecated: false },
    { label: 'Frontend Developer', value: 2, deprecated: false },
    { label: 'Web Developer', value: 3, deprecated: false },
    { label: 'Backend Developer', value: 4, deprecated: false },
    { label: 'Full-stack Developer', value: 5, deprecated: false },
    { label: 'Mobile Developer', value: 6, deprecated: false },
    { label: 'Software Developer', value: 7, deprecated: false },
    { label: 'Wordpress Developer', value: 8, deprecated: false },
    { label: 'Ruby on Rails Developer', value: 9, deprecated: false },
    { label: 'Python Developer', value: 10, deprecated: false },
    { label: 'Database Developer', value: 11, deprecated: false },
    { label: 'Systems Engineer', value: 12, deprecated: false },
    { label: 'Software Engineer', value: 13, deprecated: false },
    { label: 'Game Developer', value: 14, deprecated: false },
    { label: 'QA Engineer', value: 15, deprecated: false },
    { label: 'AI Engineer', value: 16, deprecated: false },
    { label: 'Cloud Engineer', value: 17, deprecated: false },
    { label: 'Site Reliability Engineer', value: 18, deprecated: false },
    { label: 'UX Designer', value: 19, deprecated: false },
    { label: 'UI Designer', value: 20, deprecated: false },
    { label: 'UI/UX Designer', value: 21, deprecated: false },
    { label: 'Web Designer', value: 22, deprecated: false },
    { label: 'UI/UX Researcher', value: 23, deprecated: false },
    { label: 'Graphic Designer', value: 24, deprecated: false },
    { label: 'Animation Specialist', value: 25, deprecated: false },
    { label: 'Business System Analyst', value: 26, deprecated: false },
    { label: 'Business Analyst', value: 27, deprecated: false },
    { label: 'Database Analyst', value: 28, deprecated: false },
    { label: 'Database Manager', value: 29, deprecated: false },
    { label: 'Database Administrator', value: 30, deprecated: false },
    { label: 'Data Architect', value: 31, deprecated: false },
    { label: 'Data Modeler', value: 32, deprecated: false },
    { label: 'Data Analyst', value: 33, deprecated: false },
    { label: 'Data Scientist', value: 34, deprecated: false },
    { label: 'Systems Administrator', value: 35, deprecated: false },
    { label: 'Systems Analyst', value: 36, deprecated: false },
    { label: 'Software Architect', value: 37, deprecated: false },
    { label: 'Solutions Architect', value: 38, deprecated: false },
    { label: 'Network Administrator', value: 39, deprecated: false },
    { label: 'Network Architect', value: 40, deprecated: false },
    { label: 'Network Engineer', value: 41, deprecated: false },
    { label: 'Cloud Architect', value: 42, deprecated: false },
    { label: 'Cloud Consultant', value: 43, deprecated: false },
    { label: 'Technical Lead', value: 44, deprecated: false },
    { label: 'DevOps Manager', value: 45, deprecated: false },
    { label: 'DevOps Engineer', value: 46, deprecated: false },
    { label: 'Infrastructure Engineer', value: 47, deprecated: false },
    { label: 'Agile Project Manager', value: 48, deprecated: false },
    { label: 'Product Manager', value: 49, deprecated: false },
    { label: 'Technical Account Manager', value: 50, deprecated: false },
    { label: 'IT Sales Executive', value: 51, deprecated: false },
    { label: 'IT Sales Director', value: 52, deprecated: false },
    { label: 'Technology Specialist', value: 53, deprecated: false },
    { label: 'Security Specialist', value: 54, deprecated: false },
    { label: 'Network Security Engineer', value: 55, deprecated: false },
    { label: 'Information Security Analyst', value: 56, deprecated: false },
    { label: 'Information Security Engineer', value: 57, deprecated: false },
    { label: 'Cyber Security Specialist', value: 58, deprecated: false },
    { label: 'Cyber Security Manager', value: 59, deprecated: false },
    { label: 'Cyber Security Analyst', value: 60, deprecated: false },
    { label: 'Computer Hardware Engineer', value: 61, deprecated: false },
    { label: 'SEO Consultant', value: 62, deprecated: false },
    { label: 'SEO Manager', value: 63, deprecated: false },
    { label: 'Web Analytics Developer', value: 64, deprecated: false },
    { label: 'Digital Marketing Manager', value: 65, deprecated: false },
    { label: 'Digital Marketing Specialist', value: 66, deprecated: false },
    { label: 'Brand Manager', value: 67, deprecated: false },
    { label: 'Social Media Manager', value: 68, deprecated: false },
    { label: 'Content Manager', value: 69, deprecated: false },
    { label: 'Content Strategist', value: 70, deprecated: false },
    { label: 'Content Marketer', value: 71, deprecated: false },
    { label: 'Copywriter', value: 72, deprecated: false },
    { label: 'Information Architect', value: 73, deprecated: false },
    { label: 'Interaction Designer', value: 74, deprecated: false },
    { label: 'Accessibility Specialist', value: 75, deprecated: false },
    { label: 'Help Desk Analyst', value: 76, deprecated: false },
    { label: 'IT Support Specialist', value: 77, deprecated: false },
    { label: 'IT Technician', value: 78, deprecated: false },
    { label: 'IT Manager', value: 79, deprecated: false },
    { label: 'IT Coordinator', value: 80, deprecated: false },
    { label: 'Chief Information Officer', value: 81, deprecated: false },
    { label: 'Chief Technology Officer', value: 82, deprecated: false },
    { label: 'IT Director', value: 83, deprecated: false },
    { label: 'IT Project Manager', value: 84, deprecated: false },
    { label: 'Director of Technology', value: 85, deprecated: false },
    { label: '.NET Developer', value: 86, deprecated: false },
    { label: 'Java Developer', value: 87, deprecated: false },
    { label: 'MERN Stack Developer', value: 88, deprecated: false },
    { label: 'MEAN Stack Developer', value: 89, deprecated: false },
];

export const CandidateRoles: LookupItem[] = [
    {
        label: 'Candidate',
        value: 7,
        deprecated: false,
    },
];

export const RecruiterRoles: LookupItem[] = [
    {
        label: 'Owner',
        value: 4,
        deprecated: false,
    },
    {
        label: 'Manager',
        value: 5,
        deprecated: false,
    },
    {
        label: 'Standard',
        value: 6,
        deprecated: false,
    },
];

export const JobStatuses: LookupItem[] = [
    {
        label: 'Draft',
        value: 1,
        deprecated: false,
    },
    {
        label: 'Pending',
        value: 2,
        deprecated: false,
    },
    {
        label: 'Active',
        value: 3,
        deprecated: false,
    },
    {
        label: 'Expired',
        value: 4,
        deprecated: false,
    },
    {
        label: 'Closed',
        value: 5,
        deprecated: false,
    },
    {
        label: 'Archived',
        value: 6,
        deprecated: false,
    },
];

export const WorkplaceTypes: LookupItem[] = [
    {
        label: 'Onsite',
        value: 1,
        deprecated: false,
    },
    {
        label: 'Hybrid',
        value: 2,
        deprecated: false,
    },
    {
        label: 'Remote',
        value: 3,
        deprecated: false,
    },
];
