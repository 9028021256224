import { useState, useEffect, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { BackdropLayout, ScrollArea, Sidebar, color } from '@pelpr/pelpr-ui';
import { primaryNavItems, secondaryNavItems } from 'src/config/constant';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/hooks';

const AppRoot = styled.div({
    display: 'flex',
    position: 'relative',
    height: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
});

const AppContent = styled.div({
    width: '100%',

    '.content': {
        flex: 1,
        position: 'relative',
    },
});

const AppMain = styled.div`
    width: 100%;
    background-color: ${color.lightNeutral300};
`;

const MainLayout = () => {
    const [searchParams] = useSearchParams();
    const {
        user,
        isLoading,
        isAuthenticated,
        handleSignIn,
        handleSignOut,
        firstName,
        lastName,
        profileImageUrl,
    } = useAuth();
    const [isShowLoader, setIsShowLoader] = useState(true);

    useLayoutEffect(() => {
        document.querySelector('.root-app-scroll-area')?.scrollTo({ top: 0, left: 0 });
    }, [location.pathname]);

    useEffect(() => {
        if (!isLoading && isAuthenticated && user?.accountType !== 'Management') {
            handleSignOut();
        } else if (!isLoading && !isAuthenticated && !searchParams.get('code')) {
            handleSignIn();
        } else setIsShowLoader(false);
    }, [isAuthenticated, isLoading]);

    return isAuthenticated && !isLoading && !isShowLoader ? (
        <AppRoot>
            <Sidebar
                primaryNavItems={primaryNavItems}
                secondaryNavItems={secondaryNavItems}
                firstName={firstName as string}
                lastName={lastName as string}
                profileImageUrl={profileImageUrl}
                userMenuItem={[
                    {
                        icon: 'arrowAction',
                        title: 'Log out',
                        onClick: handleSignOut,
                    },
                ]}
            />

            <ScrollArea
                className='root-app-scroll-area'
                orientation='both'
                type='auto'
                width='100%'
                zIndex={1200}>
                <AppContent>
                    <main className='content'>
                        <AppMain>
                            <Outlet />
                        </AppMain>
                    </main>
                </AppContent>
            </ScrollArea>
        </AppRoot>
    ) : (
        <BackdropLayout open={isLoading || isShowLoader} />
    );
};

export default MainLayout;
