import { useContext } from 'react';
import { AuthStateType, authContext } from 'src/providers/AuthProvider';

interface AuthDataType extends AuthStateType {
    isRecruiter: boolean;
    userId: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
}

export const useAuth = (): AuthDataType => {
    const context = useContext(authContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    const isRecruiter = context?.user?.accountType === 'Recruiter';
    const firstName = context?.user?.firstName;
    const lastName = context?.user?.lastName;

    const profileImageUrl = `${
        process.env.REACT_APP_FILE_STORAGE_BASE_PATH
    }profile-images/${context?.user?.username}.png?nocache=${Date.now()}`;

    return {
        ...context,
        userId: context?.user?.username ?? '',
        isRecruiter,
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        profileImageUrl,
    };
};

export default useAuth;
