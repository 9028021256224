import {
    Box,
    Headline,
    Modal,
    ModalBody,
    ModalHeader,
    Paragraph,
    spacing,
    Input,
    Select,
    CitySearchAutoComplete,
    PhoneInput,
    Button,
    ModalFooter,
    Switch,
} from '@pelpr/pelpr-ui';
import { useEffect } from 'react';
import * as yup from 'yup';
import { useFormik, FormikHelpers } from 'formik';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getUserAccount, updateUserAccount } from 'src/redux/user/userApi';
import { isValidPhoneNumber } from 'src/utilities/helpers';
import {
    CandidateRoles,
    Countries,
    JobsRolesLookups,
    RecruiterRoles,
} from 'src/config/lookups';
import CountryPicker from '../common/inputs/CountryPicker';
import { UserAccountTypes } from 'src/modal';

const validationSchema = yup.object({
    firstName: yup
        .string()
        .min(3, 'First name should be of minimum 3 characters length.')
        .max(64, 'First name should be of maximum 64 characters length.')
        .required('First name is required.'),
    lastName: yup
        .string()
        .min(3, 'Last name should be of minimum 3 characters length.')
        .max(64, 'Last name should be of maximum 64 characters length.')
        .required('Last name is required.'),
    mobile: yup
        .string()
        .notRequired()
        .test('is-valid-mobile', 'Invalid mobile number', (value) => {
            if (!value) return true; // Allow empty value
            // Skip validation if only country code is present
            const countryCodePattern = /^\+\d+$/;
            if (countryCodePattern.test(value)) return true;
            return isValidPhoneNumber(value); // Validate if not only country code
        }),
    city: yup
        .string()
        .min(3, 'City should be of minimum 3 characters length.')
        .max(64, 'City should be of maximum 64 characters length.')
        .required('City is required.'),
    country: yup.string().required('Country is required.'),
    jobRole: yup.string().required('Job role is required.'),
    roles: yup.array().of(yup.string()).min(1, 'Role is required.'),
    emailConfirmed: yup.boolean(),
    mobileConfirmed: yup.boolean(),
});

interface UserAccountEditType {
    firstName: string;
    lastName: string;
    mobile: string;
    city: string;
    country: string;
    jobRole: string;
    roles: string[];
    emailConfirmed: boolean;
    mobileConfirmed: boolean;
}

interface UserAccountEditModalProps {
    show: boolean;
    onClose: () => void;
    userId: string;
}

const UserAccountEditModal = ({ userId, show, onClose }: UserAccountEditModalProps) => {
    const { userAccountData, userLoading, updatingUserAccount } = useAppSelector(
        (state) => state.user,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userId) {
            dispatch(getUserAccount({ userId }));
        }
    }, [userId]);

    const submitForm = async (
        values: UserAccountEditType,
        { setSubmitting }: FormikHelpers<UserAccountEditType>,
    ) => {
        dispatch(
            updateUserAccount({
                userId,
                payload: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    mobile: values.mobile,
                    city: values.city,
                    country: Number(values.country),
                    jobRole: Number(values.jobRole),
                    roleIds: values.roles?.map(Number),
                    emailConfirmed: values.emailConfirmed,
                    mobileConfirmed: values.mobileConfirmed,
                },
                onSuccess: () => {
                    setSubmitting(false);
                    onClose();
                },
            }),
        );
    };

    const formik = useFormik<UserAccountEditType>({
        initialValues: {
            firstName: '',
            lastName: '',
            mobile: '',
            city: '',
            country: '',
            jobRole: '',
            roles: [],
            emailConfirmed: false,
            mobileConfirmed: false,
        },
        validationSchema,
        onSubmit: submitForm,
    });

    useEffect(() => {
        if (userAccountData) {
            formik.setFieldValue('firstName', userAccountData?.firstName || '');
            formik.setFieldValue('lastName', userAccountData?.lastName || '');
            formik.setFieldValue('mobile', userAccountData?.mobile?.toString() || '');
            formik.setFieldValue('city', userAccountData?.city || '');
            formik.setFieldValue(
                'country',
                userAccountData?.country ? userAccountData?.country?.toString() : '',
            );
            formik.setFieldValue(
                'jobRole',
                userAccountData?.jobRole ? userAccountData?.jobRole?.toString() : '',
            );
            formik.setFieldValue(
                'roles',
                userAccountData?.roles?.map((role) => role?.toString()) || [],
            );
            formik.setFieldValue(
                'emailConfirmed',
                userAccountData?.setupFlags?.emailConfirmed || false,
            );
            formik.setFieldValue(
                'mobileConfirmed',
                userAccountData?.setupFlags?.mobileConfirmed || false,
            );
        }
    }, [userAccountData]);


    return (
        <Modal
            uniqueKey='assessment-modal'
            show={show}
            onClose={onClose}
            sx={{ width: '405px' }}>
            <ModalHeader>
                <Headline variant='h6'>Edit User</Headline>
            </ModalHeader>

            <ModalBody
                sx={{
                    padding: `${spacing(5)} ${spacing(6)}`,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(5),
                }}>
                {userLoading && userAccountData === null ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(5),
                        }}>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                }}>
                                <Skeleton height={15} width={100} />
                                <Skeleton height={40} width={350} />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <>
                        <Paragraph weight='regular' sx={{ marginBottom: spacing(1) }}>
                            * indicates required fields
                        </Paragraph>
                        <Input
                            fullWidth
                            label='First Name'
                            name='firstName'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            isInvalid={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                            }
                            onBlur={formik.handleBlur}
                            helperText={
                                (formik.touched.firstName && formik.errors.firstName) ||
                                ''
                            }
                            required
                        />

                        <Input
                            fullWidth
                            label='Last Name'
                            name='lastName'
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            isInvalid={
                                formik.touched.lastName && Boolean(formik.errors.lastName)
                            }
                            onBlur={formik.handleBlur}
                            helperText={
                                (formik.touched.lastName && formik.errors.lastName) || ''
                            }
                            required
                        />

                        <PhoneInput
                            fullWidth
                            defaultCountry='pk'
                            id='mobile'
                            label='Mobile No'
                            value={formik.values.mobile}
                            onChange={(phoneNumber) => {
                                formik.setFieldValue('mobile', phoneNumber);
                            }}
                            isInvalid={
                                formik.touched.mobile && Boolean(formik.errors.mobile)
                            }
                            onBlur={formik.handleBlur}
                            helperText={
                                (formik.touched.mobile && formik.errors.mobile) || ''
                            }
                        />

                        <CountryPicker
                            fullWidth
                            label='Country'
                            name='country'
                            defaultValue={Number(formik.values.country)}
                            onSelected={(value) => {
                                formik.setFieldValue('country', value, true);
                                if (Number(formik.values.country) !== value) {
                                    formik.setFieldValue('city', '');
                                }
                            }}
                            hasError={
                                formik.touched.country && Boolean(formik.errors.country)
                            }
                            helperText={
                                (formik.touched.country && formik.errors.country) || ''
                            }
                        />

                        <CitySearchAutoComplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                            name='city'
                            countryCode={
                                Countries?.find(
                                    (country) =>
                                        country?.value === Number(formik.values?.country),
                                )?.countryCode as string
                            }
                            required
                            fullWidth
                            label='City'
                            selectedOptions={[formik.values.city]}
                            onChange={(value) =>
                                formik.setFieldValue('city', value?.[0] || '', true)
                            }
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={(formik.touched.city && formik.errors.city) || ''}
                        />

                        {userAccountData?.accountType === UserAccountTypes.Candidate && (
                            <Select
                                fullWidth
                                required
                                name='jobRole'
                                label='Job Role'
                                options={JobsRolesLookups}
                                selectedOptions={[formik.values.jobRole]}
                                onChange={(value) => {
                                    formik.setFieldValue('jobRole', value[0], true);
                                }}
                                error={
                                    formik.touched.jobRole &&
                                    Boolean(formik.errors.jobRole)
                                }
                                helperText={
                                    (formik.touched.jobRole &&
                                        (formik.errors.jobRole as string)) ||
                                    ''
                                }
                            />
                        )}
                        <Select
                            fullWidth
                            name='accountType'
                            label='Account role'
                            options={
                                userAccountData?.accountType ===
                                UserAccountTypes.Candidate
                                    ? CandidateRoles?.map((item) => ({
                                          label: item.label,
                                          value: item.value?.toString(),
                                      }))
                                    : RecruiterRoles?.map((item) => ({
                                          label: item.label,
                                          value: item.value?.toString(),
                                      }))
                            }
                            selectedOptions={formik.values.roles}
                            onChange={(value) => {
                                formik.setFieldValue('roles', value, true);
                            }}
                            error={formik.touched.roles && Boolean(formik.errors.roles)}
                            helperText={
                                (formik.touched.roles &&
                                    (formik.errors.roles as string)) ||
                                ''
                            }
                        />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: spacing(4),
                            }}>
                            <Paragraph>Email verified</Paragraph>
                            <Switch
                                id='emailConfirmed'
                                onChange={() =>
                                    formik.setFieldValue(
                                        'emailConfirmed',
                                        !formik.values.emailConfirmed,
                                    )
                                }
                                defaultChecked={formik.values.emailConfirmed}
                                size='sm'
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: spacing(4),
                            }}>
                            <Paragraph>Mobile verified</Paragraph>
                            <Switch
                                id='mobileConfirmed'
                                onChange={() =>
                                    formik.setFieldValue(
                                        'mobileConfirmed',
                                        !formik.values.mobileConfirmed,
                                    )
                                }
                                defaultChecked={formik.values.mobileConfirmed}
                                size='sm'
                            />
                        </Box>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                {userLoading && userAccountData === null ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(3),
                        }}>
                        <Skeleton width={80} height={40} />
                        <Skeleton width={80} height={40} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(3),
                        }}>
                        <Button
                            type='submit'
                            onClick={formik.submitForm}
                            loading={updatingUserAccount}
                            disabled={updatingUserAccount}>
                            Save
                        </Button>
                        <Button
                            variant='ghost'
                            onClick={onClose}
                            disabled={updatingUserAccount}>
                            Cancel
                        </Button>
                    </Box>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default UserAccountEditModal;
