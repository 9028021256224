import React, { useState } from 'react';
import { Box, ReactTooltip, color } from '@pelpr/pelpr-ui';
//icons
import { ReactComponent as CopyIcon } from 'src/assets/vectors/commons/copy-icon.svg';

type TooltipPlace =
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';

interface CopyToClipboardProps {
    value: string;
    label: string;
    tooltipPlace?: TooltipPlace;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
    value,
    label,
    tooltipPlace = 'top',
}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleClick = async () => {
        try {
            await navigator.clipboard.writeText(value);

            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };

    return isCopied ? (
        <Box>
            <ReactTooltip.Tooltip
                id={'copy'}
                place={tooltipPlace}
                content={'Copied'}
                style={{ zIndex: 9999 }}
                variant='success'
                isOpen={true}
            />
            <Box
                data-tooltip-id={'copy'}
                onClick={handleClick}
                sx={{
                    width: '18px',
                    minWidth: '18px',
                    height: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    color: color.lightNeutral700,
                }}>
                <CopyIcon />
            </Box>
        </Box>
    ) : (
        <>
            <ReactTooltip.Tooltip
                id={label}
                place={tooltipPlace}
                content={label}
                style={{ zIndex: 9999 }}
                variant='dark'
            />
            <Box
                data-tooltip-id={label}
                onClick={handleClick}
                sx={{
                    width: '18px',
                    minWidth: '18px',
                    height: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    color: color.lightNeutral700,
                }}>
                <CopyIcon />
            </Box>
        </>
    );
};

export default CopyToClipboard;
