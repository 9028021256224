import { UpdateUserDataType } from 'src/modal';
import { axiosInstance } from 'src/utilities/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_GATEWAY_URL}`;

export const fetchAuthenticatedUserInfo = (payload: { userId: string }) => {
    return axiosInstance.get(`${API_URL}/v1/admin/users/${payload.userId}`);
};

export const fetchAllUsersAccountRecords = (includeDeleted: boolean) => {
    return axiosInstance.get(
        `${API_URL}/v1/admin/users?includeDeleted=${includeDeleted}`,
    );
};

export const fetchUserAccountRecord = (userId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/users/${userId}`);
};

export const deleteUserAccountRecord = (userId: string) => {
    return axiosInstance.delete(`${API_URL}/v1/admin/users/${userId}`);
};

export const updateUserAccountStatusRecord = (userId: string, actionId: number) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/users/${userId}/account-status?action=${actionId === 1 ? 'enable' : 'disable'}`,
    );
};

export const updateUserAccountRecord = (userId: string, payload: UpdateUserDataType) => {
    return axiosInstance.put(`${API_URL}/v1/admin/users/${userId}`, {
        ...payload,
    });
};
