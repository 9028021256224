export interface AuthenticatedUserInfo {
    accountType: number;
    city: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
    id: string;
    mobile: string;
    organizationMemberRole: number;
    organizationId: string;
    organizationName: string;
    transactionCurrency: number;
    setupFlags: {
        emailConfirmed: boolean;
        mobileConfirmed: boolean;
        billingCompleted: boolean;
    };
}

export interface UserListDataType {
    id: string;
    name: string;
    email: string;
    photoUrl: string;
    accountTypeId: number;
    countryId: number;
    lastActive: string;
    accountStatusId: number;
    createdAt: string;
    updatedAt: string;
}

export interface UserDataType {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: null | string;
    city: string;
    country: number;
    photoUrl: string;
    accountType: number;
    jobRole: number;
    roles: number[];
    organizationId: string;
    transactionCurrency: number;
    organizationName: string;
    setupFlags: SetupFlagsDataType;
    lastLoginAt: string | null;
    createdAt: string;
    updatedAt: string | null;
}

export interface SetupFlagsDataType {
    emailConfirmed: boolean;
    mobileConfirmed: boolean;
}

export interface UpdateUserDataType {
    firstName: string;
    lastName: string;
    mobile: string;
    country: number;
    city: string;
    jobRole: number;
    roleIds?: number[];
    emailConfirmed: boolean;
    mobileConfirmed: boolean;
}

export enum UserAccountStatus {
    Active = 1,
    Disabled = 2,
    Deleted = 3,
}

export enum UserAccountTypes {
    Candidate = 1,
    Recruiter = 2,
}
