import { createSlice } from '@reduxjs/toolkit';
import {
    ApprovalRequestDataType,
    CandidateDetailsDataType,
    CandidateListDataType,
    CandidateMetadata,
} from 'src/modal';
import {
    addCandidateProfileNote,
    addCandidateProfileToJob,
    approveCandidateProfile,
    fetchCandidateDetails,
    getCandidateAssessments,
    getCandidateProfileNote,
    getCandidatesApprovals,
    getCandidatesList,
    rejectCandidateProfile,
    resetCandidateProfileStatus,
    setCandidateExperienceLevel,
    setCandidateTopTalent,
} from './candidateApi';
import { CandidateExperienceLevel } from 'src/config/lookups';
import { AssessmentResult, NoteDataType } from 'src/modal/candidate';

export interface InitialStateType {
    candidatesList: CandidateListDataType[];
    metadata: CandidateMetadata | null;
    candidatesApprovals: ApprovalRequestDataType[];
    notes: NoteDataType[];
    candidateDetails: CandidateDetailsDataType | null;
    fetchingCandidatesApprovals: boolean;
    updatingCandidateProfileStatus: boolean;
    fetchingCandidateDetails: boolean;
    candidateProfileUpdating: boolean;
    fetchingNotes: boolean;
    addingNotes: boolean;
    assessmentsLoading: boolean;
    assessmentResults: AssessmentResult[];
    removingAttempt: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: InitialStateType = {
    candidatesList: [],
    metadata: null,
    candidatesApprovals: [],
    candidateDetails: null,
    notes: [],
    fetchingCandidatesApprovals: false,
    updatingCandidateProfileStatus: false,
    fetchingCandidateDetails: false,
    candidateProfileUpdating: false,
    fetchingNotes: false,
    assessmentsLoading: false,
    addingNotes: false,
    assessmentResults: [],
    removingAttempt: false,
    loading: false,
    error: null,
};

const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch all candidates
        builder.addCase(getCandidatesList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCandidatesList.fulfilled, (state, action) => {
            state.candidatesList = action.payload.items;
            state.metadata = action.payload.metadata;
            state.loading = false;
        });
        builder.addCase(getCandidatesList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch all candidates approvals
        builder.addCase(getCandidatesApprovals.pending, (state) => {
            state.fetchingCandidatesApprovals = true;
            state.error = null;
        });
        builder.addCase(getCandidatesApprovals.fulfilled, (state, action) => {
            state.candidatesApprovals = action.payload;
            state.fetchingCandidatesApprovals = false;
        });
        builder.addCase(getCandidatesApprovals.rejected, (state, action) => {
            state.fetchingCandidatesApprovals = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // approve candidate profile
        builder.addCase(approveCandidateProfile.pending, (state) => {
            state.updatingCandidateProfileStatus = true;
            state.error = null;
        });
        builder.addCase(approveCandidateProfile.fulfilled, (state, action) => {
            const previousCandidatesList: ApprovalRequestDataType[] = [
                ...state.candidatesApprovals,
            ];
            const index = previousCandidatesList?.findIndex(
                (item) => item?.id === action.payload,
            );
            if (index !== -1) {
                previousCandidatesList[index].status = 'Approved';
                previousCandidatesList[index].approvedOn = new Date().toISOString();
                state.candidatesApprovals = previousCandidatesList;
            }
            state.updatingCandidateProfileStatus = false;
        });
        builder.addCase(approveCandidateProfile.rejected, (state, action) => {
            state.updatingCandidateProfileStatus = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // reject candidate profile
        builder.addCase(rejectCandidateProfile.pending, (state) => {
            state.updatingCandidateProfileStatus = true;
            state.error = null;
        });
        builder.addCase(rejectCandidateProfile.fulfilled, (state, action) => {
            const previousCandidatesList: ApprovalRequestDataType[] = [
                ...state.candidatesApprovals,
            ];
            const index = previousCandidatesList?.findIndex(
                (item) => item?.id === action.payload,
            );
            if (index !== -1) {
                previousCandidatesList[index].status = 'Rejected';
                previousCandidatesList[index].rejectedOn = new Date().toISOString();
                state.candidatesApprovals = previousCandidatesList;
            }
            state.updatingCandidateProfileStatus = false;
        });
        builder.addCase(rejectCandidateProfile.rejected, (state, action) => {
            state.updatingCandidateProfileStatus = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // reset candidate profile's status
        builder.addCase(resetCandidateProfileStatus.pending, (state) => {
            state.updatingCandidateProfileStatus = true;
            state.error = null;
        });
        builder.addCase(resetCandidateProfileStatus.fulfilled, (state, action) => {
            const previousCandidatesList: ApprovalRequestDataType[] = [
                ...state.candidatesApprovals,
            ];
            state.candidatesApprovals = previousCandidatesList?.filter(
                (item) => item?.id !== action.payload,
            );

            state.updatingCandidateProfileStatus = false;
        });
        builder.addCase(resetCandidateProfileStatus.rejected, (state, action) => {
            state.updatingCandidateProfileStatus = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch candidate details
        builder.addCase(fetchCandidateDetails.pending, (state) => {
            state.fetchingCandidateDetails = true;
            state.error = null;
        });
        builder.addCase(fetchCandidateDetails.fulfilled, (state, action) => {
            state.candidateDetails = action.payload;
            state.fetchingCandidateDetails = false;
        });
        builder.addCase(fetchCandidateDetails.rejected, (state, action) => {
            state.fetchingCandidateDetails = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // set candidate experience level
        builder.addCase(setCandidateExperienceLevel.pending, (state) => {
            state.candidateProfileUpdating = true;
            state.error = null;
        });
        builder.addCase(setCandidateExperienceLevel.fulfilled, (state, action) => {
            const previousData = {
                ...state.candidateDetails,
            };
            if (previousData?.id === action.payload.candidateId) {
                const experienceLevel = CandidateExperienceLevel?.find(
                    (item) => item?.value === action.payload.experienceLevel,
                )?.label;
                if (experienceLevel) {
                    state.candidateDetails = {
                        ...previousData,
                        experienceLevel: experienceLevel,
                    } as CandidateDetailsDataType;
                }
            }

            state.candidateProfileUpdating = false;
        });
        builder.addCase(setCandidateExperienceLevel.rejected, (state, action) => {
            state.candidateProfileUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // set candidate profile to top talent
        builder.addCase(setCandidateTopTalent.pending, (state) => {
            state.candidateProfileUpdating = true;
            state.error = null;
        });
        builder.addCase(setCandidateTopTalent.fulfilled, (state, action) => {
            const previousData = {
                ...state.candidateDetails,
            };
            if (previousData?.id === action.payload.candidateId) {
                state.candidateDetails = {
                    ...previousData,
                    isTopTalent: !previousData?.isTopTalent,
                } as CandidateDetailsDataType;
            }

            state.candidateProfileUpdating = false;
        });
        builder.addCase(setCandidateTopTalent.rejected, (state, action) => {
            state.candidateProfileUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // add  candidate to job
        builder.addCase(addCandidateProfileToJob.pending, (state) => {
            state.candidateProfileUpdating = true;
            state.error = null;
        });
        builder.addCase(addCandidateProfileToJob.fulfilled, (state) => {
            state.candidateProfileUpdating = false;
        });
        builder.addCase(addCandidateProfileToJob.rejected, (state, action) => {
            state.candidateProfileUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // get notes on candidate profile
        builder.addCase(getCandidateProfileNote.pending, (state) => {
            state.fetchingNotes = true;
            state.error = null;
        });
        builder.addCase(getCandidateProfileNote.fulfilled, (state, action) => {
            state.notes = action.payload;
            state.fetchingNotes = false;
        });
        builder.addCase(getCandidateProfileNote.rejected, (state, action) => {
            state.fetchingNotes = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // add note on candidate profile
        builder.addCase(addCandidateProfileNote.pending, (state) => {
            state.addingNotes = true;
            state.error = null;
        });
        builder.addCase(addCandidateProfileNote.fulfilled, (state, action) => {
            const previousNotes = [...state.notes];
            state.notes = [action.payload, ...previousNotes];
            state.addingNotes = false;
        });
        builder.addCase(addCandidateProfileNote.rejected, (state, action) => {
            state.addingNotes = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // get candidate assessments
        builder.addCase(getCandidateAssessments.pending, (state) => {
            state.assessmentsLoading = true;
            state.error = null;
        });
        builder.addCase(getCandidateAssessments.fulfilled, (state, action) => {
            state.assessmentResults = action.payload?.assessmentResults;
            state.assessmentsLoading = false;
        });
        builder.addCase(getCandidateAssessments.rejected, (state, action) => {
            state.assessmentsLoading = false;
            state.error = action.error.message || 'Something went wrong';
        });
    },
});

export default candidateSlice.reducer;
