import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Box, Textarea, Button, spacing } from '@pelpr/pelpr-ui';
import { DescriptionRegex } from 'src/utilities/helpers';

const MIN_TEXTAREA_HEIGHT = 96;

interface NoteInputProps {
    loading?: boolean;
    minHeight?: number;
    value: string;
    onChange: (value: string) => void;
    onSend: () => void;
}
const NoteInput = ({
    loading,
    minHeight,
    value = '',
    onChange,
    onSend,
}: NoteInputProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [validationError, setValidationError] = useState('');

    const minInputHeight = minHeight || MIN_TEXTAREA_HEIGHT;

    const handleChangeInputValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value && !DescriptionRegex.test(event.target.value)) {
            setValidationError(
                'Note can only contain alphanumeric characters, spaces, and these special characters: : , . # - \' " ! ? & % = _ + / () ;',
            );
        } else {
            validationError && setValidationError('');
        }
        onChange(event.target.value);
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                minInputHeight,
            )}px`;
        }
    }, [value]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
            }}>
            <Textarea
                id='message'
                name='message'
                value={value}
                onChange={handleChangeInputValue}
                disabled={loading}
                isInvalid={!!validationError}
                helperText={validationError}
                fullWidth
                ref={textareaRef}
                placeholder='Type here...'
                sx={{
                    textarea: {
                        minHeight: minInputHeight,
                        resize: 'none',
                        overflow: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'transparent transparent',
                        '&::-webkit-scrollbar': {
                            width: '0px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'transparent',
                        },
                    },
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                <Button
                    onClick={onSend}
                    loading={loading}
                    disabled={loading || !!validationError || !value?.trim()}>
                    Add Note
                </Button>
            </Box>
        </Box>
    );
};

export default NoteInput;
