import { Box, Paper, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const JobDetailsSidePanelSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '100%',
                maxWidth: '400px',
                minWidth: '400px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
                padding: spacing(6),
                borderRadius: spacing(1.25),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Skeleton width={120} height={32} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={16} />
                    <Skeleton width={100} height={20} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Skeleton width={120} height={24} />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        gap: spacing(2),
                    }}>
                    {[1, 2, 3, 4, 6, 7, 8, 9, 10]?.map((item) => (
                        <Skeleton key={item} width={48} height={28} />
                    ))}
                </Box>
            </Box>
        </Paper>
    );
};

export default JobDetailsSidePanelSkeleton;
