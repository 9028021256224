import {
    Box,
    Headline,
    Paper,
    Paragraph,
    RichTextViewer,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import React, { useState } from 'react';

import { useAppSelector } from 'src/hooks';
import OrganizationPlaceholder from 'src/assets/vectors/commons/organization-placeholder.svg';

const JobOverviewTab = () => {
    const { jobDetails } = useAppSelector((state) => state.job);

    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
                borderRadius: 0,
                borderBottomLeftRadius: spacing(1.25),
                borderBottomRightRadius: spacing(1.25),
                padding: `${spacing(6)} `,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing(4),
                }}>
                <img
                    alt='organization logo'
                    src={
                        imageLoadError
                            ? OrganizationPlaceholder
                            : jobDetails?.organizationLogoUrl
                    }
                    style={{
                        width: '88px',
                        height: '88px',
                        borderRadius: '5px',
                        objectFit: 'cover',
                    }}
                    onError={handleImageError}
                />

                <Box>
                    <Headline variant='h5'>{jobDetails?.title}</Headline>
                    <Paragraph
                        sx={{
                            color: color.midNeutral500,
                            marginTop: spacing(1),
                            lineHeight: spacing(5),
                        }}>
                        {jobDetails?.organization}
                    </Paragraph>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: spacing(2),
                            marginTop: spacing(3),
                        }}>
                        {jobDetails?.locations.map((location, index) => {
                            return (
                                <React.Fragment key={location}>
                                    <Paragraph
                                        sx={{
                                            color: color.darkNeutral300,
                                            lineHeight: spacing(5),
                                        }}>
                                        {location}
                                    </Paragraph>
                                    {/* If NOT the last element, show the dot */}
                                    {index + 1 < jobDetails?.locations?.length && (
                                        <Box
                                            sx={{
                                                width: '4px',
                                                height: '4px',
                                                borderRadius: '50%',
                                                backgroundColor: color.midNeutral300,
                                            }}></Box>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box>
                <RichTextViewer html={jobDetails?.description || ''} />
            </Box>
        </Paper>
    );
};

export default JobOverviewTab;
