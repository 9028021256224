import { Box, Paper, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const CandidateListCardSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: spacing(4),
                borderRadius: spacing(1.25),
                padding: spacing(6),
                position: 'relative',
            }}>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(4),
                }}>
                <Skeleton width={spacing(16)} height={spacing(16)} />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(1),
                    }}>
                    <Skeleton width={100} height={18} />

                    <Skeleton width={200} height={16} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={14} />
                    <Skeleton width={80} height={16} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={14} />
                    <Skeleton width={90} height={16} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={14} />
                    <Skeleton width={100} height={16} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={14} />
                    <Skeleton width={120} height={16} />
                </Box>
            </Box>
        </Paper>
    );
};

export default CandidateListCardSkeleton;
