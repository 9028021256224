import { Box, ConfirmationDialog, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    resetCandidateProfileStatus,
    fetchCandidateDetails,
} from 'src/redux/candidate/candidateApi';
interface CandidateProfileStatusResetModalProps {
    page?: string;
    open: boolean;
    onClose: () => void;
    candidateId: string;
}

const CandidateProfileStatusResetModal = ({
    page,
    open,
    onClose,
    candidateId,
}: CandidateProfileStatusResetModalProps) => {
    const { updatingCandidateProfileStatus } = useAppSelector((state) => state.candidate);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleReset = () => {
        dispatch(
            resetCandidateProfileStatus({
                candidateId,
                onSuccess: () => {
                    if (page) {
                        dispatch(fetchCandidateDetails({ candidateId }));
                    }
                    onClose();
                },
            }),
        );
    };

    return (
        <ConfirmationDialog
            uniqueKey='reset-candidate-profile-status'
            actionType='delete'
            onConfirm={handleReset}
            onDeny={handleCancel}
            loading={updatingCandidateProfileStatus}
            show={open}
            title='Reset approval workflow'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Reset'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Paragraph
                    size='medium'
                    weight='semi-bold'
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    Are you sure you want to reset the approval workflow?
                </Paragraph>
            </Box>
        </ConfirmationDialog>
    );
};

export default CandidateProfileStatusResetModal;
