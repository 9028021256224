import { Box, Paragraph, ConfirmationDialog, spacing, color } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { rejectMatchedJobApplication } from 'src/redux/job/jobApi';

interface JobApplicantRejectModalProps {
    open: boolean;
    onClose: () => void;
    jobApplicationId: string;
    version: string;
}

const JobApplicantRejectModal = ({
    open,
    onClose,
    jobApplicationId,
    version,
}: JobApplicantRejectModalProps) => {
    const { updatingJobApplicant } = useAppSelector((state) => state.job);

    const dispatch = useAppDispatch();

    const onConfirm = () => {
        dispatch(
            rejectMatchedJobApplication({
                jobApplicationId,
                version,
                onSuccess: () => {
                    onClose();
                },
            }),
        );
    };

    return (
        <ConfirmationDialog
            uniqueKey='reject-applicant'
            actionType='delete'
            onConfirm={onConfirm}
            onDeny={onClose}
            loading={updatingJobApplicant}
            show={open}
            title='Reject Applicant'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Reject'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Paragraph
                    size='medium'
                    weight='semi-bold'
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    Are sure to approve the applicant?
                </Paragraph>
            </Box>
        </ConfirmationDialog>
    );
};

export default JobApplicantRejectModal;
