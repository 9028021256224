import { useState } from 'react';
import { PageContainer, PageHeader, Box, spacing, Stepper } from '@pelpr/pelpr-ui';
import AssessmentBasicInfo from 'src/components/assessments/AssessmentBasicInfo';
import AssessmentDetails from 'src/components/assessments/AssessmentDetails';
import AssessmentSettings from 'src/components/assessments/AssessmentSettings';
import { useAppDispatch } from 'src/hooks';
import { AssessmentDataType } from 'src/modal';
import { addNewAssessment } from 'src/redux/assessments/assessmentsApi';
import { useNavigate } from 'react-router-dom';

const stepContents = [
    { key: 'basic-information', label: 'Basic Information' },
    { key: 'assessment-details', label: 'Assessment Details' },
    { key: 'settings', label: 'Settings' },
];

const initialValues: AssessmentDataType = {
    id: '',
    name: '',
    summary: '',
    description: '',
    quizCategory: 1,
    jobRoles: [],
    tags: [],
    settings: {
        numberOfQuestions: 1,
        timeLimit: 0,
        passPercentage: 0,
        maxAttemptsAllowed: 1,
    },
    isPublished: false,
    isDeleted: false,
    imageUrl: '',
    createdAt: '',
    updatedAt: '',
    version: '',
};

const AddNewAssessment = () => {
    const [assessmentData, setAssessmentData] =
        useState<AssessmentDataType>(initialValues);
    const [currentStep, setCurrentStep] = useState(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectStepHandler = (step: number) => setCurrentStep(step);

    const stepIncrementHandler = () => {
        setCurrentStep((prev) => (prev + 1 > stepContents.length ? prev : ++prev));
    };

    const stepDecrementHandler = () => {
        setCurrentStep((prev) => (prev - 1 <= 0 ? prev : --prev));
    };

    const handleSubmit = (newAssessmentData: AssessmentDataType, newStep?: number) => {
        if (newStep && newStep < 4) {
            setAssessmentData(newAssessmentData);
            stepIncrementHandler();
        } else {
            setAssessmentData(newAssessmentData);
            dispatch(
                addNewAssessment({
                    assessmentData: newAssessmentData as AssessmentDataType,
                    onSuccess: () => navigate('/assessments'),
                }),
            );
        }
    };

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Assessment' />}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(5),
                }}>
                <>
                    <Stepper
                        currentStep={currentStep}
                        steps={stepContents}
                        onSelectStep={selectStepHandler}
                    />

                    <Box
                        sx={{
                            flexGrow: 1,
                        }}>
                        {currentStep === 1 && (
                            <AssessmentBasicInfo
                                contentType='step'
                                steps={stepContents.length}
                                currentStep={currentStep}
                                onPrevious={stepDecrementHandler}
                                assessmentData={assessmentData}
                                onSubmit={handleSubmit}
                            />
                        )}
                        {currentStep === 2 && (
                            <AssessmentDetails
                                contentType='step'
                                steps={stepContents.length}
                                currentStep={currentStep}
                                onPrevious={stepDecrementHandler}
                                assessmentData={assessmentData}
                                onSubmit={handleSubmit}
                            />
                        )}
                        {currentStep === 3 && (
                            <AssessmentSettings
                                contentType='step'
                                steps={stepContents.length}
                                currentStep={currentStep}
                                onPrevious={stepDecrementHandler}
                                assessmentData={assessmentData}
                                onSubmit={handleSubmit}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </PageContainer>
    );
};

export default AddNewAssessment;
