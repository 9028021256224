import { useEffect, useState } from 'react';
import {
    Box,
    Input,
    Paper,
    Paragraph,
    BaseSelect,
    Switch,
    color,
    spacing,
    Button,
    RichTextEditor,
    sanitizeHTML,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    addNewQuestion,
    getQuestion,
    updateQuestion,
} from 'src/redux/question/questionApi';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChoicesData, QuestionCategory } from 'src/modal';
import QuestionChoicesList from './QuestionChoicesList';
import { setAddEditQuestionMode } from 'src/redux/question/questionSlice';
import { CodeTextRegex } from 'src/common/constants/regex';
import QuestionViewSkeleton from './QuestionViewSkeletonSkeleton';

const validationSchema = yup.object().shape({
    questionType: yup.number().required('Question type is required'),
    allowedChoiceCount: yup.number(),
    difficultyIndex: yup.number().required('Difficulty index is required'),
    points: yup
        .number()
        .required('Points are required')
        .positive('Points must be greater than zero'),
    isPublished: yup.boolean().required('Publication status is required'),
    choices: yup.array(),
    text: yup
        .string()
        .matches(
            CodeTextRegex,
            'Text can contain alphanumeric characters, spaces, and the following special characters: : , . # - \' " ! ? & % = _ + / () ; < > { } [ ] * ^ | ~  $  @`.',
        ),
});

const initialValues = {
    id: '',
    text: '',
    questionType: 1,
    allowedChoiceCount: 1,
    difficultyIndex: 1,
    points: 1,
    isPublished: false,
    choices: [],
    isDeleted: false,
};

const initialChoiceValues: ChoicesData = {
    1: [],
    2: [],
    3: [
        {
            id: 1,
            text: 'True',
            isCorrect: false,
            choiceValue: null,
        },
        {
            id: 2,
            text: 'False',
            isCorrect: false,
            choiceValue: null,
        },
    ],
    4: [
        {
            id: 1,
            text: 'Yes',
            isCorrect: false,
            choiceValue: null,
        },
        {
            id: 2,
            text: 'No',
            isCorrect: false,
            choiceValue: null,
        },
    ],
    5: [],
};

const QuestionDetails = () => {
    const { assessmentId } = useParams();
    const { questionCategories, questionDifficultyLevels } = useAppSelector(
        (state) => state.app.lookups,
    );
    const {
        loading,
        questionUpdating,
        question,
        questions,
        activeQuestionId,
        questionEditMode,
    } = useAppSelector((state) => state.question);

    const [choices, setChoices] = useState(initialChoiceValues);
    const [charCount, setCharCount] = useState(0);
    const [choicesError, setChoicesError] = useState('');
    const dispatch = useAppDispatch();

    const questionNumber =
        questionEditMode === 'edit'
            ? questions?.findIndex((q) => q.id === Number(activeQuestionId)) + 1
            : questions?.length + 1;

    useEffect(() => {
        if (assessmentId && activeQuestionId) {
            dispatch(
                getQuestion({
                    quizId: assessmentId,
                    questionId: `${activeQuestionId}`,
                }),
            );
        }
    }, [activeQuestionId]);

    const onFormSubmit = (values: typeof initialValues) => {
        if (
            choices[Number(values?.questionType)]?.length <
            Number(values?.allowedChoiceCount)
        ) {
            setChoicesError(
                'Allowed choice count must be less than or equal to the number of choices',
            );
            return;
        }
        setChoicesError('');
        const newChoices = structuredClone(choices[Number(values?.questionType)]);

        if (Number(values?.questionType) === QuestionCategory.Rating) {
            newChoices[0].isCorrect = true;
            if (newChoices.length < 5) {
                setChoicesError(
                    'Choice count must be greater or equal to 5 for rating question.',
                );
                return;
            }
        }

        if (questionEditMode === 'add' && assessmentId) {
            dispatch(
                addNewQuestion({
                    quizId: assessmentId,
                    questionData: {
                        text: sanitizeHTML(values?.text) as unknown as string,
                        questionType: Number(values?.questionType),
                        allowedChoiceCount:
                            choices[Number(values?.questionType)]?.filter(
                                (choice) => choice.isCorrect,
                            )?.length || Number(values?.allowedChoiceCount),
                        difficultyIndex: Number(values?.difficultyIndex),
                        points: Number(values?.points),
                        choices: newChoices,
                        isPublished: values?.isPublished,
                        isDeleted: false,
                    },
                    onSuccess: (newQuestionId: number) => {
                        dispatch(
                            setAddEditQuestionMode({
                                activeQuestionId: newQuestionId,
                                questionEditMode: 'edit',
                            }),
                        );
                    },
                }),
            );
        } else if (questionEditMode === 'edit' && assessmentId) {
            dispatch(
                updateQuestion({
                    quizId: assessmentId,
                    questionData: {
                        id: question?.id,
                        text: sanitizeHTML(values?.text) as unknown as string,
                        questionType: Number(values?.questionType),
                        allowedChoiceCount:
                            choices[Number(values?.questionType)]?.filter(
                                (choice) => choice.isCorrect,
                            )?.length || Number(values?.allowedChoiceCount),
                        difficultyIndex: Number(values?.difficultyIndex),
                        points: Number(values?.points),
                        choices: newChoices,
                        version: question?.version as string,
                        isPublished: values?.isPublished,
                        isDeleted: false,
                    },
                }),
            );
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: onFormSubmit,
        enableReinitialize: true,
    });

    useEffect(() => {
        setTimeout(() => {
            if (charCount > 512) {
                formik.setFieldError('text', 'Question can be at most 512 characters');
            } else if (charCount < 4) {
                formik.setFieldError('text', 'Question must be at least 4 characters');
            } else if (CodeTextRegex.test(formik.values.text)) {
                formik.setFieldError('text', undefined);
            }
        }, 0);
    }, [formik.errors.text, formik.values.text]);

    useEffect(() => {
        if (question) {
            formik.setValues({
                text: (sanitizeHTML(question.text) as unknown as string) || '',
                questionType: question.questionType || 1,
                allowedChoiceCount: question.allowedChoiceCount || 1,
                difficultyIndex: question.difficultyIndex || 1,
                points: question.points || 1,
                isPublished: question.isPublished || false,
                // @ts-expect-error Choices type may not match expected type
                choices: question.choices || [],
                isDeleted: question.isDeleted || false,
            });
            setChoices({
                ...initialChoiceValues,
                [Number(question?.questionType)]: question?.choices,
            });
        } else {
            formik.resetForm();
            setChoices(initialChoiceValues);
        }
    }, [question]);

    if (!loading && questionEditMode === null && !question) {
        return (
            <Paper
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    padding: spacing(6),
                }}>
                <Box>
                    <Paragraph
                        sx={{
                            margin: spacing(6),
                            color: color.midNeutral500,
                        }}>
                        Select a question from the left menu to view its details.
                    </Paragraph>
                </Box>
            </Paper>
        );
    }
    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(8),
                padding: spacing(6),
                marginBottom: spacing(6),
            }}>
            {loading && <QuestionViewSkeleton />}
            {!loading && (
                <>
                    <Paragraph size='medium' weight='medium'>
                        {`Question ${questionNumber}`}
                    </Paragraph>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '782px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(8),
                        }}>
                        <RichTextEditor
                            showToolBar
                            label='Question'
                            plugins={[
                                'heading',
                                'alignment',
                                'fontSize',
                                'bold',
                                'code',
                                'italic',
                                'list',
                                'strikethrough',
                                'underline',
                            ]}
                            fullWidth
                            required
                            value={formik.values.text}
                            onChange={(value, charCount) => {
                                setCharCount(charCount);
                                formik.setFieldValue('text', value, true);
                            }}
                            isInvalid={formik.touched.text && !!formik.errors.text}
                            helperText={
                                formik.touched.text && formik.errors.text
                                    ? formik.errors.text
                                    : ''
                            }
                            disabled={questionUpdating}
                            charLimit={512}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: spacing(11.5),
                            }}>
                            <BaseSelect
                                sx={{
                                    width: '100%',
                                    maxWidth: '287px',
                                    ul: {
                                        minHeight: '200px',
                                    },
                                }}
                                label='Question Type'
                                name='questionType'
                                options={questionCategories}
                                defaultSelected={formik.values.questionType}
                                onChange={(value) =>
                                    formik.setFieldValue('questionType', value)
                                }
                                disabled={questionUpdating}
                            />
                            <BaseSelect
                                sx={{
                                    width: '100%',
                                    maxWidth: '287px',
                                }}
                                label='Difficulty'
                                name='difficultyIndex'
                                options={questionDifficultyLevels}
                                defaultSelected={formik.values.difficultyIndex}
                                onChange={(value) =>
                                    formik.setFieldValue('difficultyIndex', value)
                                }
                                disabled={questionUpdating}
                            />

                            <Input
                                sx={{
                                    width: '100%',
                                    maxWidth: '116px',
                                }}
                                label='Set Points'
                                name='points'
                                type='number'
                                value={formik.values.points}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={
                                    formik.touched.points && Boolean(formik.errors.points)
                                }
                                helperText={
                                    (formik.touched.points &&
                                        (formik.errors.points as string)) ||
                                    ''
                                }
                                disabled={questionUpdating}
                                required
                            />
                        </Box>
                        <QuestionChoicesList
                            questionType={Number(formik.values.questionType)}
                            choices={choices[formik.values.questionType]}
                            onUpdate={(type: number, updatedChoices) =>
                                setChoices({ ...choices, [type]: updatedChoices })
                            }
                        />
                        {choicesError && (
                            <Paragraph
                                sx={{
                                    color: color.danger500,
                                }}>
                                {choicesError}
                            </Paragraph>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: spacing(3),
                            }}>
                            <Switch
                                id='isPublished'
                                checked={formik.values.isPublished}
                                onChange={formik.handleChange}
                            />
                            <Paragraph weight='medium'>Publish Question</Paragraph>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: spacing(3),
                            }}>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={formik.handleSubmit}
                                disabled={questionUpdating}
                                loading={questionUpdating}>
                                Save
                            </Button>
                            <Button variant='ghost'>Cancel</Button>
                        </Box>
                    </Box>
                </>
            )}
        </Paper>
    );
};

export default QuestionDetails;
