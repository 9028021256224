import { useState, useEffect } from 'react';
import { Box, Paragraph, spacing, color, Input, Checkbox, Button } from '@pelpr/pelpr-ui';
import { ChoiceDataType, QuestionCategory, ChoicesData } from 'src/modal';
import { useAppSelector } from 'src/hooks';

//icons
import { ReactComponent as CrossIcon } from 'src/assets/vectors/commons/cross-circle-icon.svg';

const initialChoicesData: ChoicesData = {
    1: [],
    2: [],
    3: [
        {
            id: 1,
            text: 'True',
            isCorrect: false,
            choiceValue: null,
        },
        {
            id: 2,
            text: 'False',
            isCorrect: false,
            choiceValue: null,
        },
    ],
    4: [
        {
            id: 1,
            text: 'Yes',
            isCorrect: false,
            choiceValue: null,
        },
        {
            id: 2,
            text: 'No',
            isCorrect: false,
            choiceValue: null,
        },
    ],
    5: [],
};

interface QuestionChoicesListProps {
    questionType: number;
    choices: ChoiceDataType[];
    onUpdate: (questionType: number, choices: ChoiceDataType[]) => void;
}

const QuestionChoicesList = ({
    questionType,
    choices,
    onUpdate,
}: QuestionChoicesListProps) => {
    const { questionUpdating } = useAppSelector((state) => state.question);
    const [choiceList, setChoiceList] = useState<ChoicesData>(initialChoicesData);

    useEffect(() => {
        setChoiceList({
            ...choiceList,
            [questionType]: choices,
        });
    }, [questionType, choices]);

    const onTextValueChange = (
        questionType: number,
        choiceIndex: number,
        value: string,
    ) => {
        const previousChoiceList = structuredClone(choiceList);
        const newChoiceList = previousChoiceList[questionType].map(
            (choice: ChoiceDataType, index: number) => {
                if (index === choiceIndex) {
                    choice.text = value;
                }
                return choice;
            },
        );
        setChoiceList({
            ...previousChoiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    const onRatingValueChange = (
        questionType: number,
        choiceIndex: number,
        value: string,
    ) => {
        const previousChoiceList = structuredClone(choiceList);
        const newChoiceList = previousChoiceList[questionType].map(
            (choice: ChoiceDataType, index: number) => {
                if (index === choiceIndex) {
                    choice.choiceValue = Number(value);
                }
                return choice;
            },
        );
        setChoiceList({
            ...previousChoiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    const onCheckBoxChange = (questionType: number, choiceIndex: number) => {
        const previousChoiceList = structuredClone(choiceList);
        const newChoiceList = previousChoiceList[questionType].map(
            (choice: ChoiceDataType, index: number) => {
                if (index === choiceIndex) {
                    choice.isCorrect = !choice.isCorrect;
                }
                return choice;
            },
        );
        setChoiceList({
            ...previousChoiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    const onTrueFalseYesCheck = (
        questionType: number,
        choiceIndex: number,
        check: boolean,
    ) => {
        const previousChoiceList = structuredClone(choiceList);
        const newChoiceList = previousChoiceList[questionType].map(
            (choice: ChoiceDataType, index: number) => {
                if (index === choiceIndex) {
                    return {
                        ...choice,
                        isCorrect: check,
                    };
                } else {
                    return {
                        ...choice,
                        isCorrect: !check,
                    };
                }
            },
        );
        setChoiceList({
            ...previousChoiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    const onDeleteChoice = (questionType: number, choiceIndex: number) => {
        const newChoiceList = choiceList[questionType].filter((_, index: number) => {
            return index !== choiceIndex;
        });
        setChoiceList({
            ...choiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    const onAddChoice = () => {
        const currentChoiceList = choiceList[questionType] || [];
        const newChoiceList = [
            ...currentChoiceList,
            {
                text: '',
                isCorrect: false,
                choiceValue: questionType === QuestionCategory.Rating ? 1 : null,
            },
        ];

        setChoiceList({
            ...choiceList,
            [questionType]: newChoiceList,
        });
        onUpdate(questionType, newChoiceList);
    };

    if (
        questionType === QuestionCategory.MultipleChoice ||
        questionType === QuestionCategory.SingleChoice
    ) {
        return (
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '695px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <Paragraph weight='medium'>Answer Options</Paragraph>
                    <Paragraph
                        weight='medium'
                        sx={{
                            color: color.neonBlue500,
                        }}>
                        Mark correct answers
                    </Paragraph>
                </Box>
                {choiceList[questionType]?.map(
                    (choice: ChoiceDataType, index: number) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: spacing(12),
                                }}>
                                <Input
                                    sx={{
                                        marginBottom: '0px !important',
                                    }}
                                    fullWidth
                                    name='text'
                                    type='text'
                                    value={choice?.text}
                                    onChange={(event) =>
                                        onTextValueChange(
                                            questionType,
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    disabled={questionUpdating}
                                    required
                                />
                                <Box
                                    onClick={() => onCheckBoxChange(questionType, index)}>
                                    <Checkbox
                                        sx={{ marginBottom: 0 }}
                                        label=''
                                        checked={choice?.isCorrect}
                                        onChange={() =>
                                            onCheckBoxChange(questionType, index)
                                        }
                                    />
                                </Box>
                                <Button
                                    sx={{
                                        svg: {
                                            color: `${color.midNeutral500} !important`,
                                            width: `${spacing(6)} !important`,
                                            height: `${spacing(6)} !important`,
                                        },
                                    }}
                                    variant='ghost'
                                    CustomIcon={CrossIcon}
                                    onClick={() => onDeleteChoice(questionType, index)}
                                    disabled={questionUpdating}
                                />
                            </Box>
                        );
                    },
                )}
                <Button
                    variant='ghost'
                    icon='add'
                    disabled={questionUpdating}
                    onClick={onAddChoice}>
                    Add Option
                </Button>
            </Box>
        );
    }

    if (
        questionType === QuestionCategory.TrueFalse ||
        questionType === QuestionCategory.YesNo
    ) {
        return (
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '695px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <Paragraph weight='medium'>Answer Options</Paragraph>
                    <Paragraph
                        weight='medium'
                        sx={{
                            color: color.neonBlue500,
                        }}>
                        Mark correct answers
                    </Paragraph>
                </Box>
                {choiceList[questionType]?.map(
                    (choice: ChoiceDataType, index: number) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: spacing(12),
                                }}>
                                <Input
                                    sx={{
                                        marginBottom: '0px !important',
                                    }}
                                    fullWidth
                                    name='text'
                                    type='text'
                                    value={choice?.text}
                                    onChange={(event) =>
                                        onTextValueChange(
                                            questionType,
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    disabled={questionUpdating}
                                    required
                                />
                                <Box
                                    onClick={() =>
                                        onTrueFalseYesCheck(
                                            questionType,
                                            index,
                                            !choice?.isCorrect,
                                        )
                                    }>
                                    <Checkbox
                                        sx={{ marginBottom: 0 }}
                                        label=''
                                        checked={choice?.isCorrect}
                                        onChange={() => {}}
                                    />
                                </Box>
                            </Box>
                        );
                    },
                )}
            </Box>
        );
    }

    if (questionType === QuestionCategory.Rating) {
        return (
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '695px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <Paragraph weight='medium'>Answer Options</Paragraph>
                    <Paragraph
                        weight='medium'
                        sx={{
                            color: color.neonBlue500,
                        }}>
                        Mark correct answers
                    </Paragraph>
                </Box>
                {choiceList[questionType]?.map(
                    (choice: ChoiceDataType, index: number) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: spacing(12),
                                }}>
                                <Input
                                    sx={{
                                        marginBottom: '0px !important',
                                    }}
                                    fullWidth
                                    name='text'
                                    type='text'
                                    value={choice?.text}
                                    onChange={(event) =>
                                        onTextValueChange(
                                            questionType,
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    disabled={questionUpdating}
                                    required
                                />
                                <Input
                                    sx={{
                                        marginBottom: '0px !important',
                                        input: {
                                            width: `${spacing(11)} !important`,
                                            textAlign: 'center',
                                        },
                                    }}
                                    name='choiceValue'
                                    type='number'
                                    value={choice?.choiceValue || 1}
                                    onChange={(event) =>
                                        onRatingValueChange(
                                            questionType,
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    disabled={questionUpdating}
                                    required
                                />
                                <Button
                                    sx={{
                                        svg: {
                                            color: `${color.midNeutral500} !important`,
                                            width: `${spacing(6)} !important`,
                                            height: `${spacing(6)} !important`,
                                        },
                                    }}
                                    variant='ghost'
                                    CustomIcon={CrossIcon}
                                    onClick={() => onDeleteChoice(questionType, index)}
                                    disabled={questionUpdating}
                                />
                            </Box>
                        );
                    },
                )}
                <Button
                    variant='ghost'
                    icon='add'
                    disabled={questionUpdating}
                    onClick={onAddChoice}>
                    Add Option
                </Button>
            </Box>
        );
    }

    return null;
};

export default QuestionChoicesList;
