import { useState, useEffect } from 'react';
import {
    Badge,
    Box,
    Headline,
    PageContainer,
    PageHeader,
    Paragraph,
    Tabs,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getAssessment } from 'src/redux/assessments/assessmentsApi';
import { useParams } from 'react-router-dom';
import { formatDate } from 'src/utilities/date';
import AssessmentBasicInfo from 'src/components/assessments/AssessmentBasicInfo';
import { AssessmentDataType } from 'src/modal';
import AssessmentDetails from 'src/components/assessments/AssessmentDetails';
import AssessmentSettings from 'src/components/assessments/AssessmentSettings';
import QuestionsScreen from 'src/components/questions/QuestionsScreen';
import AssessmentPageSkeleton from 'src/components/assessments/AssessmentPageSkeleton';

//icons
import { ReactComponent as InfoIcon } from 'src/assets/vectors/commons/info-square-icon.svg';
import { ReactComponent as TextIcon } from 'src/assets/vectors/commons/text-icon.svg';
import { ReactComponent as ListIcon } from 'src/assets/vectors/commons/bill-list-icon.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/vectors/commons/fine-tune-icon.svg';
import { resetEditQuestion } from 'src/redux/question/questionSlice';

const tabs = [
    {
        title: 'Basic Information',
        icon: <InfoIcon />,
    },
    {
        title: 'Assessment Details',
        icon: <TextIcon />,
    },
    {
        title: 'Settings',
        icon: <SettingsIcon />,
    },
    {
        title: 'Questions',
        icon: <ListIcon />,
    },
];

const enum TabType {
    BasicInfo = 0,
    Details = 1,
    Settings = 2,
    Questions = 3,
}

const EditAssessment = () => {
    const { assessmentId } = useParams();
    const { assessment } = useAppSelector((state) => state.assessments);
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [assessmentData, setAssessmentData] = useState<AssessmentDataType | null>(null);

    useEffect(() => {
        dispatch(resetEditQuestion());
    }, []);

    useEffect(() => {
        if (assessmentId) {
            dispatch(
                getAssessment({
                    assessmentId,
                    onSuccess: () => {
                        setLoading(false);
                    },
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (assessment) {
            setAssessmentData(assessment);
        }
    }, [assessment]);

    const dispatch = useAppDispatch();

    const handleOnSubmit = (
        newAssessmentData: AssessmentDataType | AssessmentDataType,
    ) => {
        setAssessmentData(newAssessmentData as AssessmentDataType);
    };

    return (
        <PageContainer
            pageHeader={<PageHeader title='Assessments' />}
            sx={{
                width: '100%',
                minHeight: '100vh',
                padding: 0,
            }}>
            {loading && !assessmentData ? (
                <AssessmentPageSkeleton />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '100vh',
                        padding: 0,
                    }}>
                    {assessmentData && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                paddingLeft: spacing(6),
                                paddingRight: spacing(6),
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: spacing(4),
                                    }}>
                                    <Headline
                                        variant='h6'
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}>
                                        {assessment?.name}
                                    </Headline>
                                    <Badge
                                        text={
                                            assessment?.isPublished
                                                ? 'Published'
                                                : 'Unpublished'
                                        }
                                        variant={
                                            assessment?.isPublished ? 'green' : 'neutral'
                                        }
                                    />
                                </Box>

                                {assessment?.updatedAt && (
                                    <Paragraph
                                        size='regular'
                                        weight='regular'
                                        sx={{
                                            marginTop: spacing(1),
                                        }}>
                                        {`Last Updated: ${formatDate(
                                            assessment?.updatedAt as string,
                                        )}`}
                                    </Paragraph>
                                )}
                            </Box>

                            <Tabs
                                tabs={tabs}
                                activeTab={activeTab}
                                onTabClick={(index) => setActiveTab(index)}
                            />

                            {activeTab === TabType.BasicInfo && (
                                <AssessmentBasicInfo
                                    contentType='tab'
                                    assessmentData={assessmentData}
                                    onSubmit={handleOnSubmit}
                                />
                            )}
                            {activeTab === TabType.Details && (
                                <AssessmentDetails
                                    contentType='tab'
                                    assessmentData={assessmentData}
                                    onSubmit={handleOnSubmit}
                                />
                            )}
                            {activeTab === TabType.Settings && (
                                <AssessmentSettings
                                    contentType='tab'
                                    assessmentData={assessmentData}
                                    onSubmit={handleOnSubmit}
                                />
                            )}
                            {activeTab === TabType.Questions && <QuestionsScreen />}
                        </Box>
                    )}
                </Box>
            )}
        </PageContainer>
    );
};

export default EditAssessment;
