import { useEffect } from 'react';
import {
    Box,
    color,
    Headline,
    Modal,
    ModalBody,
    ModalHeader,
    Paragraph,
    spacing,
} from '@pelpr/pelpr-ui';
import { AccountTypes, Countries, Currencies, JobRoles } from 'src/config/lookups';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getUserAccount } from 'src/redux/user/userApi';
import Skeleton from 'react-loading-skeleton';
import { UserAccountTypes } from 'src/modal';

const ListItem = ({ label, value }: { label: string; value: string | number }) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
            <Paragraph
                size='small'
                sx={{
                    color: color.midNeutral500,
                }}>
                {label}
            </Paragraph>
            <Paragraph
                weight='medium'
                sx={{
                    wordWrap: 'break-word',
                    textWrap: 'wrap',
                    color: color.darkNeutral500,
                }}>
                {value}
            </Paragraph>
        </Box>
    );
};

interface UserAccountViewModalProps {
    show: boolean;
    onClose: () => void;
    userId: string;
}

const UserAccountViewModal = ({ userId, show, onClose }: UserAccountViewModalProps) => {
    const { userAccountData, userLoading } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    useEffect(() => {
        userId &&
            dispatch(
                getUserAccount({
                    userId: userId,
                }),
            );
    }, [userId]);

    const jobRoles =
        JobRoles?.filter((role) => role.value === userAccountData?.jobRole)[0]?.label ||
        '';

    return (
        <Modal
            uniqueKey='assessment-modal'
            show={show}
            onClose={onClose}
            sx={{
                width: '405px',
            }}>
            <ModalHeader>
                <Headline variant='h6'>View User</Headline>
            </ModalHeader>
            <ModalBody
                sx={{
                    padding: `${spacing(5)} ${spacing(6)}`,
                }}>
                {userLoading ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(4),
                        }}>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                }}>
                                <Skeleton height={15} width={100} />
                                <Skeleton height={15} width={200} />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    userAccountData && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: spacing(4),
                            }}>
                            <ListItem label='User id' value={userAccountData?.id} />
                            <ListItem label='Email' value={userAccountData?.email} />
                            <ListItem
                                label='First name'
                                value={userAccountData?.firstName}
                            />
                            <ListItem
                                label='Last name'
                                value={userAccountData?.lastName}
                            />
                            <ListItem
                                label='Country'
                                value={
                                    (Countries?.find(
                                        (country) =>
                                            country?.value === userAccountData?.country,
                                    )?.label as string) || ''
                                }
                            />
                            <ListItem label='City' value={userAccountData?.city} />
                            <ListItem
                                label='Mobile'
                                value={userAccountData?.mobile || ''}
                            />
                            {userAccountData?.accountType ===
                                UserAccountTypes.Candidate && (
                                <ListItem label='Job role' value={jobRoles} />
                            )}
                            <ListItem
                                label='Account role'
                                value={
                                    (AccountTypes?.find(
                                        (accountType) =>
                                            accountType?.value ===
                                            userAccountData?.accountType,
                                    )?.label as string) || ''
                                }
                            />

                            {userAccountData?.accountType ===
                                UserAccountTypes.Recruiter && (
                                <ListItem
                                    label='Organization id'
                                    value={userAccountData?.organizationId || ''}
                                />
                            )}
                            {userAccountData?.accountType ===
                                UserAccountTypes.Recruiter && (
                                <ListItem
                                    label='Organization'
                                    value={userAccountData?.organizationName || ''}
                                />
                            )}
                            <ListItem
                                label='Transaction currency'
                                value={
                                    (Currencies?.find(
                                        (currency) =>
                                            currency?.value ===
                                            userAccountData?.transactionCurrency,
                                    )?.label as string) || ''
                                }
                            />
                            <ListItem
                                label='Email verified'
                                value={
                                    userAccountData?.setupFlags?.emailConfirmed
                                        ? 'Yes'
                                        : 'No'
                                }
                            />
                            <ListItem
                                label='Mobile verified'
                                value={
                                    userAccountData?.setupFlags?.mobileConfirmed
                                        ? 'Yes'
                                        : 'No'
                                }
                            />
                        </Box>
                    )
                )}
            </ModalBody>
        </Modal>
    );
};

export default UserAccountViewModal;
