import { Box, ConfirmationDialog, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { removeQuizAttempt } from 'src/redux/assessments/assessmentsApi';
import { getCandidateAssessments } from 'src/redux/candidate/candidateApi';

interface CandidateAssessmentAttemptDeleteModalProps {
    open: boolean;
    onClose: () => void;
    attemptId: string | null;
    quizId: string | null;
}

const CandidateAssessmentAttemptDeleteModal = ({
    open,
    onClose,
    quizId,
    attemptId,
}: CandidateAssessmentAttemptDeleteModalProps) => {
    const { removingAttempt } = useAppSelector((state) => state.assessments);
    const { candidateId } = useParams();
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleRemove = () => {
        if (attemptId && quizId)
            dispatch(
                removeQuizAttempt({
                    attemptId,
                    quizId,
                    onSuccess: () => {
                        onClose();
                        candidateId &&
                            dispatch(
                                getCandidateAssessments({
                                    candidateId,
                                }),
                            );
                    },
                }),
            );
    };

    return (
        <ConfirmationDialog
            uniqueKey='remove-candidate-quiz-attempt'
            actionType='delete'
            onConfirm={handleRemove}
            onDeny={handleCancel}
            loading={removingAttempt}
            show={open}
            title='Delete quiz attempt'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Delete'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Paragraph
                    size='medium'
                    weight='semi-bold'
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    Are you sure you want to delete the quiz attempt?
                </Paragraph>
            </Box>
        </ConfirmationDialog>
    );
};

export default CandidateAssessmentAttemptDeleteModal;
