import { useState } from 'react';
import { Box, color, Paragraph, spacing } from '@pelpr/pelpr-ui';
import { UserListDataType } from 'src/modal';
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';

interface UserTableCardProps {
    user: UserListDataType;
}

const UserTableCard = ({ user }: UserTableCardProps) => {
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                maxWidth: '350px',
                gap: spacing(4),
            }}>
            <Box
                sx={{
                    width: spacing(12),
                    minWidth: spacing(12),
                    height: spacing(12),
                    borderRadius: '50%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {!imageLoadError && user?.photoUrl ? (
                    <img
                        alt='User picture'
                        src={user?.photoUrl}
                        width={48}
                        height={48}
                        style={{
                            borderRadius: '5px',
                        }}
                        onError={handleImageError}
                    />
                ) : (
                    <img
                        alt='User picture avatar'
                        src={Avatar}
                        width={48}
                        height={48}
                        style={{
                            borderRadius: '50%',
                        }}
                    />
                )}
            </Box>
            <Box
                sx={{
                    maxWidth: '270px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                }}>
                <Paragraph
                    weight='semi-bold'
                    sx={{
                        maxWidth: '270px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {user?.name}
                </Paragraph>
                <Paragraph
                    weight='regular'
                    sx={{
                        color: color.midNeutral500,
                        width: '100%',
                        maxWidth: '270px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {user?.email}
                </Paragraph>
            </Box>
        </Box>
    );
};

export default UserTableCard;
