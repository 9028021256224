import { format } from 'date-fns';

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy');
};

export const convertLocalToUTCDate = (inputDate: Date): string => {
    return inputDate?.toISOString();
};

export function friendlyTime(d: string): string {
    const date: Date = new Date(d);
    const dateNow: Date = new Date();
    const delta: number = Math.round((dateNow.getTime() - date.getTime()) / 1000);

    const minute = 60,
        hour = minute * 60,
        day = hour * 24;
    // week = day * 7;

    let fuzzy;

    if (delta < 10) {
        fuzzy = 'Just Now';
    } else if (delta < minute) {
        fuzzy = delta + 's ago';
    } else if (delta < 2 * minute) {
        fuzzy = '1m ago';
    } else if (delta < hour) {
        fuzzy = Math.floor(delta / minute) + 'm ago';
    } else if (Math.floor(delta / hour) === 1) {
        fuzzy = '1h ago';
    } else if (delta < day) {
        fuzzy = Math.floor(delta / hour) + 'h ago';
    } else if (delta < day * 2) {
        fuzzy = 'yesterday';
    } else {
        fuzzy = Math.floor(delta / day) + 'd ago';
    }

    return fuzzy;
}

export const formatToReadableDate = (
    targetDateStr: string,
    dateFormat: 'MMM dd, yyyy hh:mm a' | 'MMM dd, yyyy' = 'MMM dd, yyyy hh:mm a',
): string => {
    // Parse the target date string
    const targetDate = new Date(targetDateStr);

    // Format the date to the desired format
    const formattedDate = format(targetDate, dateFormat);

    return formattedDate;
};
