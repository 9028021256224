import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from 'src/pages/dashboard';
import Assessments from 'src/pages/assessments/Assessments';
import AddNewAssessment from 'src/pages/assessments/AddNewAssessment';
import EditAssessment from 'src/pages/assessments/EditAssessment';
import MainLayout from 'src/Layouts/MainLayout';
import { CandidatesSearch, CandidatesApprovals } from 'src/pages/candidates';
import CandidateDetailsPage from 'src/pages/candidates/CandidateDetailsPage';
import JobsSearch from 'src/pages/jobs/JobsSearch';
import JobDetailsPage from 'src/pages/jobs/JobDetailsPage';
import JobsListing from 'src/pages/jobs/JobsListing';
import UsersList from 'src/pages/users/UsersList';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route path='/' element={<Dashboard />} />
                <Route path='assessments' element={<Assessments />} />
                <Route path='assessments/create' element={<AddNewAssessment />} />
                <Route path='assessments/:assessmentId' element={<EditAssessment />} />
                <Route path='candidates/search' element={<CandidatesSearch />} />
                <Route path='candidates/approvals' element={<CandidatesApprovals />} />
                <Route
                    path='candidates/:candidateId'
                    element={<CandidateDetailsPage />}
                />
                <Route path='jobs/search' element={<JobsSearch />} />
                <Route path='/jobs/listing' element={<JobsListing />} />
                <Route path='jobs/:jobId' element={<JobDetailsPage />} />
                <Route path='manage-users' element={<UsersList />} />
                <Route path='*' element={<Navigate to='/' replace />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
