import { Box, Paper, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const JobCardSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
                padding: spacing(6),
            }}>
            <Skeleton
                width={56}
                height={56}
                style={{
                    borderRadius: '5px',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(2),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Skeleton width={150} height={16} />

                    <Box
                        sx={{
                            width: spacing(1),
                            height: spacing(1),
                            backgroundColor: color.midNeutral300,
                            borderRadius: '50%',
                        }}
                    />
                    <Skeleton width={80} height={14} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Skeleton width={100} height={14} />
                    <Box
                        sx={{
                            width: spacing(1),
                            height: spacing(1),
                            backgroundColor: color.midNeutral300,
                            borderRadius: '50%',
                        }}
                    />
                    <Skeleton width={50} height={16} />
                    <Skeleton width={50} height={16} />
                    <Skeleton width={50} height={16} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(4),
                    }}>
                    <Skeleton width={90} height={14} />
                    <Skeleton width={90} height={14} />
                    <Skeleton width={90} height={14} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                        marginTop: spacing(1),
                    }}>
                    <Skeleton width={60} height={14} />
                    <Skeleton width={60} height={14} />
                    <Skeleton width={60} height={14} />
                </Box>
            </Box>
        </Paper>
    );
};
export default JobCardSkeleton;
