import { Box, Paper, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const JobCollaboratorCardSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '220px',
                minWidth: '220px',
                height: '243px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: spacing(4),
                padding: `${spacing(6)} ${spacing(4)}`,
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}>
                <Skeleton width={18} height={20} />

                <Skeleton width={88} height={88} circle />

                <Skeleton width={12} height={24} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(1.25),
                }}>
                <Skeleton width={188} height={16} />
                <Skeleton width={50} height={24} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(2),
                }}>
                <Skeleton width={20} height={20} />
                <Skeleton width={144} height={14} />
            </Box>
        </Paper>
    );
};

export default JobCollaboratorCardSkeleton;
