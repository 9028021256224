import { Box, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const CandidateNoteCardSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
                paddingTop: spacing(6),
                paddingBottom: spacing(6),
                borderBottom: `2.317px solid ${color.lightNeutral500}`,
            }}>
            <Skeleton width={48} height={48} />

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(2),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(6),
                    }}>
                    <Skeleton width={100} height={14} />
                    <Skeleton width={40} height={12} />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(1),
                        span: {
                            display: 'block',
                            height: '14px',
                            width: '99%',
                        },
                    }}>
                    <Skeleton width='99%' height={14} />
                    <Skeleton width='99%' height={14} />
                    <Skeleton width={400} height={14} />
                </Box>
            </Box>
        </Box>
    );
};

export default CandidateNoteCardSkeleton;
