import {
    fetchAllAssessments,
    fetchAssessment,
    addAssessment,
    updateAssessmentRecord,
    deleteAssessment,
    addAssessmentPhoto,
    publicAssessmentRecord,
    unpublishedAssessmentRecord,
    deleteQuizAttempt,
} from 'src/services/assessments';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddAssessmentDataType, AssessmentDataType } from 'src/modal';
import { toast } from 'react-toastify';

export const getAllAssessments = createAsyncThunk(
    'assessments/getAllAssessments',
    async ({ includeDeleted }: { includeDeleted: boolean}) => {
        try {
            const response = await fetchAllAssessments(includeDeleted);
            return response?.data?.quizzes;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch assessments.');
            throw error.response.data;
        }
    },
);

export const getAssessment = createAsyncThunk(
    'assessments/getAssessment',
    async (payload: { assessmentId: string; onSuccess: () => void }) => {
        try {
            const response = await fetchAssessment(payload.assessmentId);
            payload.onSuccess();
            return response?.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch assessment.');
            throw error.response.data;
        }
    },
);

export const addNewAssessment = createAsyncThunk(
    'assessment/addNewAssessment',
    async (payload: { assessmentData: AddAssessmentDataType; onSuccess: () => void }) => {
        try {
            await addAssessment(payload.assessmentData);
            toast.success('Assessment added.');
            payload.onSuccess();
            return payload.assessmentData;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Assessment save failed.');
            throw error.response.data;
        }
    },
);

export const updateAssessment = createAsyncThunk(
    'assessment/updateAssessment',
    async (payload: { assessmentData: AssessmentDataType }) => {
        try {
            const { id, ...restAssessmentData } = payload.assessmentData;
            const response = await updateAssessmentRecord(
                id as string,
                restAssessmentData,
            );
            toast.success('Assessment updated.');
            return {
                ...payload.assessmentData,
                version: response?.data?.version,
            };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Assessment update failed.');
            throw error.response.data;
        }
    },
);

export const publishAssessment = createAsyncThunk(
    'assessment/publishAssessment',
    async ({
        quizId,
        version,
        onSuccess,
    }: {
        quizId: string;
        version: string;
        onSuccess: () => void;
    }) => {
        try {
            const response = await publicAssessmentRecord(quizId, version);
            toast.success('Assessment published');
            onSuccess();
            return {
                version: response?.data?.version,
            };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            console.error('Publish assessment error:', error);
            toast.error(
                `Assessment publishing failed. \n${error?.response?.data?.detail || ''}`,
            );
            throw error.response.data;
        }
    },
);

export const unpublishAssessment = createAsyncThunk(
    'assessment/unpublishAssessment',
    async ({
        quizId,
        version,
        onSuccess,
    }: {
        quizId: string;
        version: string;
        onSuccess: () => void;
    }) => {
        try {
            const response = await unpublishedAssessmentRecord(quizId, version);
            toast.success('Assessment unpublished');
            onSuccess();
            return {
                version: response?.data?.version,
            };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error(
                `Assessment un-publishing failed. \n${
                    error?.response?.data?.detail || ''
                }`,
            );
            throw error.response.data;
        }
    },
);

export const removeAssessment = createAsyncThunk(
    'assessment/removeAssessment',
    async ({
        quizId,
        version,
        onSuccess,
    }: {
        quizId: string;
        version: string;
        onSuccess: () => void;
    }) => {
        try {
            await deleteAssessment(quizId, version);
            toast.success('Assessment deleted.');
            onSuccess();
            return quizId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Assessment delete failed.');
            throw error.response.data;
        }
    },
);

export const uploadAssessmentPhoto = createAsyncThunk(
    'assessment/uploadAssessmentPhoto',
    async ({
        quizId,
        imageData,
        onSuccess,
    }: {
        quizId: string;
        imageData: FormData;
        onSuccess?: () => void;
    }) => {
        try {
            const response = await addAssessmentPhoto(quizId, imageData);
            toast.success('Assessment Image uploaded.');
            onSuccess && onSuccess();
            return response?.data;
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Assessment Image upload failed.');
            throw error.response.data;
        }
    },
);

export const removeQuizAttempt = createAsyncThunk(
    'question/removeQuizAttempt',
    async (payload: { quizId: string; attemptId: string; onSuccess: () => void }) => {
        try {
            await deleteQuizAttempt(payload.quizId, `${payload.attemptId}`);
            toast.success('Quiz attempt deleted.');
            payload.onSuccess();
            return { quizId: payload?.quizId, attemptId: payload.attemptId };

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Quiz attempt delete failed.');
            throw error.response.data;
        }
    },
);

export const getUpdatedAssessment = createAsyncThunk(
    'assessments/updateAssessmentStatus',
    async (assessmentId: string) => {
        try {
            const response = await fetchAssessment(assessmentId);
            return response?.data;

        } catch (error: any) {
            toast.error('Failed to fetch assessments.');
            throw error.response.data;
        }
    },
);
