import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Headline, Paragraph, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getCandidateAssessments } from 'src/redux/candidate/candidateApi';
import CandidateAssessmentSkeleton from './CandidateAssessmentSkeleton';
import CandidateAssessmentCard from './CandidateAssessmentCard';

const CandidateAssessmentsTab = () => {
    const { candidateId } = useParams();
    const { assessmentResults, assessmentsLoading } = useAppSelector(
        (state) => state.candidate,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        candidateId &&
            dispatch(
                getCandidateAssessments({
                    candidateId,
                }),
            );
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                    paddingTop: spacing(6),
                    paddingBottom: spacing(6),
                }}>
                <Headline variant='h6'>Assessments List</Headline>
                <Paragraph>
                    Includes assessments which were completed or attempted by the
                    candidate.
                </Paragraph>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    gap: spacing(4),
                }}>
                {assessmentsLoading &&
                    [1, 2, 3, 4, 5].map((item) => (
                        <CandidateAssessmentSkeleton key={item} />
                    ))}
                {!assessmentsLoading &&
                    assessmentResults?.length > 0 &&
                    assessmentResults?.map((assessment) => (
                        <CandidateAssessmentCard
                            key={assessment?.assessmentDetail?.id}
                            assessmentData={assessment}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default CandidateAssessmentsTab;
