import { useState } from 'react';
import { Box, color, Paper, spacing, Tabs } from '@pelpr/pelpr-ui';
import CandidateProfileTab from './CandidateProfileTab';
import CandidateNotesTab from './CandidateNotesTab';
import CandidateAssessmentsTab from './CandidateAssessmentsTab';

const tabOptions = [
    {
        title: 'Profile',
    },
    {
        title: 'Assessments',
    },
    {
        title: 'References',
    },
    {
        title: 'Notes',
    },
    { title: 'Timeline' },
    {
        title: 'Files',
    },
];

const CandidateDetailsPanel = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
            <Paper
                sx={{
                    width: '100%',
                    borderRadius: 0,
                    borderTopLeftRadius: spacing(1.25),
                    borderTopRightRadius: spacing(1.25),
                }}>
                <Tabs
                    tabs={tabOptions}
                    tabType='tab'
                    activeTab={activeTab}
                    onTabClick={(value) => setActiveTab(value)}
                    sx={{
                        padding: spacing(6),
                        borderBottom: `2px solid ${color.lightNeutral500}`,
                    }}
                />
            </Paper>

            {activeTab === 0 && <CandidateProfileTab />}
            {activeTab === 1 && <CandidateAssessmentsTab />}
            {activeTab === 3 && <CandidateNotesTab />}
        </Box>
    );
};

export default CandidateDetailsPanel;
