import { useState, useEffect } from 'react';
import { Box, PageContainer, PageHeader, spacing } from '@pelpr/pelpr-ui';
import CandidatesTopFilters from 'src/components/candidates/CandidatesTopFilters';
import CandidatesList from 'src/components/candidates/CandidatesList';
import CandidatesLeftFilters from 'src/components/candidates/CandidatesLeftFilters';
import { useAppDispatch } from 'src/hooks';
import { getCandidatesList } from 'src/redux/candidate/candidateApi';
import { formatSearchCandidateQueryParams } from 'src/utilities/helpers';
import {
    CandidatesLeftSearchFiltersType,
    CandidatesTopSearchFiltersType,
} from 'src/modal';

const initialLeftFilters = {
    profileStatus: [],
    experienceLevel: [],
    candidateJobStatus: [],
    jobCategory: [],
    salaryRange: [0, 500000],
};

const CandidatesSearch = () => {
    const dispatch = useAppDispatch();
    const [topFilters, setTopFilters] = useState<CandidatesTopSearchFiltersType>({
        search: '',
        country: 2,
        city: '',
        sortBy: '',
        page: 1,
        pageSize: 10,
    });

    const [leftFilters, setLeftFilters] =
        useState<CandidatesLeftSearchFiltersType>(initialLeftFilters);

    useEffect(() => {
        dispatch(
            getCandidatesList({
                queryParams: [{ key: 'country', value: 'Pakistan' }],
            }),
        );
    }, []);

    const handleTopFilterChanged = (value: string | number, type: string) => {
        let newFilters: CandidatesTopSearchFiltersType = {
            ...topFilters,
            [type]: value,
        };
        setTopFilters(newFilters);
        if (type === 'page' || type === 'pageSize') {
            newFilters = {
                ...newFilters,
                page: newFilters?.page || 1,
                pageSize: newFilters?.pageSize || 10,
            };
            const queryParams = formatSearchCandidateQueryParams(newFilters, leftFilters);
            dispatch(
                getCandidatesList({
                    queryParams,
                }),
            );
        }
    };

    const handleLeftFilterChanged = (
        value: string[] | number[] | boolean,
        type: string,
    ) => {
        setLeftFilters({
            ...leftFilters,
            [type]: value,
        });
    };

    const handleFilterReset = () => {
        const newTopFilters = {
            search: '',
            country: 2,
            city: '',
            sortBy: '',
            page: null,
            pageSize: null,
        };

        setTopFilters(newTopFilters);
        setLeftFilters(initialLeftFilters);
        const queryParams = formatSearchCandidateQueryParams(
            newTopFilters,
            initialLeftFilters,
        );

        dispatch(
            getCandidatesList({
                queryParams,
            }),
        );
    };

    const onSearch = () => {
        const queryParams = formatSearchCandidateQueryParams(topFilters, leftFilters);
        dispatch(
            getCandidatesList({
                queryParams,
            }),
        );
    };

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Candidates / Search' />}>
            <CandidatesTopFilters
                filters={topFilters}
                handleFilterChange={handleTopFilterChanged}
                onSearch={onSearch}
            />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: spacing(6),
                    paddingBottom: spacing(6),
                    gap: spacing(8),
                }}>
                <CandidatesLeftFilters
                    filters={leftFilters}
                    handleFilterChange={handleLeftFilterChanged}
                    onSearch={onSearch}
                    onResetFilters={handleFilterReset}
                />
                <CandidatesList
                    filters={topFilters}
                    handleFilterChange={handleTopFilterChanged}
                />
            </Box>
        </PageContainer>
    );
};

export default CandidatesSearch;
