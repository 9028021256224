import { useState } from 'react';
import {
    Box,
    Button,
    Headline,
    Paper,
    Paragraph,
    ActionMenu,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { CandidateListDataType } from 'src/modal/candidate';
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';
import { ReactComponent as MedalIcon } from 'src/assets/vectors/commons/medal-star-fill-icon.svg';
import { roundNumber } from 'src/utilities/formattingHelpers';

interface CandidateListCardProps {
    candidateData: CandidateListDataType;
}

const CandidateListCard = ({ candidateData }: CandidateListCardProps) => {
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: spacing(4),
                borderRadius: spacing(1.25),
                padding: spacing(6),
                position: 'relative',
            }}>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: spacing(16),
                        minWidth: spacing(16),
                        height: spacing(16),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: spacing(1.25),
                        overflow: 'hidden',
                    }}>
                    {!imageLoadError && candidateData?.photoUrl ? (
                        <img
                            alt='User picture'
                            src={candidateData?.photoUrl}
                            width={56}
                            height={56}
                            style={{
                                borderRadius: '5px',
                            }}
                            onError={handleImageError}
                        />
                    ) : (
                        <img
                            alt='User picture avatar'
                            src={Avatar}
                            width={56}
                            height={56}
                            style={{
                                borderRadius: '5px',
                            }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: spacing(2),
                        }}>
                        <Headline variant='h6'>
                            {`${candidateData?.firstName} ${candidateData?.lastName}`}
                        </Headline>
                        {candidateData?.isTopTalent && (
                            <MedalIcon
                                style={{
                                    marginTop: spacing(1),
                                    color: color.neonBlue500,
                                }}
                            />
                        )}
                    </Box>
                    <Paragraph>{candidateData?.headline}</Paragraph>
                </Box>
                <Box
                    sx={{
                        width: spacing(2),
                        height: spacing(2),
                        backgroundColor: candidateData?.isLive
                            ? color.success500
                            : candidateData?.isSearchable
                              ? color.midNeutral500
                              : color.orange500,
                        borderRadius: '50%',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Experience
                    </Paragraph>
                    <Paragraph>{candidateData?.experienceLevel}</Paragraph>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Salary
                    </Paragraph>
                    <Paragraph>{`$${roundNumber(candidateData?.yearlySalary)} ($${roundNumber(candidateData?.yearlySalary / 12)}/month | $${roundNumber(candidateData?.hourlyRate, 2)}/hr)`}</Paragraph>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Location
                    </Paragraph>
                    <Paragraph>{`${candidateData?.city}, ${candidateData?.country}`}</Paragraph>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Paragraph
                        size='small'
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        Top Skills
                    </Paragraph>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: spacing(1),
                        }}>
                        {candidateData?.topSkills?.map((skill, index) => (
                            <Paragraph key={index}>
                                {skill}
                                {index === candidateData?.topSkills?.length - 1
                                    ? ''
                                    : ','}
                            </Paragraph>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: '24px',
                    right: '24px',
                }}>
                <ActionMenu
                    alignment='bottom-left'
                    isOpen={isUserMenuOpened}
                    openMenuHandler={() => setIsUserMenuOpened(!isUserMenuOpened)}
                    closeMenuHandler={() => setIsUserMenuOpened(false)}>
                    <Button
                        variant='ghost'
                        icon='view'
                        href={`/candidates/${candidateData?.id}`}>
                        View details
                    </Button>
                </ActionMenu>
            </Box>
        </Paper>
    );
};

export default CandidateListCard;
