import { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Box,
    Input,
    Paragraph,
    StepperNavigator,
    Paper,
    color,
    spacing,
    SectionHeader,
    Button,
} from '@pelpr/pelpr-ui';
import { AssessmentDataType } from 'src/modal';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { updateAssessment } from 'src/redux/assessments/assessmentsApi';

const validationSchema = yup.object().shape({
    numberOfQuestions: yup
        .number()
        .typeError('Enter a number')
        .integer('Enter a whole number')
        .required('Number of questions is required'),

    timeLimit: yup
        .number()
        .typeError('Enter a number')
        .integer('Enter a whole number')
        .required('Time limit is required'),

    passPercentage: yup
        .number()
        .typeError('Enter a number')
        .integer('Enter a whole number')
        .required('Pass percentage is required'),
    maxAttemptsAllowed: yup
        .number()
        .typeError('Enter a number')
        .integer('Enter a whole number')
        .required('MaxAttempts allowed is required')
        .min(1, 'MaxAttemptsAllowed must be equal to 1')
        .max(1, 'MaxAttemptsAllowed must be equal to 1'),
});

const initialValues = {
    numberOfQuestions: 1,
    timeLimit: 0,
    passPercentage: 0,
    maxAttemptsAllowed: 1,
};

interface AssessmentSettingsProps {
    contentType: 'tab' | 'step';
    steps?: number;
    currentStep?: number;
    onPrevious?: () => void;
    assessmentData: AssessmentDataType;
    onSubmit: (assessmentData: AssessmentDataType, step?: number) => void;
}

const AssessmentSettings = ({
    contentType,
    steps,
    currentStep,
    onPrevious,
    assessmentData,
    onSubmit,
}: AssessmentSettingsProps) => {
    const { assessmentUpdating } = useAppSelector((state) => state.assessments);
    const dispatch = useAppDispatch();

    const onFormSubmit = useCallback((values: typeof initialValues) => {
        if (contentType === 'step') {
            onSubmit(
                {
                    ...assessmentData,

                    settings: {
                        numberOfQuestions: values.numberOfQuestions,
                        timeLimit: values.timeLimit,
                        passPercentage: values.passPercentage,
                        maxAttemptsAllowed: values.maxAttemptsAllowed,
                    },
                } as AssessmentDataType,
                4,
            );
        } else if (contentType === 'tab') {
            dispatch(
                updateAssessment({
                    assessmentData: {
                        ...(assessmentData as AssessmentDataType),

                        settings: {
                            numberOfQuestions: values.numberOfQuestions,
                            timeLimit: values.timeLimit,
                            passPercentage: values.passPercentage,
                            maxAttemptsAllowed: values.maxAttemptsAllowed,
                        },
                    },
                }),
            );
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: onFormSubmit,
    });

    useEffect(() => {
        formik.setFieldValue(
            'numberOfQuestions',
            assessmentData?.settings?.numberOfQuestions,
        );
        formik.setFieldValue('timeLimit', assessmentData?.settings?.timeLimit);
        formik.setFieldValue('passPercentage', assessmentData?.settings?.passPercentage);
        formik.setFieldValue(
            'maxAttemptsAllowed',
            assessmentData?.settings?.maxAttemptsAllowed,
        );
    }, [assessmentData]);

    return (
        <Box sx={{ width: '100%', marginBottom: spacing(8) }}>
            <Paper
                sx={{
                    width: '100%',
                    padding: spacing(6),
                    marginBottom: spacing(6.5),
                }}>
                <SectionHeader
                    title='Settings'
                    subtitle=' Set assessment rules and scoring methodology'
                />
                <Paragraph
                    sx={{ color: color.darkNeutral300, margin: `${spacing(6)} 0` }}>
                    * indicates required fields
                </Paragraph>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: spacing(6),
                        maxWidth: 354,
                    }}>
                    <InputWrapper
                        label='Passing Grade (%)'
                        description='Set the passing percentage for this assessment. 0 means no passing score.'>
                        <Input
                            fullWidth
                            type='number'
                            name='passPercentage'
                            value={formik.values.passPercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched.passPercentage &&
                                Boolean(formik.errors.passPercentage)
                            }
                            helperText={
                                (formik.touched.passPercentage &&
                                    (formik.errors.passPercentage as string)) ||
                                ''
                            }
                            disabled={assessmentUpdating}
                            required
                        />
                    </InputWrapper>

                    <InputWrapper
                        label='Max Questions allowed to answer'
                        description='This is the number of questions presented to candidates for assessment, and questions will come randomly from all available questions that belongs to this quiz.'>
                        <Input
                            fullWidth
                            type='number'
                            name='numberOfQuestions'
                            value={formik.values.numberOfQuestions}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched.numberOfQuestions &&
                                Boolean(formik.errors.numberOfQuestions)
                            }
                            helperText={
                                (formik.touched.numberOfQuestions &&
                                    (formik.errors.numberOfQuestions as string)) ||
                                ''
                            }
                            disabled={assessmentUpdating}
                            required
                        />
                    </InputWrapper>
                    <InputWrapper
                        label='Time limit (minutes)'
                        description='Set time limit for the assessment. 0 means no time limit.'>
                        <Input
                            fullWidth
                            type='number'
                            name='timeLimit'
                            value={formik.values.timeLimit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched.timeLimit &&
                                Boolean(formik.errors.timeLimit)
                            }
                            helperText={
                                (formik.touched.timeLimit &&
                                    (formik.errors.timeLimit as string)) ||
                                ''
                            }
                            disabled={assessmentUpdating}
                            required
                        />
                    </InputWrapper>
                    <InputWrapper
                        label='Attempts allowed'
                        description='Set restriction on the number of attempts a candidate is allowed to take for this assessment. Set to 1.'>
                        <Input
                            fullWidth
                            type='number'
                            name='maxAttemptsAllowed'
                            value={formik.values.maxAttemptsAllowed}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched.maxAttemptsAllowed &&
                                Boolean(formik.errors.maxAttemptsAllowed)
                            }
                            helperText={
                                (formik.touched.maxAttemptsAllowed &&
                                    (formik.errors.maxAttemptsAllowed as string)) ||
                                ''
                            }
                            disabled={assessmentUpdating}
                            required
                        />
                    </InputWrapper>
                    {contentType === 'tab' && (
                        <Button
                            sx={{
                                width: 'max-content',
                                marginTop: spacing(2.5),
                                marginBottom: spacing(2.5),
                            }}
                            onClick={formik.handleSubmit}
                            loading={assessmentUpdating}
                            disabled={assessmentUpdating}>
                            Save
                        </Button>
                    )}
                </Box>
            </Paper>

            {contentType === 'step' && (
                <StepperNavigator
                    loading={assessmentUpdating}
                    steps={steps || 3}
                    currentStep={currentStep || 3}
                    previousButtonLabel='Previous'
                    nextButtonLabel='Create'
                    onPrevious={onPrevious ? onPrevious : () => {}}
                    onNext={formik.handleSubmit}
                />
            )}
        </Box>
    );
};

export default AssessmentSettings;
