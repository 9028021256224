import Skeleton from 'react-loading-skeleton';
import { Box, spacing } from '@pelpr/pelpr-ui';

const columnFlexStartStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
};

const rowFlexStartStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
};

const spacingStyle = (spacing: (factor: number) => string) => ({
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
});

const QuestionViewSkeleton = () => {
    return (
        <Box sx={{ width: '100%', height: '100%', ...spacingStyle(spacing) }}>
            <Box sx={{ ...columnFlexStartStyle }}>
                <Skeleton width={300} height={30} />
                <Box
                    sx={{
                        width: '100%',
                        marginTop: spacing(5),
                        marginBottom: spacing(2),
                    }}>
                    <Skeleton width={100} height={20} />
                    <Skeleton width={'100%'} height={200} />
                </Box>
            </Box>

            <Box sx={{ ...rowFlexStartStyle, gap: spacing(4), ...spacingStyle(spacing) }}>
                {[1, 2, 3].map((item) => (
                    <Box key={item} sx={{ width: '100%', gap: spacing(3) }}>
                        <Skeleton width={50} height={20} />
                        <Skeleton width={200} height={30} />
                    </Box>
                ))}
            </Box>

            <Box sx={{ paddingTop: spacing(6) }}>
                <Skeleton width={100} height={20} />
            </Box>

            <Box>
                {[1, 2, 3].map((item) => (
                    <Box
                        key={item}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: spacing(2),
                            paddingTop: spacing(2),
                            paddingBottom: spacing(2),
                        }}>
                        <Skeleton width={500} height={30} />
                        <Skeleton width={60} height={30} />
                        <Skeleton width={50} height={30} />
                    </Box>
                ))}
            </Box>

            <Box sx={{ paddingTop: spacing(6) }}>
                <Skeleton width={300} height={30} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        gap: spacing(3),
                        marginTop: spacing(1),
                        marginBottom: spacing(2),
                    }}>
                    <Skeleton width={100} height={40} />
                    <Skeleton width={100} height={40} />
                </Box>
            </Box>
        </Box>
    );
};

export default QuestionViewSkeleton;
