import { createSlice } from '@reduxjs/toolkit';
import {
    AssessmentCategories,
    Currencies,
    QuestionCategories,
    QuestionDifficultyLevels,
    JobsRolesLookups,
} from 'src/config/lookups';
import { LookupItem } from 'src/modal';

interface LookUpType {
    assessmentCategories: LookupItem[];
    questionCategories: LookupItem[];
    questionDifficultyLevels: LookupItem[];
    rolesTypes: LookupItem[];
    currencies: LookupItem[];
}

interface InitialStateType {
    lookups: LookUpType;
}

const initialState: InitialStateType = {
    lookups: {
        assessmentCategories: AssessmentCategories,
        questionCategories: QuestionCategories,
        questionDifficultyLevels: QuestionDifficultyLevels,
        rolesTypes: JobsRolesLookups,
        currencies: Currencies,
    },
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: () => {},
});

export default appSlice.reducer;
