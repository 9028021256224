import { useState } from 'react';
import { Box, Paragraph, RichTextViewer, color, spacing } from '@pelpr/pelpr-ui';

//icons
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';
import { NoteDataType } from 'src/modal/candidate';
import { friendlyTime } from 'src/utilities/date';

interface CandidateNoteCardProps {
    note: NoteDataType;
}

const CandidateNoteCard = ({ note }: CandidateNoteCardProps) => {
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    const noteHtml = note?.note?.replace(/\n/g, '<br />');

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
                paddingTop: spacing(6),
                paddingBottom: spacing(6),
                borderBottom: `2.317px solid ${color.lightNeutral500}`,
            }}>
            <img
                alt='User picture'
                src={imageLoadError ? Avatar : note?.addedByUserPhotoUrl}
                width={48}
                height={48}
                style={{
                    borderRadius: '5px',
                }}
                onError={handleImageError}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(2),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: spacing(6),
                    }}>
                    <Paragraph>{note?.addedByUserName}</Paragraph>
                    <Paragraph size='small'>{friendlyTime(note?.createdAt)}</Paragraph>
                </Box>

                <Paragraph>
                    <RichTextViewer
                        sx={{
                            padding: 0,
                        }}
                        html={noteHtml}
                    />
                </Paragraph>
            </Box>
        </Box>
    );
};

export default CandidateNoteCard;
