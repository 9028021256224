import { useState } from 'react';
import {
    ActionMenu,
    Badge,
    Box,
    Button,
    Paper,
    Paragraph,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { formatToReadableDate } from 'src/utilities/date';
import CopyToClipboard from 'src/components/common/CopyToClipboard';
import { JobApplicantDataType, jobApplicationStagesEnum } from 'src/modal';

//icons
import { ReactComponent as CrossIcon } from 'src/assets/vectors/commons/cross-circle-icon.svg';
import { ReactComponent as CheckIcon } from 'src/assets/vectors/commons/check-circle-icon.svg';
import { ReactComponent as LinkIcon } from 'src/assets/vectors/commons/link-icon.svg';
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';
import { useAppSelector } from 'src/hooks';

const BadgeText: Record<number, string> = {
    1: 'Qualifed',
    2: 'Invited',
    3: 'Declined',
    4: 'Interview',
    5: 'Hired',
    6: 'Rejected',
    7: 'Matched',
};

interface JobApplicantCardProps {
    applicantData: JobApplicantDataType;
    onApprove?: (id: string, type: 'approved', version: string) => void;
    onReject?: (id: string, type: 'rejected', version: string) => void;
}

const JobApplicantCard = ({
    applicantData,
    onApprove,
    onReject,
}: JobApplicantCardProps) => {
    const { updatingJobApplicant } = useAppSelector((state) => state.job);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };
    const matchType = 1;

    return (
        <Paper
            sx={{
                width: '220px',
                minWidth: '220px',
                height: '243px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: spacing(4),
                padding: `${spacing(6)} ${spacing(4)}`,
            }}>
            <></>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    color: color.lightNeutral700,
                    '.open-menu': {
                        transform: 'rotate(90deg)',
                    },
                }}>
                <CopyToClipboard
                    value={applicantData?.candidateId}
                    label='Copy userid to clipboard'
                    tooltipPlace='right'
                />

                <img
                    alt='User picture'
                    src={imageLoadError ? Avatar : applicantData?.candidatePhoto}
                    width={88}
                    height={88}
                    style={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                    }}
                    onError={handleImageError}
                />

                <ActionMenu
                    alignment='left'
                    minWidth={'150px'}
                    isOpen={isMenuOpened}
                    closeMenuHandler={() => setIsMenuOpened(false)}
                    openMenuHandler={() => setIsMenuOpened(!isMenuOpened)}>
                    <Button
                        sx={{
                            width: '100%',
                            justifyContent: 'flex-start',
                        }}
                        variant='ghost'
                        icon='view'
                        href={`/candidates/${applicantData?.candidateId}`}
                        disabled={updatingJobApplicant}>
                        View
                    </Button>
                    {applicantData?.jobApplicationStageId ===
                        jobApplicationStagesEnum.Matched && (
                        <>
                            <Button
                                disabled={updatingJobApplicant}
                                variant='ghost'
                                CustomIcon={CheckIcon}
                                onClick={() =>
                                    onApprove &&
                                    onApprove(
                                        applicantData?.id,
                                        'approved',
                                        applicantData?.version,
                                    )
                                }>
                                Approve
                            </Button>
                            <Button
                                variant='ghost'
                                CustomIcon={CrossIcon}
                                disabled={updatingJobApplicant}
                                onClick={() =>
                                    onReject &&
                                    onReject(
                                        applicantData?.id,
                                        'rejected',
                                        applicantData?.version,
                                    )
                                }>
                                Reject
                            </Button>
                        </>
                    )}
                </ActionMenu>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(1.25),
                }}>
                <Paragraph
                    size='regular'
                    sx={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '188px',
                    }}>
                    {applicantData?.candidateName}
                </Paragraph>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: spacing(2),
                    }}>
                    <Badge
                        text={BadgeText[applicantData?.jobApplicationStageId]}
                        variant={matchType === 1 ? 'green' : 'neutral'}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(2),
                    color: color.darkNeutral300,
                }}>
                <LinkIcon
                    style={{
                        minWidth: spacing(5),
                    }}
                />
                <Paragraph
                    weight='medium'
                    sx={{
                        textWrap: 'nowrap',
                    }}>
                    {formatToReadableDate(applicantData?.createdAt)}
                </Paragraph>
            </Box>
        </Paper>
    );
};

export default JobApplicantCard;
