import { useState, useMemo } from 'react';
import {
    SystemTable,
    Box,
    Paragraph,
    Pagination,
    DebounceInput,
    color,
    spacing,
    Button,
    SortFun,
    fuzzyFilter,
    FilterSelect,
    Checkbox,
} from '@pelpr/pelpr-ui';
import {
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender,
    FilterFn,
} from '@tanstack/react-table';

// icons
import { ReactComponent as CrossIcon } from 'src/assets/vectors/commons/cross-icon.svg';
import { Filters } from 'src/modal';

type TableProps<T> = {
    data: T[];
    columns: ColumnDef<T, T>[];
    filters: Filters<T>[];
    searchFilterFn?: FilterFn<T>;
    showDeleted?: boolean;
    setShowDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
};

const filterBadgeStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: spacing(1),
    color: color.darkNeutral500,
    backgroundColor: color.neonBlue50,
    borderRadius: '5px',
    padding: `${spacing(2)} ${spacing(3)}`,
    svg: {
        cursor: 'pointer',
        '&:hover': {
            color: color.danger500,
        },
    },
};

const Table = <T,>({
    data,
    columns,
    filters,
    searchFilterFn,
    showDeleted,
    setShowDeleted,
}: TableProps<T>) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const filteredData = useMemo(() => {
        if (data.length === 0) {
            return [];
        }
        let filtered = data;

        // Apply filters dynamically
        if (columnFilters) {
            filters.forEach((filter) => {
                const filterValues = columnFilters.find((f) => f.id === filter.id)
                    ?.value as string[];

                if (filterValues?.length > 0) {
                    filtered = filtered.filter((item) => {
                        if (filter.column === 'isPublished') {
                            return filterValues.includes(
                                /* eslint-disable  @typescript-eslint/no-explicit-any */
                                (item as any).isPublished ? 'Published' : 'Unpublished',
                            );
                        } else {
                            const matchingValues = filter.data
                                /* eslint-disable  @typescript-eslint/no-explicit-any */
                                .filter((f: any) => filterValues.includes(f.label))
                                /* eslint-disable  @typescript-eslint/no-explicit-any */
                                .map((f: any) => f.value);

                            // Ensure the current item matches any of the filter criteria
                            return matchingValues.includes((item as any)[filter.column]);
                        }
                    });
                }
            });
        }

        // Apply global search filter
        if (globalFilter) {
            setPagination({ ...pagination, pageIndex: 0 });
            const searchTerms = globalFilter
                .toLowerCase()
                .split(',')
                .map((term) => term.trim());

            filtered = filtered.filter((tbData) => {
                if (typeof tbData === 'object' && tbData !== null) {
                    return Object.values(tbData).some((value) => {
                        const stringValue = String(value).toLowerCase();
                        return searchTerms.some((term) =>
                            stringValue.includes(term.toLowerCase()),
                        );
                    });
                }
                return false;
            });
        }

        return filtered;
    }, [data, columnFilters, globalFilter, filters]);

    const startIndex = pagination.pageIndex * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    const currentPageData = useMemo(() => {
        return filteredData.slice(startIndex, endIndex);
    }, [filteredData, pagination.pageIndex, pagination.pageSize]);

    const table = useReactTable({
        data: currentPageData,
        columns,
        initialState: {
            pagination: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        enableColumnFilters: true,
        manualPagination: true,
        pageCount: Math.ceil(filteredData.length / pagination.pageSize),
        onColumnFiltersChange: (filters) => {
            setColumnFilters(filters);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        onGlobalFilterChange: (filter) => {
            setGlobalFilter(filter);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        globalFilterFn: searchFilterFn || fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    // Dynamically create columns map
    const columnsMap = filters.reduce(
        (acc, filter) => {
            acc[filter.id] = table?.getColumn(filter.id);
            return acc;
        },
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        {} as Record<string, any>,
    );

    // Clear filters dynamically
    const clearFilters = () => {
        filters.forEach((filter) => {
            columnsMap[filter.id]?.setFilterValue([]);
        });
    };

    const handlePageSize = (newPageSize: number) => {
        setPagination({ ...pagination, pageSize: newPageSize });
        table.setPageSize(newPageSize);
    };

    const handlePageChange = (pageNumber: number) => {
        setPagination({ ...pagination, pageIndex: pageNumber - 1 });
        table.setPageIndex(pageNumber - 1);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: spacing(3),
                    marginBottom: spacing(6),
                }}>
                <Box
                    sx={{
                        width: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    {filters?.length > 0 &&
                        filters.map((filter) => (
                            <FilterSelect
                                key={filter.id}
                                size={filter.size}
                                uniqueKey={filter.id}
                                label={filter.label}
                                defaultSelected={
                                    columnsMap[filter.id]?.getFilterValue()
                                        ? (columnsMap[
                                              filter.id
                                          ]?.getFilterValue() as string[])
                                        : []
                                }
                                onApply={(filterValue) => {
                                    columnsMap[filter.id]?.setFilterValue(filterValue);
                                }}
                                /* eslint-disable  @typescript-eslint/no-explicit-any */
                                filters={filter.data.map((value: any) => value.label)}
                            />
                        ))}
                    {showDeleted != null && setShowDeleted != null && (
                        <Checkbox
                            size='sm'
                            label='Show deleted'
                            sx={{
                                marginTop: '10px',
                                label: {
                                    width: '140px',
                                },
                            }}
                            checked={showDeleted}
                            onChange={(event) => setShowDeleted(event.target.checked)}
                        />
                    )}
                </Box>

                <DebounceInput
                    sx={{
                        width: '100%',
                        maxWidth: '354px',
                        marginBottom: 0,
                    }}
                    size='small'
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value))}
                />
            </Box>
            {filters.some(
                (filter) =>
                    (columnsMap[filter.id]?.getFilterValue() as string[])?.length > 0,
            ) && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: spacing(5),
                        padding: spacing(3),
                        marginBottom: spacing(6),
                        backgroundColor: color.lightNeutral300,
                        borderRadius: '5px',
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: spacing(2),
                        }}>
                        {filters.flatMap((filter) =>
                            (columnsMap[filter.id]?.getFilterValue() as string[])?.map(
                                (filterValue) => (
                                    <Box key={filterValue} sx={filterBadgeStyle}>
                                        <Paragraph size='small'>{`${filter.label}: ${filterValue}`}</Paragraph>
                                        <CrossIcon
                                            onClick={() => {
                                                columnsMap[filter.id]?.setFilterValue(
                                                    (
                                                        columnsMap[
                                                            filter.id
                                                        ]?.getFilterValue() as string[]
                                                    ).filter(
                                                        (value) => value !== filterValue,
                                                    ),
                                                );
                                            }}
                                        />
                                    </Box>
                                ),
                            ),
                        )}
                    </Box>
                    <Button variant='secondary' size='small' onClick={clearFilters}>
                        Clear all
                    </Button>
                </Box>
            )}
            <SystemTable minColumnWidth={50}>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{ width: header.getSize() }}>
                                    {header.isPlaceholder ? null : (
                                        <Box
                                            sx={{
                                                cursor: header.column.getCanSort()
                                                    ? 'pointer'
                                                    : 'default',

                                                userSelect: header.column.getCanSort()
                                                    ? 'none'
                                                    : 'auto',
                                            }}
                                            {...{
                                                onClick:
                                                    header.column.getToggleSortingHandler(),
                                            }}>
                                            {flexRender(
                                                header.column.getIsSorted()
                                                    ? ''
                                                    : header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            {{
                                                asc: (
                                                    <SortFun
                                                        type='asc'
                                                        headerName={
                                                            header.column.columnDef
                                                                .header as string
                                                        }
                                                    />
                                                ),
                                                desc: (
                                                    <SortFun
                                                        type='desc'
                                                        headerName={
                                                            header.column.columnDef
                                                                .header as string
                                                        }
                                                    />
                                                ),
                                            }[header.column.getIsSorted() as string] ??
                                                null}
                                        </Box>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        style={{ width: cell.column.getSize() }}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </SystemTable>

            {currentPageData?.length === 0 && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: spacing(15),
                        paddingBottom: spacing(9),
                    }}>
                    <Paragraph
                        sx={{
                            color: color.midNeutral500,
                        }}>
                        No Data to display
                    </Paragraph>
                </Box>
            )}
            {currentPageData?.length > 0 && (
                <Pagination
                    currentPage={pagination.pageIndex + 1}
                    totalPages={table.getPageOptions()?.length || 1}
                    setCurrentPage={handlePageChange}
                    pageSize={pagination.pageSize}
                    onPageSizeChange={handlePageSize}
                />
            )}
        </Box>
    );
};

export default Table;
