import { useState } from 'react';
import { Box, Button, Headline, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { QualificationType } from 'src/modal/candidate';
import { format, parseJSON } from 'date-fns';

import { ReactComponent as EducationIcon } from 'src/assets/vectors/commons/education-icon.svg';

interface CandidateQualificationsProps {
    qualifications: QualificationType[];
}

const CandidateQualifications = ({ qualifications }: CandidateQualificationsProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const qualificationsList = expanded ? qualifications : qualifications?.slice(0, 3);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
            }}>
            <Headline variant='h6'>Education</Headline>
            {qualificationsList?.map((qualification, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <EducationIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph size='regular' weight='semi-bold'>
                            {qualification?.qualificationLabel}
                        </Paragraph>
                        <Paragraph>{qualification?.institution}</Paragraph>
                        <Paragraph>
                            {`${format(
                                parseJSON(qualification?.startDate),
                                'MMM yyyy',
                            )} - ${
                                qualification?.isCurrent
                                    ? 'Present'
                                    : format(
                                          parseJSON(qualification?.endDate),
                                          'MMM yyyy',
                                      )
                            }`}
                        </Paragraph>
                        <Paragraph>{qualification?.location}</Paragraph>
                        <Paragraph
                            sx={{
                                marginTop: spacing(2),
                            }}>
                            {qualification?.description}
                        </Paragraph>
                    </Box>
                </Box>
            ))}
            {qualifications?.length > 3 && (
                <Button
                    variant='link'
                    sx={{
                        margin: '0 auto',
                    }}
                    onClick={handleExpandClick}>
                    {expanded ? 'See less' : 'See more'}
                </Button>
            )}
        </Box>
    );
};

export default CandidateQualifications;
