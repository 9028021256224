import { useEffect } from 'react';
import { Box, Button, PageContainer, PageHeader, spacing } from '@pelpr/pelpr-ui';
import { useNavigate, useParams } from 'react-router-dom';
import JobDetailsPanel from 'src/components/jobs/JobDetails/JobDetailsPanel';
import JobDetailsSidePanel from 'src/components/jobs/JobDetails/JobDetailsSidePanel';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchJobDetails } from 'src/redux/job/jobApi';
import JobDetailsSidePanelSkeleton from 'src/components/jobs/JobDetails/JobDetailsSidePanelSkeleton';
import JobDetailsPanelSkeleton from 'src/components/jobs/JobDetails/JobDetailsPanelSkeleton';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/vectors/commons/arrow-left-icon.svg';

const JobDetailsPage = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const { fetchingJobDetails } = useAppSelector((state) => state.job);
    const dispatch = useAppDispatch();

    useEffect(() => {
        jobId && dispatch(fetchJobDetails({ jobId }));
    }, []);

    return (
        <PageContainer
            sx={{ minHeight: '100vh' }}
            pageHeader={<PageHeader title='Jobs / Details' />}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: spacing(12),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}>
                    <Button
                        siz='small'
                        variant='link'
                        CustomIcon={ArrowLeftIcon}
                        onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    {fetchingJobDetails ? (
                        <>
                            <JobDetailsPanelSkeleton />
                            <JobDetailsSidePanelSkeleton />
                        </>
                    ) : (
                        <>
                            <JobDetailsPanel />
                            <JobDetailsSidePanel />
                        </>
                    )}
                </Box>
            </Box>
        </PageContainer>
    );
};

export default JobDetailsPage;
