import { Countries } from 'src/config/lookups';
import {
    CandidatesLeftSearchFiltersType,
    QueryParamType,
    CandidatesTopSearchFiltersType,
    JobsTopSearchFiltersType,
    JobsLeftSearchFiltersType,
} from 'src/modal';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const isEmpty = (val: any) =>
    val === undefined ||
    val === null ||
    (typeof val === 'object' && Object.keys(val).length === 0) ||
    (typeof val === 'string' && val.trim().length === 0);

// Function to generate a random code verifier
export const generateCodeVerifier = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeVerifier = Array.from(crypto.getRandomValues(new Uint8Array(128)))
        .map((byte) => chars[byte % chars.length])
        .join('');
    return codeVerifier;
};

// Function to generate the code challenge from the code verifier
export const generateCodeChallenge = async (codeVerifier: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const codeChallenge = base64urlencode(hashBuffer);
    return codeChallenge;
};

// Function to encode ArrayBuffer to base64url
const base64urlencode = (arrayBuffer: ArrayBuffer) => {
    const bytes = new Uint8Array(arrayBuffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

// Helper function to convert queryParams array to query string
export const buildQueryString = (queryParams: QueryParamType[]): string => {
    return queryParams
        .map(
            (param) =>
                `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`,
        )
        .join('&');
};

export const formatSearchCandidateQueryParams = (
    topFilters: CandidatesTopSearchFiltersType,
    leftFilters: CandidatesLeftSearchFiltersType,
): QueryParamType[] => {
    const countryValue = Countries?.find(
        (country) => country?.value === topFilters?.country,
    )?.label;

    const allFilters = {
        ...topFilters,
        search: topFilters.search?.trim(),
        country: countryValue,
        ...leftFilters,
    };

    type QueriesPramsObjectType = {
        q: string | null;
        country: string | null;
        city: string | null;
        sortBy: string | null;
        page: number | null;
        pageSize: number | null;
        isLive: boolean | null;
        experienceLevel: string[] | null;
        jobSearchStatus: string[] | null;
        openToOffice: string | null;
        openToHybrid: string | null;
        openToRemote: string | null;
        minimumSalary: number | null;
        maximumSalary: number | null;
    };

    const queriesPramsObject: QueriesPramsObjectType = {
        q: allFilters?.search || null,
        country: allFilters?.country?.trim() || null,
        city: allFilters?.city.trim() || null,
        sortBy: !allFilters?.search ? allFilters?.sortBy.trim() || null : null,
        page: allFilters?.page || null,
        pageSize: allFilters?.pageSize || null,
        isLive:
            allFilters?.profileStatus?.length === 2
                ? null
                : allFilters?.profileStatus?.find((item) => item === 'live')
                  ? true
                  : allFilters?.profileStatus?.find((item) => item === 'offline')
                    ? false
                    : null,
        experienceLevel:
            allFilters?.experienceLevel?.length > 0 ? allFilters?.experienceLevel : null,
        jobSearchStatus:
            allFilters?.candidateJobStatus?.length > 0
                ? allFilters?.candidateJobStatus
                : null,
        openToOffice: allFilters?.jobCategory?.find((item) => item === 'Onsite')
            ? 'true'
            : null,
        openToHybrid: allFilters?.jobCategory?.find((item) => item === 'Hybrid')
            ? 'true'
            : null,
        openToRemote: allFilters?.jobCategory?.find((item) => item === 'Remote')
            ? 'true'
            : null,
        minimumSalary:
            allFilters?.salaryRange?.[0] !== undefined
                ? allFilters?.salaryRange[0]
                : null,
        maximumSalary:
            allFilters?.salaryRange?.[1] !== undefined
                ? allFilters?.salaryRange[1]
                : null,
    };

    const queryParams: QueryParamType[] = Object.entries(queriesPramsObject)
        .filter(([, value]) => value !== null)
        .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map((item) => ({
                    key,
                    value: String(item),
                }));
            }
            return {
                key,
                value: String(value),
            };
        });
    return queryParams;
};

export const formatSearchJobQueryParams = (
    topFilters: JobsTopSearchFiltersType,
    leftFilters: JobsLeftSearchFiltersType,
): QueryParamType[] => {
    const countryValue = Countries?.find(
        (country) => country?.value === topFilters?.country,
    )?.label;

    const allFilters = {
        ...topFilters,
        search: topFilters.search?.trim(),
        country: countryValue?.trim(),
        ...leftFilters,
    };

    type QueriesPramsObjectType = {
        q: string | null;
        country: string | null;
        city: string | null;
        sortBy: string | null;
        page: number | null;
        pageSize: number | null;
        jobLevel: string[] | null;
        jobType: string[] | null;
        workplaceType: string[] | null;
        companyProfile: string[] | null;
        companySize: string[] | null;
        minimumSalary: number | null;
        maximumSalary: number | null;
    };

    const queriesPramsObject: QueriesPramsObjectType = {
        q: allFilters?.search || null,
        country: allFilters?.country || null,
        city: allFilters?.city?.trim() || null,
        sortBy: !allFilters?.search ? allFilters?.sortBy?.trim() || null : null,
        page: allFilters?.page || null,
        pageSize: allFilters?.pageSize || null,
        jobLevel:
            allFilters?.experienceLevel?.length > 0 ? allFilters?.experienceLevel : null,
        jobType: allFilters?.jobType?.length > 0 ? allFilters?.jobType : null,
        workplaceType:
            allFilters?.workplaceType?.length > 0 ? allFilters?.workplaceType : null,
        companyProfile:
            allFilters?.companyProfile?.length > 0 ? allFilters?.companyProfile : null,
        companySize: allFilters?.companySize?.length > 0 ? allFilters?.companySize : null,
        minimumSalary:
            allFilters?.salaryRange?.[0] !== undefined
                ? allFilters?.salaryRange[0]
                : null,
        maximumSalary:
            allFilters?.salaryRange?.[1] !== undefined
                ? allFilters?.salaryRange[1]
                : null,
    };

    const queryParams: QueryParamType[] = Object.entries(queriesPramsObject)
        .filter(([, value]) => value !== null)
        .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map((item) => ({
                    key,
                    value: String(item),
                }));
            }
            return {
                key,
                value: String(value),
            };
        });
    return queryParams;
};
export const DescriptionRegex =
    /^[a-zA-Z0-9\s\n\r\:\,\.\#\-\'\"\!\?\&\%\=\_\/\;\(\)\+]+$/;

export const stripHtmlTags = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent ?? div.innerText) || '';
};
export const isValidHtml = (html: string) => {
    // Strip HTML tags to validate inner content
    const textContent = stripHtmlTags(html);

    // Validate inner content with allowed characters regex
    return DescriptionRegex.test(textContent);
};

const phoneUtil = PhoneNumberUtil.getInstance();

interface PhoneNumberValidationOptions {
    defaultRegion?: string;
}

export const isValidPhoneNumber = (
    value: string | undefined,
    options?: PhoneNumberValidationOptions,
): boolean => {
    try {
        if (!value) {
            return false; // Handle the case where value is undefined
        }

        const defaultRegion = options?.defaultRegion || 'US';
        const phoneNumber: PhoneNumber = phoneUtil.parse(value, defaultRegion);
        return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
        return false;
    }
};

export const stripHtmlAndSpaces = (value: string) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.textContent || div.innerText || '';
    return text.replace(/\s+/g, '');
};
