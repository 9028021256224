import { Box, Paper, color, spacing } from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';

const CandidateDetailsSidePanelSkeleton = () => {
    return (
        <Paper
            sx={{
                width: '100%',
                maxWidth: '400px',
                minWidth: '400px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
                padding: spacing(6),
                borderRadius: spacing(1.25),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <Skeleton
                        width={88}
                        height={88}
                        style={{
                            borderRadius: '5px',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Skeleton width={100} height={24} />
                        <Skeleton width={150} height={14} />
                        <Skeleton width={180} height={14} />
                    </Box>
                </Box>
                <Skeleton width={24} height={24} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(4),
                    }}>
                    <Skeleton width={150} height={28} />
                    <Skeleton width={90} height={14} />
                </Box>
                <Skeleton width={20} height={10} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={80} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Skeleton width={150} height={15} />
                <Skeleton width={250} height={14} count={5} />
            </Box>

            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    gap: spacing(2),
                }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                    <Skeleton key={item} width={80} height={28} />
                ))}
            </Box>

            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Skeleton width={120} height={24} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Skeleton width={50} height={12} />
                    <Skeleton width={100} height={14} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(4),
                }}>
                <Skeleton width={120} height={24} />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(2),
                    }}>
                    {[1, 2, 3, 4, 6]?.map((item) => (
                        <Box
                            key={item}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: spacing(4),
                            }}>
                            <Skeleton width={60} height={12} />
                            <Skeleton width={90} height={14} />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Paper>
    );
};

export default CandidateDetailsSidePanelSkeleton;
