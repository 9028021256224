import { QueryParamType } from 'src/modal';
import { axiosInstance } from 'src/utilities/axiosInstance';
import { buildQueryString } from 'src/utilities/helpers';

const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

// fetch jobs meta data by reference
export const getJobMetaByRef = (reference: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/jobs/references/${reference}`);
};

// fetch jobs by search
export const fetchSearchJobs = (queryParams: QueryParamType[]) => {
    const queryString = buildQueryString(queryParams);
    const url = queryString
        ? `${API_URL}/v1/admin/jobs/search?${queryString}`
        : `${API_URL}/v1/admin/jobs/search`;
    return axiosInstance.get(url);
};

// fetch jobs  collaborators
export const getJobCollaborators = (jobId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/jobs/${jobId}/collaborators`);
};

// get job details
export const getJobDetails = (jobId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/jobs/${jobId}`);
};

// get job applications
export const getJobApplications = (jobPostId: string) => {
    return axiosInstance.get(
        `${API_URL}/v1/admin/job-applications?jobPostId=${jobPostId}`,
    );
};

// approve matched job application
export const approveMatchedJobApplicationApi = (
    jobApplicationId: string,
    version: string,
) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/job-applications/${jobApplicationId}/approve?version=${version}`,
    );
};

// reject matched job application
export const rejectMatchedJobApplicationApi = (
    jobApplicationId: string,
    version: string,
) => {
    return axiosInstance.delete(
        `${API_URL}/v1/admin/job-applications/${jobApplicationId}?version=${version}`,
    );
};

export const getJobsListing = () => {
    return axiosInstance.get(`${API_URL}/v1/admin/jobs`);
};
