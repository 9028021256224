import { useState } from 'react';
import {
    Box,
    Button,
    DebounceInput,
    Headline,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    spacing,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchJobMetaDataByRef } from 'src/redux/job/jobApi';
import JobCard from '../../jobs/JobCard';
import JobCardSkeleton from '../../jobs/JobCardSkeleton';
import { addCandidateProfileToJob } from 'src/redux/candidate/candidateApi';

interface CandidateAddToJobModalProps {
    open: boolean;
    onClose: () => void;
    candidateId: string;
}

const CandidateAddToJobModal = ({
    open,
    onClose,
    candidateId,
}: CandidateAddToJobModalProps) => {
    const { candidateProfileUpdating } = useAppSelector((state) => state.candidate);
    const { fetchingJobMeta, jobMetaData } = useAppSelector((state) => state.job);
    const [jobReference, setJobReference] = useState('');
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleChangeJobReference = (value: string) => {
        setJobReference(value);
        value && handleFetchJobMetaByRef(value);
    };

    const handleApprove = () => {
        if (!fetchingJobMeta && jobReference && jobMetaData) {
            dispatch(
                addCandidateProfileToJob({
                    payload: {
                        jobPostId: jobMetaData?.id,
                        candidateId,
                        matchType: 1,
                        score: 0,
                    },
                    onSuccess: () => {
                        onClose();
                    },
                }),
            );
        }
    };

    const handleFetchJobMetaByRef = (reference: string) => {
        dispatch(
            fetchJobMetaDataByRef({
                reference,
            }),
        );
    };

    return (
        <Modal
            uniqueKey='add-candidate-to-job'
            show={open}
            sx={{
                width: '100%',
                maxWidth: '700px',
            }}>
            <ModalHeader>
                <Headline variant='h6'>Add to job</Headline>
            </ModalHeader>
            <ModalBody
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(5),
                }}>
                <DebounceInput
                    sx={{
                        width: '100%',
                        maxWidth: '354px',
                        marginBottom: 0,
                    }}
                    placeholder='Search by reference'
                    size='medium'
                    value={jobReference}
                    onChange={(value) => handleChangeJobReference(String(value))}
                    disabled={fetchingJobMeta}
                />
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '148px',
                    }}>
                    {!fetchingJobMeta && jobReference && jobMetaData && (
                        <JobCard jobData={jobMetaData} />
                    )}
                    {fetchingJobMeta && <JobCardSkeleton />}
                </Box>
            </ModalBody>
            <ModalFooter
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: spacing(2),
                }}>
                <Button variant='ghost' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    loading={!!candidateProfileUpdating}
                    disabled={
                        !!candidateProfileUpdating ||
                        fetchingJobMeta ||
                        !jobReference ||
                        !jobMetaData
                    }
                    onClick={handleApprove}>
                    Add to job
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CandidateAddToJobModal;
