import { useState } from 'react';
import {
    ActionMenu,
    Badge,
    Box,
    Paper,
    Paragraph,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { formatToReadableDate } from 'src/utilities/date';
import CopyToClipboard from 'src/components/common/CopyToClipboard';
import { JobCollaboratorDataType } from 'src/modal';

//icons
import { ReactComponent as LinkIcon } from 'src/assets/vectors/commons/link-icon.svg';
import Avatar from 'src/assets/images/avatar-placeholder-primary.png';

interface JobCollaboratorCardProps {
    collaboratorData: JobCollaboratorDataType;
}

const JobCollaboratorCard = ({ collaboratorData }: JobCollaboratorCardProps) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Paper
            sx={{
                width: '220px',
                minWidth: '220px',
                height: '243px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: spacing(4),
                padding: `${spacing(6)} ${spacing(4)}`,
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    color: color.lightNeutral700,
                    '.open-menu': {
                        transform: 'rotate(90deg)',
                    },
                }}>
                <CopyToClipboard
                    value={collaboratorData?.userId}
                    label='Copy userid to clipboard'
                    tooltipPlace='right'
                />

                <img
                    alt='User picture'
                    src={imageLoadError ? Avatar : collaboratorData?.userPhotoUrl}
                    width={88}
                    height={88}
                    style={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                    }}
                    onError={handleImageError}
                />

                <ActionMenu
                    alignment='left'
                    minWidth={'150px'}
                    isOpen={isMenuOpened}
                    closeMenuHandler={() => setIsMenuOpened(false)}
                    openMenuHandler={() => setIsMenuOpened(!isMenuOpened)}>
                    <Box
                        sx={{
                            height: spacing(6),
                        }}></Box>
                </ActionMenu>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: spacing(1.25),
                }}>
                <Paragraph
                    size='regular'
                    sx={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '188px',
                    }}>
                    {collaboratorData?.userName}
                </Paragraph>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: spacing(2),
                    }}>
                    {collaboratorData?.roles?.map((role) => (
                        <Badge key={role} text={role} variant='neutral' />
                    ))}
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(2),
                    color: color.darkNeutral300,
                }}>
                <LinkIcon
                    style={{
                        minWidth: spacing(5),
                    }}
                />
                <Paragraph
                    weight='medium'
                    sx={{
                        textWrap: 'nowrap',
                    }}>
                    {formatToReadableDate(collaboratorData?.createdAt)}
                </Paragraph>
            </Box>
        </Paper>
    );
};

export default JobCollaboratorCard;
