import { useState } from 'react';
import { Box, Button, Headline, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { ProjectType } from 'src/modal/candidate';

import { ReactComponent as MedalIcon } from 'src/assets/vectors/commons/medal-star-icon.svg';

const replaceWithBr = (description: string) => {
    return description.replace(/\n/g, '<br />');
};

interface CandidateProjectsProps {
    projects: ProjectType[];
}

const CandidateProjects = ({ projects }: CandidateProjectsProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const projectsList = expanded ? projects : projects?.slice(0, 3);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
            }}>
            <Headline variant='h6'>Projects</Headline>
            {projectsList?.map((project, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <MedalIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph size='regular' weight='semi-bold'>
                            {project?.name}
                        </Paragraph>
                        <a target='_blank' rel='noreferrer' href={project?.url}>
                            <Paragraph sx={{ color: color.neonBlue500 }}>
                                {project?.url}
                            </Paragraph>
                        </a>
                        <Paragraph>{replaceWithBr(project?.description)}</Paragraph>
                    </Box>
                </Box>
            ))}
            {projects?.length > 3 && (
                <Button
                    variant='link'
                    sx={{
                        margin: '0 auto',
                    }}
                    onClick={handleExpandClick}>
                    {expanded ? 'See less' : 'See more'}
                </Button>
            )}
        </Box>
    );
};

export default CandidateProjects;
