import { createSlice } from '@reduxjs/toolkit';
import {
    addNewAssessment,
    getAllAssessments,
    getAssessment,
    publishAssessment,
    removeAssessment,
    removeQuizAttempt,
    unpublishAssessment,
    updateAssessment,
    uploadAssessmentPhoto,
    getUpdatedAssessment,
} from './assessmentsApi';
import { AssessmentDataType, AssessmentListDataType } from 'src/modal';

export interface InitialStateType {
    assessments: AssessmentListDataType[];
    assessment: AssessmentDataType | null;
    assessmentUpdating: boolean;
    assessmentPublishing: boolean;
    assessmentImageUploading: boolean;
    assessmentImageUploaded: boolean;
    removingAttempt: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: InitialStateType = {
    assessments: [],
    assessment: null,
    assessmentUpdating: false,
    assessmentPublishing: false,
    assessmentImageUploading: false,
    assessmentImageUploaded: false,
    removingAttempt: false,
    loading: false,
    error: null,
};

const quizzesSlice = createSlice({
    name: 'assessments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch all assessments
        builder.addCase(getAllAssessments.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllAssessments.fulfilled, (state, action) => {
            state.assessments = action.payload;
            state.loading = false;
        });
        builder.addCase(getAllAssessments.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // fetch a assessment
        builder.addCase(getAssessment.pending, (state) => {
            state.assessment = null;
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAssessment.fulfilled, (state, action) => {
            state.assessment = action.payload;
            state.loading = false;
        });
        builder.addCase(getAssessment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // add new assessment
        builder.addCase(addNewAssessment.pending, (state) => {
            state.assessmentUpdating = true;
            state.error = null;
        });
        builder.addCase(addNewAssessment.fulfilled, (state) => {
            state.assessmentUpdating = false;
        });
        builder.addCase(addNewAssessment.rejected, (state, action) => {
            state.assessmentUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // update assessment
        builder.addCase(updateAssessment.pending, (state) => {
            state.assessmentUpdating = true;
            state.error = null;
        });
        builder.addCase(updateAssessment.fulfilled, (state, action) => {
            state.assessment = action.payload;
            state.assessmentUpdating = false;
        });
        builder.addCase(updateAssessment.rejected, (state, action) => {
            state.assessmentUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // publish assessment
        builder.addCase(publishAssessment.pending, (state) => {
            state.assessmentPublishing = true;
            state.error = null;
        });
        builder.addCase(publishAssessment.fulfilled, (state) => {
            //TODO: will modify assessments array in future to avoid extra api call
            state.assessmentPublishing = false;
        });
        builder.addCase(publishAssessment.rejected, (state, action) => {
            state.assessmentPublishing = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // un-publish assessment
        builder.addCase(unpublishAssessment.pending, (state) => {
            //TODO: will modify assessments array in future to avoid extra api call
            state.assessmentPublishing = true;
            state.error = null;
        });
        builder.addCase(unpublishAssessment.fulfilled, (state) => {
            state.assessmentPublishing = false;
        });
        builder.addCase(unpublishAssessment.rejected, (state, action) => {
            state.assessmentPublishing = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // delete assessment
        builder.addCase(removeAssessment.pending, (state) => {
            state.assessmentUpdating = true;
            state.error = null;
        });
        builder.addCase(removeAssessment.fulfilled, (state, action) => {
            state.assessments = state.assessments?.filter(
                (assessment) => assessment?.id !== action.payload,
            );
            state.assessmentUpdating = false;
        });
        builder.addCase(removeAssessment.rejected, (state, action) => {
            state.assessmentUpdating = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // upload assessment photo
        builder.addCase(uploadAssessmentPhoto.pending, (state) => {
            state.assessmentImageUploading = true;
            state.error = null;
        });
        builder.addCase(uploadAssessmentPhoto.fulfilled, (state) => {
            state.assessmentImageUploading = false;
            state.assessmentImageUploaded = true;
        });
        builder.addCase(uploadAssessmentPhoto.rejected, (state, action) => {
            state.assessmentImageUploading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // delete quiz attempt
        builder.addCase(removeQuizAttempt.pending, (state) => {
            state.removingAttempt = true;
            state.error = null;
        });
        builder.addCase(removeQuizAttempt.fulfilled, (state) => {
            state.removingAttempt = false;
        });
        builder.addCase(removeQuizAttempt.rejected, (state, action) => {
            state.removingAttempt = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // update assignment status
        builder.addCase(getUpdatedAssessment.pending, (state) => {
            state.error = null;
        });
        builder.addCase(
            getUpdatedAssessment.fulfilled,
            (state, action) => {                
                const { id, isPublished, version } = action.payload;
                const assessmentIndex = state.assessments.findIndex(
                    (assessment) => assessment.id === id,
                );

                if (assessmentIndex !== -1) {
                    state.assessments[assessmentIndex].isPublished = isPublished;
                    state.assessments[assessmentIndex].version = version;
                }
            },
        );
        builder.addCase(getUpdatedAssessment.rejected, (state, action) => {   
            state.error = action.error.message || 'Failed to update assessment status';
        });
    },
});

export default quizzesSlice.reducer;
