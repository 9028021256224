import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    deleteUserAccount,
    getAllUsersAccounts,
    getAuthenticatedUserInfo,
    getUserAccount,
    updateUserAccount,
    updateUserAccountStatus,
} from './userApi';
import { AuthenticatedUserInfo, UserDataType, UserListDataType } from 'src/modal';

export interface InitialStateType {
    loadingUser: boolean;
    error: string | null;
    authenticatedUserInfo: AuthenticatedUserInfo | null;
    usersAccounts: UserListDataType[];
    userAccountData: UserDataType | null;
    fetchingUsersAccounts: boolean;
    userLoading: boolean;
    updatingUserAccount: boolean;
}

const initialState: InitialStateType = {
    loadingUser: false,
    error: null,
    authenticatedUserInfo: null,
    usersAccounts: [],
    userAccountData: null,
    fetchingUsersAccounts: false,
    userLoading: false,
    updatingUserAccount: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateAuthenticatedUserInfo: (
            state,
            action: PayloadAction<{
                [x: string]: unknown;
            }>,
        ) => {
            // @ts-expect-error: Merging arbitrary properties into authenticatedUserInfo intentionally
            state.authenticatedUserInfo = {
                ...state.authenticatedUserInfo,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        // Get authenticated user
        builder.addCase(getAuthenticatedUserInfo.pending, (state) => {
            state.loadingUser = true;
            state.error = null;
        });
        builder.addCase(getAuthenticatedUserInfo.fulfilled, (state, action) => {
            state.authenticatedUserInfo = action.payload;
            state.loadingUser = false;
        });
        builder.addCase(getAuthenticatedUserInfo.rejected, (state, action) => {
            state.loadingUser = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // Get all users
        builder.addCase(getAllUsersAccounts.pending, (state) => {
            state.fetchingUsersAccounts = true;
            state.error = null;
        });
        builder.addCase(getAllUsersAccounts.fulfilled, (state, action) => {
            state.usersAccounts = action.payload;
            state.fetchingUsersAccounts = false;
        });
        builder.addCase(getAllUsersAccounts.rejected, (state, action) => {
            state.fetchingUsersAccounts = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // Get a user account Data
        builder.addCase(getUserAccount.pending, (state) => {
            state.userAccountData = null;
            state.userLoading = true;
            state.error = null;
        });
        builder.addCase(getUserAccount.fulfilled, (state, action) => {
            state.userAccountData = action.payload;
            state.userLoading = false;
        });
        builder.addCase(getUserAccount.rejected, (state, action) => {
            state.userLoading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // Update user account
        builder.addCase(updateUserAccount.pending, (state) => {
            state.updatingUserAccount = true;
            state.error = null;
        });
        builder.addCase(updateUserAccount.fulfilled, (state) => {
            state.updatingUserAccount = false;
        });
        builder.addCase(updateUserAccount.rejected, (state, action) => {
            state.updatingUserAccount = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // Update user account status
        builder.addCase(updateUserAccountStatus.pending, (state) => {
            state.userLoading = true;
            state.error = null;
        });
        builder.addCase(updateUserAccountStatus.fulfilled, (state, action) => {
            const previousUsers: UserListDataType[] = [...state.usersAccounts];
            const index = previousUsers.findIndex(
                (user) => user.id === action.payload.userId,
            );
            if (index !== -1) {
                previousUsers[index] = {
                    ...previousUsers[index],
                    accountStatusId: action.payload.status,
                };
                state.usersAccounts = previousUsers;
            }
            state.userLoading = false;
        });
        builder.addCase(updateUserAccountStatus.rejected, (state, action) => {
            state.userLoading = false;
            state.error = action.error.message || 'Something went wrong';
        });

        // Delete User account
        builder.addCase(deleteUserAccount.pending, (state) => {
            state.userLoading = true;
            state.error = null;
        });
        builder.addCase(deleteUserAccount.fulfilled, (state, action) => {
            state.usersAccounts = state.usersAccounts.filter(
                (user) => user.id !== action.payload,
            );
            state.userLoading = false;
        });
        builder.addCase(deleteUserAccount.rejected, (state, action) => {
            state.userLoading = false;
            state.error = action.error.message || 'Something went wrong';
        });
    },
});

export const { updateAuthenticatedUserInfo } = userSlice.actions;

export default userSlice.reducer;
