import { useState } from 'react';
import { Box, Button, Headline, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { PublicationType } from 'src/modal/candidate';
import { format, parseJSON } from 'date-fns';

import { ReactComponent as DocIcon } from 'src/assets/vectors/commons/doc-icon.svg';

const replaceWithBr = (description: string) => {
    return description.replace(/\n/g, '<br />');
};

interface CandidatePublicationsProps {
    publications: PublicationType[];
}

const CandidatePublications = ({ publications }: CandidatePublicationsProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const publicationsList = expanded ? publications : publications?.slice(0, 3);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
            }}>
            <Headline variant='h6'>Publications</Headline>
            {publicationsList?.map((publication, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <DocIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph size='regular' weight='semi-bold'>
                            {publication?.name}
                        </Paragraph>
                        <Paragraph>{publication?.publisher}</Paragraph>
                        <Paragraph>
                            {format(parseJSON(publication?.date), 'MMM yyyy')}
                        </Paragraph>
                        <a target='_blank' rel='noreferrer' href={publication?.url}>
                            <Paragraph sx={{ color: color.neonBlue500 }}>
                                {publication?.url}
                            </Paragraph>
                        </a>
                        <Paragraph>{replaceWithBr(publication?.description)}</Paragraph>
                    </Box>
                </Box>
            ))}
            {publications?.length > 3 && (
                <Button
                    variant='link'
                    sx={{
                        margin: '0 auto',
                    }}
                    onClick={handleExpandClick}>
                    {expanded ? 'See less' : 'See more'}
                </Button>
            )}
        </Box>
    );
};

export default CandidatePublications;
