import { Box, ConfirmationDialog, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { approveCandidateProfile } from 'src/redux/candidate/candidateApi';

interface CandidateProfileApproveModalProps {
    open: boolean;
    onClose: () => void;
    candidateId: string;
}

const CandidateProfileApproveModal = ({
    open,
    onClose,
    candidateId,
}: CandidateProfileApproveModalProps) => {
    const { updatingCandidateProfileStatus } = useAppSelector((state) => state.candidate);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleApprove = () => {
        dispatch(
            approveCandidateProfile({
                candidateId,
                onSuccess: () => {
                    onClose();
                },
            }),
        );
    };

    return (
        <ConfirmationDialog
            uniqueKey='approve-candidate-profile'
            actionType='submit'
            onConfirm={handleApprove}
            onDeny={handleCancel}
            loading={updatingCandidateProfileStatus}
            show={open}
            title='Approve Profile'
            cancelBtnLabel='Cancel'
            submitBtnLabel='Approve'>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(1),
                }}>
                <Paragraph
                    size='medium'
                    weight='semi-bold'
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    Approving candidate profiles on Pelpr:
                </Paragraph>
                <Paragraph
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    1. Only accounts will fully completed profiles must be approved.
                </Paragraph>
                <Paragraph
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    2. Make sure the experience level is set on the profile.
                </Paragraph>
                <Paragraph
                    sx={{
                        color: color.darkNeutral300,
                    }}>
                    3. Ensure the introductory call has been completed.
                </Paragraph>
            </Box>
        </ConfirmationDialog>
    );
};

export default CandidateProfileApproveModal;
