import { AddToJobPayloadType, QueryParamType } from 'src/modal';
import { axiosInstance } from 'src/utilities/axiosInstance';
import { buildQueryString } from 'src/utilities/helpers';

const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

// fetch candidates
export const fetchCandidates = (queryParams: QueryParamType[]) => {
    const queryString = buildQueryString(queryParams);
    const url = queryString
        ? `${API_URL}/v1/admin/candidates?${queryString}`
        : `${API_URL}/v1/admin/candidates`;
    return axiosInstance.get(url);
};

// fetch candidate approvals
export const fetchCandidatesApprovals = () => {
    return axiosInstance.get(`${API_URL}/v1/admin/candidates/approval-requests`);
};

// approve candidate profile
export const approveCandidateProfileApi = (candidateId: string) => {
    return axiosInstance.post(
        `${API_URL}/v1/admin/candidates/${candidateId}/feature/approve`,
    );
};

// reject candidate profile
export const rejectCandidateProfileApi = (candidateId: string, reasonCode: string) => {
    return axiosInstance.post(
        `${API_URL}/v1/admin/candidates/${candidateId}/feature/reject?reasonCode=${reasonCode}`,
    );
};

// reset candidate profile's status
export const resetCandidateProfileStatusApi = (candidateId: string) => {
    return axiosInstance.post(
        `${API_URL}/v1/admin/candidates/${candidateId}/feature/reset`,
    );
};

// get candidate details
export const getCandidateDetails = (candidateId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/candidates/${candidateId}`);
};

// set candidate experience level
export const updateCandidateExperienceLevel = (
    candidateId: string,
    experienceLevel: number,
) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/candidates/${candidateId}/rank/experience-level`,
        {
            experienceLevel,
        },
    );
};

// set candidate profile to top talent
export const setCandidateRank = (candidateId: string) => {
    return axiosInstance.put(
        `${API_URL}/v1/admin/candidates/${candidateId}/rank/top-talent`,
    );
};

export const addCandidateToJob = (payload: AddToJobPayloadType) => {
    return axiosInstance.post(`${API_URL}/v1/admin/job-applications`, {
        ...payload,
    });
};

// get notes on candidate profile
export const fetchCandidatesProfileNote = (candidateId: string) => {
    return axiosInstance.get(
        `${API_URL}/v1/admin/candidates/${candidateId}/internal-notes`,
    );
};

// add a note on candidate profile
export const addCandidateProfileNoteAPi = (candidateId: string, note: string) => {
    return axiosInstance.post(
        `${API_URL}/v1/admin/candidates/${candidateId}/internal-notes`,
        {
            note,
        },
    );
};

// get candidate assessments results list
export const fetchCandidateAssessments = (candidateId: string) => {
    return axiosInstance.get(`${API_URL}/v1/admin/assessments/results/${candidateId}`);
};
