import { ReactComponent as DashboardIcon } from 'src/assets/vectors/sidebar/dashboard.svg';
import { ReactComponent as AssessmentsIcon } from 'src/assets/vectors/sidebar/assessments.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/vectors/sidebar/settings.svg';
import { ReactComponent as CandidatesIcon } from 'src/assets/vectors/sidebar/candidates.svg';
import { ReactComponent as JobsIcon } from 'src/assets/vectors/sidebar/case-icon.svg';
import { ReactComponent as AnalyticsIcon } from 'src/assets/vectors/sidebar/analytics.svg';
import { ReactComponent as HelpIcon } from 'src/assets/vectors/sidebar/help.svg';
import { ReactComponent as FeedbackIcon } from 'src/assets/vectors/sidebar/feedback.svg';
import { ReactComponent as CheckIcon } from 'src/assets/vectors/commons/check-square-icon.svg';
import { ReactComponent as CheckListIcon } from 'src/assets/vectors/commons/check-list-icon.svg';
import { ReactComponent as SettingTuneIcon } from 'src/assets/vectors/commons/fine-tune-icon.svg';
import { ReactComponent as MirrorLeftIcon } from 'src/assets/vectors/commons/mirror-left-icon.svg';
import { ReactComponent as UserIdIcon } from 'src/assets/vectors/sidebar/user-id.svg';

export const primaryNavItems = [
    {
        Icon: DashboardIcon,
        to: '/dashboard',
        title: 'Dashboard',
    },
    {
        Icon: AssessmentsIcon,
        to: '/assessments',
        title: 'Assessments',
    },
    {
        Icon: CandidatesIcon,
        to: '/candidates/search',
        title: 'Candidates',
        nestedItems: [
            {
                title: 'Search',
                to: '/candidates/search',
            },
            {
                title: 'Approvals',
                to: '/candidates/approvals',
            },
        ],
    },
    {
        Icon: JobsIcon,
        to: '/jobs/listing',
        title: 'Jobs',
        nestedItems: [
            {
                title: 'Listing',
                to: '/jobs/listing',
            },
            {
                title: 'Search',
                to: '/jobs/search',
            },
        ],
    },
    {
        Icon: UserIdIcon,
        to: '/manage-users',
        title: 'Users',
    },
    {
        Icon: AnalyticsIcon,
        to: '/analytics',
        title: 'Analytics',
    },
    {
        Icon: SettingsIcon,
        to: '/settings',
        title: 'Settings',
    },
];

export const secondaryNavItems = [
    {
        Icon: HelpIcon,
        to: '/help',
        title: 'Help',
    },
    {
        Icon: FeedbackIcon,
        to: '/feedback',
        title: 'Feedback',
    },
];
// Define QuestionIcons object
interface QuestionIconsType {
    [key: number]: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
}

export const QuestionIcons: QuestionIconsType = {
    1: CheckListIcon,
    2: CheckIcon,
    3: MirrorLeftIcon,
    4: MirrorLeftIcon,
    5: SettingTuneIcon,
};
