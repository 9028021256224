import { Box, color, spacing } from '@pelpr/pelpr-ui';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const JobOverviewTabSkeleton = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing(4),
                }}>
                <Skeleton width={88} height={88} borderRadius={5} />
                <Box>
                    <Skeleton width={290} height={28} />

                    <Box
                        sx={{
                            marginTop: spacing(1),
                        }}>
                        <Skeleton width={174} height={20} />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: spacing(2),
                            marginTop: spacing(3),
                        }}>
                        {[1, 2, 3].map((location, index) => {
                            return (
                                <React.Fragment key={location}>
                                    <Skeleton width={50} height={20} />
                                    {/* If NOT the last element, show the dot */}
                                    {index + 1 < 3 && (
                                        <Box
                                            sx={{
                                                width: '4px',
                                                height: '4px',
                                                borderRadius: '50%',
                                                backgroundColor: color.midNeutral300,
                                            }}></Box>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            <Box
                sx={{
                    width: '100%',
                }}>
                <Box sx={{ marginBottom: spacing(6) }}>
                    <Skeleton width={86} height={24} />
                    <Box sx={{ marginTop: spacing(2) }}>
                        <Skeleton count={2.5} />
                    </Box>
                    <Box sx={{ marginTop: spacing(6) }}>
                        <Skeleton count={1.4} />
                    </Box>
                </Box>
                <Box sx={{ marginBottom: spacing(6) }}>
                    <Skeleton width={86} height={24} />
                    <Box sx={{ marginTop: spacing(2) }}>
                        <Skeleton count={2.5} />
                    </Box>
                    <Box sx={{ marginTop: spacing(6) }}>
                        <Skeleton count={1.4} />
                    </Box>
                </Box>
                <Box sx={{ marginBottom: spacing(6) }}>
                    <Skeleton width={86} height={24} />
                    <Box sx={{ marginTop: spacing(2) }}>
                        <Skeleton count={2.5} />
                    </Box>
                    <Box sx={{ marginTop: spacing(6) }}>
                        <Skeleton count={1.4} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default JobOverviewTabSkeleton;
