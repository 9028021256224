import { useState } from 'react';
import { Box, Button, Headline, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { CourseType } from 'src/modal/candidate';

import { ReactComponent as CourseIcon } from 'src/assets/vectors/commons/course-icon.svg';

interface CandidateCoursesProps {
    courses: CourseType[];
}

const CandidateCourses = ({ courses }: CandidateCoursesProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const coursesList = expanded ? courses : courses?.slice(0, 3);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
            }}>
            <Headline variant='h6'>Courses</Headline>
            {coursesList?.map((course, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <CourseIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    <Paragraph size='regular' weight='semi-bold'>
                        {course?.title}
                    </Paragraph>
                </Box>
            ))}
            {coursesList?.length > 3 && (
                <Button
                    variant='link'
                    sx={{
                        margin: '0 auto',
                    }}
                    onClick={handleExpandClick}>
                    {expanded ? 'See less' : 'See more'}
                </Button>
            )}
        </Box>
    );
};

export default CandidateCourses;
