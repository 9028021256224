import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Box,
    Input,
    Paragraph,
    StepperNavigator,
    Paper,
    color,
    spacing,
    Textarea,
    Select,
    SectionHeader,
    Button,
} from '@pelpr/pelpr-ui';
import { AssessmentDataType } from 'src/modal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { updateAssessment } from 'src/redux/assessments/assessmentsApi';
import styled from '@emotion/styled';
//icons
import IdeaIcon from 'src/assets/vectors/commons/idea-icon.svg';
import EditAssessmentImageModal from './EditAssessmentImageModal';
import { DescriptionRegex } from 'src/utilities/helpers';

const PlaceholderContainer = styled.div`
    position: relative;
    width: 88px;
    height: 88px;
    min-height: 88px;
    min-width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: '5px';
    overflow: visible;
    z-index: 1;
    border: 1px solid ${color.lightNeutral500};
`;

const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Title is required')
        .min(4, 'Title must be at least 4 characters')
        .max(64, 'Title can be at most 64 characters'),
    summary: yup
        .string()
        .required('Summary is required')
        .min(4, 'Summary must be at least 4 characters')
        .max(512, 'Summary can be at most 512 characters')
        .matches(
            DescriptionRegex,
            'Summary can only contain alphanumeric characters, spaces, and these special characters: : , . # - \' " ! ? & % = _ + / () ;',
        ),
    quizCategory: yup.number().required('Category is required'),
});

const initialValues = {
    name: '',
    summary: '',
    quizCategory: 1,
};

interface BasicInformationProps {
    contentType: 'tab' | 'step';
    steps?: number;
    currentStep?: number;
    onPrevious?: () => void;
    assessmentData: AssessmentDataType | AssessmentDataType;
    onSubmit: (
        assessmentData: AssessmentDataType | AssessmentDataType,
        step?: number,
    ) => void;
}

const AssessmentBasicInfo = ({
    contentType,
    steps,
    currentStep,
    onPrevious,
    assessmentData,
    onSubmit,
}: BasicInformationProps) => {
    const { assessment, assessmentUpdating, assessmentImageUploaded } = useAppSelector(
        (state) => state.assessments,
    );
    const { assessmentCategories } = useAppSelector((state) => state.app.lookups);
    const [imageLoadError, setImageLoadError] = useState(false);
    const [isEditAssessmentImage, setIsEditAssessmentImage] = useState(false);

    useEffect(() => {
        imageLoadError && setImageLoadError(false);
    }, [assessmentImageUploaded]);

    const assessmentImage = `${assessment?.imageUrl}?nocache=${Date.now()}`;

    const dispatch = useAppDispatch();

    const toggleEditAssessmentImageModal = () => {
        setIsEditAssessmentImage(!isEditAssessmentImage);
    };

    const handleImageError = () => {
        setImageLoadError(true);
    };

    const onFormSubmit = (values: typeof initialValues) => {
        if (contentType === 'step') {
            onSubmit(
                {
                    ...assessmentData,
                    ...values,
                    quizCategory: Number(values?.quizCategory),
                },
                2,
            );
        } else if (contentType === 'tab') {
            dispatch(
                updateAssessment({
                    assessmentData: {
                        ...(assessmentData as AssessmentDataType),
                        ...values,
                        quizCategory: Number(values?.quizCategory),
                    },
                }),
            );
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: onFormSubmit,
    });

    useEffect(() => {
        formik.setFieldValue('name', assessmentData?.name || '');
        formik.setFieldValue('summary', assessmentData?.summary || '');
        formik.setFieldValue('quizCategory', assessmentData?.quizCategory || 1);
    }, [assessmentData]);

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: spacing(8),
            }}>
            <Paper
                sx={{
                    width: '100%',
                    padding: spacing(6),
                    marginBottom: spacing(6.5),
                }}>
                <SectionHeader
                    title='Basic Information'
                    subtitle=' Name and describe the assessment so that candidates can understand
                    it.'
                />

                {contentType === 'tab' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gpa: spacing(1),
                        }}>
                        <Paragraph weight='medium'>Assessment Image</Paragraph>
                        <PlaceholderContainer>
                            {!imageLoadError && assessment && assessment.imageUrl ? (
                                <img
                                    alt='Assessment Photo'
                                    src={assessmentImage}
                                    width={88}
                                    height={88}
                                    style={{
                                        borderRadius: '5px',
                                        objectFit: 'cover',
                                    }}
                                    onError={handleImageError}
                                />
                            ) : (
                                <img
                                    alt='Assessment Photo'
                                    src={IdeaIcon}
                                    width={88}
                                    height={88}
                                    style={{
                                        borderRadius: '5px',
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    right: -10,
                                    bottom: 0,
                                    zIndex: 10,
                                    backgroundColor: 'transparent',

                                    overflow: 'hidden',
                                    height: spacing(7),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Button
                                    icon='edit'
                                    size='xs'
                                    variant='secondary'
                                    title=''
                                    onClick={toggleEditAssessmentImageModal}
                                />
                            </Box>
                        </PlaceholderContainer>
                    </Box>
                )}
                <Paragraph
                    sx={{ color: color.darkNeutral300, margin: `${spacing(6)} 0` }}>
                    * indicates required fields
                </Paragraph>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: spacing(6),
                        maxWidth: '470px',
                    }}>
                    <Input
                        fullWidth
                        label='Title'
                        placeholder='Eg. Frontend Test'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={
                            (formik.touched.name && (formik.errors.name as string)) || ''
                        }
                        disabled={assessmentUpdating}
                        required
                    />
                    <Textarea
                        fullWidth
                        label='Summary'
                        rows={3}
                        name='summary'
                        value={formik.values.summary}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                            formik.touched.summary && Boolean(formik.errors.summary)
                        }
                        helperText={
                            (formik.touched.summary &&
                                (formik.errors.summary as string)) ||
                            ''
                        }
                        disabled={assessmentUpdating}
                        required
                    />
                    <Select
                        label='Category'
                        name='quizCategory'
                        options={assessmentCategories}
                        selectedOptions={[formik.values.quizCategory?.toString()]}
                        onChange={(value) =>
                            formik.setFieldValue('quizCategory', value[0])
                        }
                        disabled={assessmentUpdating}
                    />
                    {contentType === 'tab' && (
                        <Button
                            sx={{
                                width: 'max-content',
                                marginTop: spacing(2.5),
                                marginBottom: spacing(2.5),
                            }}
                            onClick={formik.handleSubmit}
                            loading={assessmentUpdating}
                            disabled={assessmentUpdating}>
                            Save
                        </Button>
                    )}
                </Box>
            </Paper>
            {contentType === 'step' && (
                <StepperNavigator
                    loading={assessmentUpdating}
                    steps={steps || 1}
                    currentStep={currentStep || 1}
                    previousButtonLabel='Cancel'
                    onPrevious={onPrevious ? onPrevious : () => {}}
                    onNext={formik.handleSubmit}
                />
            )}
            {isEditAssessmentImage && (
                <EditAssessmentImageModal
                    open={isEditAssessmentImage}
                    onClose={toggleEditAssessmentImageModal}
                />
            )}
        </Box>
    );
};

export default AssessmentBasicInfo;
