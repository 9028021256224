import {
    fetchAllQuestions,
    fetchQuestion,
    addQuestion,
    updateQuestionRecord,
    deleteQuestion,
} from 'src/services/question';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuestionDataType } from 'src/modal';
import { toast } from 'react-toastify';

export const getAllQuestions = createAsyncThunk(
    'question/getAllQuestions',
    async (payload: { quizId: string; onSuccess?: () => void }) => {
        try {
            const response = await fetchAllQuestions(payload?.quizId);
            payload.onSuccess && payload.onSuccess();
            return response?.data?.questions;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            throw error.response.data;
        }
    },
);

export const getQuestion = createAsyncThunk(
    'question/getQuestion',
    async ({ quizId, questionId }: { quizId: string; questionId: string }) => {
        try {
            const response = await fetchQuestion(quizId, questionId);
            return response?.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Failed to fetch question.');
            throw error.response.data;
        }
    },
);

export const addNewQuestion = createAsyncThunk(
    'question/addNewQuestion',
    async (
        payload: {
            quizId: string;
            questionData: QuestionDataType;
            onSuccess: (id: number) => void;
        },
        thunkAPI,
    ) => {
        const { quizId, questionData, onSuccess } = payload;
        try {
            const response = await addQuestion(quizId, questionData);
            toast.success('Question added.');
            thunkAPI.dispatch(
                getAllQuestions({
                    quizId,
                }),
            );
            onSuccess(response.data.id);
            return response.data;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            const ErrorMessages = Object.values(error.response.data.errors)?.join('\n');
            toast.error(`Question add failed. \n${ErrorMessages}`);
            throw error.response.data;
        }
    },
);

export const updateQuestion = createAsyncThunk(
    'question/updateQuestion',
    async (
        payload: {
            quizId: string;
            questionData: QuestionDataType;
        },
        thunkAPI,
    ) => {
        try {
            const { id, ...restQuestionData } = payload.questionData;
            await updateQuestionRecord(payload.quizId, `${id}`, restQuestionData);
            toast.success('Question updated.');
            thunkAPI.dispatch(
                getAllQuestions({
                    quizId: payload?.quizId,
                }),
            );
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            const ErrorMessages = Object.values(error.response.data.errors)
                ?.flat()
                ?.join('\n');

            toast.error(`Question update failed. \n${ErrorMessages}`);
            throw error.response.data;
        }
    },
);

export const removeQuestion = createAsyncThunk(
    'question/removeQuestion',
    async (payload: { quizId: string; questionId: number; onSuccess: () => void }) => {
        try {
            await deleteQuestion(payload.quizId, `${payload.questionId}`);
            toast.success('Question deleted.');
            payload.onSuccess();
            return payload.questionId;

            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            toast.error('Question delete failed.');
            throw error.response.data;
        }
    },
);
