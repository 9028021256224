import { useState, useMemo } from 'react';
import { ActionMenu, Badge, Box, Button, spacing } from '@pelpr/pelpr-ui';
import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { isEmpty } from 'src/utilities/helpers';
import { ApprovalRequestDataType } from 'src/modal';
import { useAppSelector } from 'src/hooks';
import CandidateProfileApproveModal from './CandiateProfileApproveModal';
import CandidateProfileRejectModal from './CandidateProfileRejectModal';
import CandidateProfileStatusResetModal from './CandidateProfileStatusResetModal';

//icons
import { ReactComponent as CrossIcon } from 'src/assets/vectors/commons/cross-circle-icon.svg';
import { ReactComponent as CheckIcon } from 'src/assets/vectors/commons/check-circle-icon.svg';
import { ReactComponent as ResetIcon } from 'src/assets/vectors/commons/reset-icon.svg';
import Table from '../common/tables/table';
import { formatToReadableDate } from 'src/utilities/date';

const Labels = {
    Pending: <Badge variant='orange' text='Pending' />,
    Rejected: <Badge variant='red' text='Rejected' />,
    Approved: <Badge variant='neonBlue' text='Approved' />,
};

type modalType = 'approved' | 'rejected' | 'reset';

interface CandidateApprovalsTableProps {
    statusFilter: string;
}

const CandidateApprovalsTable = ({}: CandidateApprovalsTableProps) => {
    const { candidatesApprovals, updatingCandidateProfileStatus } = useAppSelector(
        (state) => state.candidate,
    );

    const [openedMenu, setOpenedMenu] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [candidateData, setCandidateData] = useState<{
        id: string;
        type: modalType;
    } | null>(null);

    const handleConfirmModalOpen = (id: string, type: modalType) => {
        setCandidateData({
            id,
            type,
        });
        setShowConfirmModal(true);
    };

    const handleClose = () => {
        setCandidateData(null);
        setShowConfirmModal(false);
    };

    const statusFilterFn: FilterFn<ApprovalRequestDataType> = (
        row,
        columnId,
        filterValue,
    ) => {
        if (!isEmpty(filterValue)) {
            if (filterValue.includes(row.getValue(columnId))) return true;
            else return false;
        }
        return true;
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const columns = useMemo<ColumnDef<ApprovalRequestDataType, any>[]>(
        () => [
            {
                accessorFn: (row) => row.name,
                id: 'name',
                cell: (info) => (
                    <Box
                        sx={{
                            minWidth: '150px',
                            textOverflow: 'wrap',
                        }}>
                        {info.getValue()}
                    </Box>
                ),
                header: 'Name',
                accessorKey: 'name',
                size: 200,
            },
            {
                accessorFn: (row) => row.email,
                id: 'email',
                cell: (info) => (
                    <Box
                        sx={{
                            maxWidth: '300px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {info.getValue()}
                    </Box>
                ),
                header: 'Email',
                accessorKey: 'email',
                size: 300,
            },
            {
                accessorFn: (row) =>
                    row?.status === 'Pending'
                        ? row?.submittedOn
                        : row?.status === 'Approved'
                          ? row?.approvedOn
                          : row?.rejectedOn,
                id: 'date',
                cell: (info) => (
                    <Box
                        sx={{
                            minWidth: '250px',
                            textOverflow: 'wrap',
                        }}>
                        {formatToReadableDate(info.getValue())}
                    </Box>
                ),
                header: 'Date',
                accessorKey: 'date',
                size: 150,
            },
            {
                accessorFn: (row) => row?.status,
                id: 'status',
                header: 'Status',
                cell: (info) => Labels[info?.getValue() as keyof typeof Labels],
                filterFn: statusFilterFn,
                filterable: true,
                enableSorting: false,
                accessorKey: 'status',
                size: 100,
            },
            {
                accessorFn: (row) => [row?.id, row?.status],
                id: 'actions',
                header: () => 'Actions',
                size: 100,
                enableSorting: false,
                cell: (info) => {
                    const value = [...info.getValue()];
                    const id = value[0];
                    const status = value[1];
                    const isDisabled = status === 'Approved' || status === 'Rejected';
                    return (
                        <ActionMenu
                            isOpen={openedMenu === id}
                            alignment='bottom-left'
                            openMenuHandler={() => setOpenedMenu(id)}
                            closeMenuHandler={(event: MouseEvent | TouchEvent) => {
                                const isMenuIcon = (
                                    event?.target as unknown as HTMLInputElement
                                )?.classList.contains('open-menu');

                                if (id === openedMenu && !isMenuIcon) setOpenedMenu('');
                            }}>
                            <Button
                                sx={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                }}
                                variant='ghost'
                                icon='view'
                                href={`/candidates/${id}`}
                                disabled={updatingCandidateProfileStatus}>
                                View
                            </Button>
                            <Button
                                disabled={isDisabled || updatingCandidateProfileStatus}
                                variant='ghost'
                                CustomIcon={CheckIcon}
                                onClick={() => handleConfirmModalOpen(id, 'approved')}>
                                Approve
                            </Button>
                            <Button
                                variant='ghost'
                                CustomIcon={CrossIcon}
                                disabled={isDisabled || updatingCandidateProfileStatus}
                                onClick={() => handleConfirmModalOpen(id, 'rejected')}>
                                Reject
                            </Button>
                            <Button
                                variant='ghost'
                                CustomIcon={ResetIcon}
                                disabled={updatingCandidateProfileStatus}
                                onClick={() => handleConfirmModalOpen(id, 'reset')}>
                                Reset
                            </Button>
                        </ActionMenu>
                    );
                },
            },
        ],
        [openedMenu],
    );

    return (
        <Box
            sx={{
                minWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
            }}>
            <Table data={candidatesApprovals} columns={columns} filters={[]} />
            {showConfirmModal && candidateData && candidateData?.type === 'approved' && (
                <CandidateProfileApproveModal
                    open={showConfirmModal}
                    onClose={handleClose}
                    candidateId={candidateData?.id}
                />
            )}
            {showConfirmModal && candidateData && candidateData?.type === 'rejected' && (
                <CandidateProfileRejectModal
                    open={showConfirmModal}
                    onClose={handleClose}
                    candidateId={candidateData?.id}
                />
            )}
            {showConfirmModal && candidateData && candidateData?.type === 'reset' && (
                <CandidateProfileStatusResetModal
                    open={showConfirmModal}
                    onClose={handleClose}
                    candidateId={candidateData?.id}
                />
            )}
        </Box>
    );
};

export default CandidateApprovalsTable;
