import axios from 'axios';
import { handleSignOut, refreshAuthToken } from 'src/providers/AuthProvider';
import { v4 as uuid } from 'uuid';

const axiosInstance = axios.create({
    baseURL: process.env.apiGatewayUrl,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-PL-RequestId': uuid(),
        'X-PL-ApplicationId': process.env.REACT_APP_COGNITO_CLIENT_ID,
        'X-PL-CorrelationId': uuid(),
    },
});

axiosInstance.defaults.headers.get.Accept = 'application/json';
axiosInstance.defaults.headers.post.Accept = 'application/json';

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
    'accessToken',
)}`;

// Add an Axios response interceptor to handle 401 responses
axiosInstance.interceptors.response.use(
    (response) => {
        // If the response is successful, just return it as-is
        return response;
    },
    async (error) => {
        // If the response status is 401 and there's no 'config._retry' flag (to avoid infinite loops)
        if (error.response?.status === 401 && !error.config._retry) {
            try {
                // Refresh the token
                await refreshAuthToken();

                // Set a flag to avoid infinite loops and retry the original request
                error.config._retry = true;
                return axiosInstance && axiosInstance(error.config);
            } catch (refreshError) {
                // Handle errors that might occur during token refresh (e.g., token refresh failure)
                // You might want to log the error or perform some other action here
                handleSignOut();
                throw refreshError;
            }
        }

        // For other error responses, just reject the promise
        return Promise.reject(error);
    },
);

// Add a request interceptor to update the Authorization header with the token.
axiosInstance.interceptors.request.use(
    (config) => {
        // Get the access token from localStorage or wherever you store it.
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            // Set the Authorization header with the access token.
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const setAuthToken = (token: string): void => {
    if (axiosInstance) {
        if (token) {
            // applying token
            axiosInstance.defaults.headers.common.Authorization = token;
        } else {
            // deleting the token from header
            delete axiosInstance.defaults.headers.common.Authorization;
        }
    }
};

export { axiosInstance, setAuthToken };
