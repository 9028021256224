import { useEffect, useMemo, useState } from 'react';
import {
    Paper,
    Box,
    spacing,
    DebounceInput,
    Button,
    FilterSelect,
    Paragraph,
    color,
    BaseSelect,
} from '@pelpr/pelpr-ui';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { QuestionListDataType } from 'src/modal';
import QuestionListItem from './QuestionListItem';
import { setAddEditQuestionMode } from 'src/redux/question/questionSlice';
import Skeleton from 'react-loading-skeleton';

const QuestionsList = () => {
    const { loading, activeQuestionId, questions } = useAppSelector(
        (state) => state.question,
    );

    const [searchValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState<string[]>(['Published', 'Unpublished']);
    const [sortOption, setSortOption] = useState('Newest First');
    const [filteredQuestions, setFilteredQuestions] = useState<QuestionListDataType[]>(
        questions || [],
    );
    const dispatch = useAppDispatch();

    const filterQuestions = (questions: QuestionListDataType[]) => {
        return questions.filter((question) => {
            const matchesFilter =
                filters.length !== 1 ||
                (filters[0] === 'Published'
                    ? question.isPublished
                    : !question.isPublished);

            const matchesSearch =
                !searchValue.trim() ||
                question.text?.toLowerCase().includes(searchValue.toLowerCase());

            return matchesFilter && matchesSearch;
        });
    };

    const sortQuestions = (questions: QuestionListDataType[]) => {
        const sortFunctions: Record<
            string,
            (a: QuestionListDataType, b: QuestionListDataType) => number
        > = {
            'Newest First': (a, b) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
            'Oldest First': (a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            'Recently updated': (a, b) =>
                new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        };

        return questions.sort(sortFunctions[sortOption]);
    };

    const updateFilteredQuestions = () => {
        const filtered = filterQuestions(questions);
        const sorted = sortQuestions(filtered);
        setFilteredQuestions(sorted);
    };

    useEffect(() => {
        updateFilteredQuestions();
    }, [questions, searchValue, filters, sortOption]);

    useEffect(() => {
        const activeQuestionItem = document.getElementById(
            `question-${activeQuestionId}`,
        );
        if (activeQuestionItem) {
            activeQuestionItem.scrollIntoView({ behavior: 'smooth' });
        }
    }, [questions, activeQuestionId]);

    const handleSearch = (search: string) => {
        setSearchValue(search);
    };

    const handleSort = (sortType: string) => {
        setSortOption(sortType);
    };

    const handleStatus = (statuses: string[]) => {
        setFilters(statuses);
    };

    const sortOptions = useMemo(
        () => [
            { label: 'Oldest first', value: 'Oldest First' },
            { label: 'Newest first', value: 'Newest First' },
            { label: 'Recently updated', value: 'Recently updated' },
        ],
        [],
    );

    const handleAddNewQuestion = () => {
        dispatch(
            setAddEditQuestionMode({ activeQuestionId: null, questionEditMode: 'add' }),
        );
    };

    return (
        <Paper
            sx={{
                width: '100%',
                maxWidth: '380px',
                minHeight: '490px',
                marginBottom: spacing(6),
            }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(4.5),
                    padding: `${spacing(4)} ${spacing(6)}`,
                }}>
                <DebounceInput
                    sx={{ width: '100%', marginBottom: 0 }}
                    size='small'
                    value={searchValue}
                    onChange={(value) => handleSearch(String(value))}
                />
                <Button
                    icon='add'
                    variant='secondary'
                    size='lg'
                    onClick={handleAddNewQuestion}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: spacing(4.5),
                    padding: `0px ${spacing(6)} ${spacing(4)} ${spacing(6)}`,
                    borderBottom: `1px solid ${color.lightNeutral500}`,
                }}>
                <BaseSelect
                    borderless={true}
                    name='list-sort'
                    options={sortOptions}
                    defaultSelected={sortOption}
                    onChange={(value) => handleSort(value)}
                    sx={{ ul: { minWidth: '180px' } }}
                />
                <Box sx={{ maxWidth: '150px' }}>
                    <FilterSelect
                        size='small'
                        uniqueKey='status'
                        label='Status'
                        alignment='right'
                        defaultSelected={filters}
                        onApply={handleStatus}
                        filters={['Published', 'Unpublished']}
                    />
                </Box>
            </Box>
            {loading && (
                <Box sx={{ width: '100%', height: '100%' }}>
                    {[1, 2, 3, 4].map((item) => (
                        <Skeleton key={item} height={96} />
                    ))}
                </Box>
            )}
            {!loading && questions.length === 0 && (
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        padding: `${spacing(12)} ${spacing(2.5)} ${spacing(2.5)} ${spacing(2.5)}`,
                    }}>
                    <Paragraph sx={{ color: color.midNeutral500 }}>
                        Click + to add a question.
                    </Paragraph>
                </Box>
            )}
            {!loading && questions.length > 0 && filteredQuestions.length > 0 && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '90vh',
                        overflowY: 'scroll',
                    }}>
                    {filteredQuestions.map((question) => (
                        <QuestionListItem key={question.id} question={question} />
                    ))}
                </Box>
            )}
        </Paper>
    );
};

export default QuestionsList;
