import {
    Box,
    Headline,
    List,
    ListItemType,
    Paper,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { Countries } from 'src/config/lookups';
import { useAppSelector } from 'src/hooks';

//icons
import CandidateWork from './CandidateWork';
import CandidateQualifications from './CandidateQualifications';
import CandidateCourses from './CandidateCourses';
import CandidateAwards from './CandidateAwards';
import CandidateProjects from './CandidateProjects';
import CandidatePublications from './CandidatePublications';
import { roundNumber } from 'src/utilities/formattingHelpers';

const CandidateProfileTab = () => {
    const { candidateDetails } = useAppSelector((state) => state.candidate);

    const jobPreferences = candidateDetails?.jobPreferences;
    const locationPreferences: ListItemType[] = [
        {
            text: 'On-Site',
            icon: jobPreferences?.openToOffice ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Hybrid',
            icon: jobPreferences?.openToHybrid ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Remote',
            icon: jobPreferences?.openToRemote ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
    ];

    const positionPreferences: ListItemType[] = [
        {
            text: 'Permanent',
            icon: jobPreferences?.fullTime ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Part-Time',
            icon: jobPreferences?.partTime ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Contract',
            icon: jobPreferences?.contract ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Temporary',
            icon: jobPreferences?.temporary ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
        {
            text: 'Internship',
            icon: jobPreferences?.internship ? 'check-circle' : 'cross-circle',
            iconColor: color.darkNeutral300,
        },
    ];

    const currencyCode = Countries?.find(
        (empType) => empType?.label === candidateDetails?.country,
    )?.currencyCode;

    const salaryPreference =
        jobPreferences?.preferredSalary && jobPreferences?.preferredHourlyRate
            ? `${roundNumber(jobPreferences?.preferredSalary).toLocaleString(
                  'en-IN',
              )}/yr | ${roundNumber(jobPreferences?.preferredSalary / 12).toLocaleString(
                  'en-IN',
              )}/month | ${roundNumber(
                  jobPreferences?.preferredHourlyRate,
                  2,
              ).toLocaleString('en-IN')}/hr (${currencyCode})`
            : 'No salary preferences set';

    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
                borderRadius: 0,
                borderBottomLeftRadius: spacing(1.25),
                borderBottomRightRadius: spacing(1.25),
                padding: `${spacing(6)} `,
            }}>
            <Headline variant='h6'>Preferences</Headline>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    gap: spacing(6),
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <List
                        title='Tech Stack'
                        items={
                            jobPreferences?.technologies?.map((item) => ({
                                text: item,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            })) as ListItemType[]
                        }
                    />
                    <List
                        title='Company Profile'
                        items={
                            jobPreferences?.companyProfile?.map((item) => ({
                                text: item,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            })) as ListItemType[]
                        }
                    />
                    <List
                        title='Company Size'
                        items={
                            jobPreferences?.companySize?.map((item) => ({
                                text: item,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            })) as ListItemType[]
                        }
                    />
                    <List
                        title='Company Industry'
                        items={
                            jobPreferences?.companyIndustry?.map((item) => ({
                                text: item,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            })) as ListItemType[]
                        }
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(4),
                    }}>
                    <List
                        title='Location'
                        items={
                            jobPreferences?.preferredLocations?.map((item) => ({
                                text: item,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            })) as ListItemType[]
                        }
                    />
                    <List
                        title='Work Location'
                        items={locationPreferences as ListItemType[]}
                    />
                    <List
                        title='Position Type'
                        items={positionPreferences as ListItemType[]}
                    />

                    <List
                        title='Salary'
                        items={[
                            {
                                text: salaryPreference,
                                icon: 'check-circle',
                                iconColor: color.darkNeutral300,
                            },
                        ]}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: color.lightNeutral500,
                }}
            />
            {candidateDetails?.employments &&
                candidateDetails?.employments?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '2px',
                                backgroundColor: color.lightNeutral500,
                            }}
                        />
                        <CandidateWork employments={candidateDetails?.employments} />
                    </>
                )}
            {candidateDetails?.qualifications &&
                candidateDetails?.qualifications?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '2px',
                                backgroundColor: color.lightNeutral500,
                            }}
                        />
                        <CandidateQualifications
                            qualifications={candidateDetails?.qualifications}
                        />
                    </>
                )}
            {candidateDetails?.courses && candidateDetails?.courses?.length > 0 && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: color.lightNeutral500,
                        }}
                    />
                    <CandidateCourses courses={candidateDetails?.courses} />
                </>
            )}
            {candidateDetails?.awards && candidateDetails?.awards?.length > 0 && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: color.lightNeutral500,
                        }}
                    />
                    <CandidateAwards awards={candidateDetails?.awards} />
                </>
            )}
            {candidateDetails?.projects && candidateDetails?.projects?.length > 0 && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: color.lightNeutral500,
                        }}
                    />
                    <CandidateProjects projects={candidateDetails?.projects} />
                </>
            )}
            {candidateDetails?.publications &&
                candidateDetails?.publications?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '2px',
                                backgroundColor: color.lightNeutral500,
                            }}
                        />
                        <CandidatePublications
                            publications={candidateDetails?.publications}
                        />
                    </>
                )}
        </Paper>
    );
};

export default CandidateProfileTab;
