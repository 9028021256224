import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import appReducer from './app/appSlice';
import userReducer from './user/userSlice';
import quizzesReducer from './assessments/assessmentsSlice';
import questionReducer from './question/questionSlice';
import candidateReducer from './candidate/candidateSlice';
import jobReducer from './job/jobSlice';

const storage = createWebStorage('local');

const authPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['authenticatedUserInfo'],
};

const rootReducer = combineReducers({
    app: appReducer,
    user: persistReducer(authPersistConfig, userReducer),
    assessments: quizzesReducer,
    question: questionReducer,
    candidate: candidateReducer,
    job: jobReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
