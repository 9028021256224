import { useState, useEffect } from 'react';
import {
    Box,
    Headline,
    Paper,
    Paragraph,
    ScrollArea,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import NoteInput from 'src/components/common/inputs/NoteInput';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    addCandidateProfileNote,
    getCandidateProfileNote,
} from 'src/redux/candidate/candidateApi';
import CandidateNoteCard from './CandidateNoteCard';
import CandidateNoteCardSkeleton from './CandidateNoteCardSkeleton';

const CandidateNotesTab = () => {
    const { candidateDetails, notes, fetchingNotes, addingNotes } = useAppSelector(
        (state) => state.candidate,
    );
    const [noteInput, setNoteInput] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        candidateDetails?.id &&
            dispatch(
                getCandidateProfileNote({
                    candidateId: candidateDetails?.id,
                }),
            );
    }, []);
    const handleAddNote = () => {
        candidateDetails?.id &&
            dispatch(
                addCandidateProfileNote({
                    candidateId: candidateDetails?.id,
                    note: noteInput,
                    onSuccess: () => setNoteInput(''),
                }),
            );
    };

    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(6),
                borderRadius: 0,
                borderBottomLeftRadius: spacing(1.25),
                borderBottomRightRadius: spacing(1.25),
                padding: `${spacing(6)} `,
            }}>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(6),
                }}>
                <Box>
                    <Headline variant='h6'>Notes</Headline>
                    <Paragraph>
                        Record information about any discussion or event regarding this
                        candidate. (internal view only)
                    </Paragraph>
                </Box>
                <NoteInput
                    loading={addingNotes}
                    value={noteInput}
                    onChange={(value) => setNoteInput(value)}
                    onSend={handleAddNote}
                />
            </Box>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '100%',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    borderTop: `2.317px solid ${color.lightNeutral500}`,
                }}>
                <ScrollArea orientation='vertical' width='100%' height='90vh' type='auto'>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            paddingRight: spacing(6),
                            paddingBottom: spacing(6),
                        }}>
                        {fetchingNotes &&
                            [1, 2, 3, 4, 5].map((item) => (
                                <CandidateNoteCardSkeleton key={item} />
                            ))}
                        {!fetchingNotes &&
                            notes?.length > 0 &&
                            notes?.map((note) => (
                                <CandidateNoteCard key={note?.id} note={note} />
                            ))}
                    </Box>
                </ScrollArea>
            </Box>
        </Paper>
    );
};

export default CandidateNotesTab;
