import { useState } from 'react';
import {
    ActionMenu,
    Badge,
    Box,
    Button,
    Paper,
    Paragraph,
    color,
    spacing,
} from '@pelpr/pelpr-ui';
import { JobListDataType } from 'src/modal';
import { friendlyTime } from 'src/utilities/date';
import OrganizationPlaceholder from 'src/assets/vectors/commons/organization-placeholder.svg';

import { ReactComponent as BankNotIcon } from 'src/assets/vectors/commons/bank-note-icon.svg';
import { ReactComponent as ClockIcon } from 'src/assets/vectors/commons/clock-circle-icon.svg';
import { ReactComponent as UserGroupIcon } from 'src/assets/vectors/commons/user-group-icon.svg';
import { ReactComponent as LocationIcon } from 'src/assets/vectors/commons/location-icon.svg';

interface JobCardProps {
    jobData: JobListDataType;
    allowActionMenu?: boolean;
}

const JobCard = ({ jobData, allowActionMenu }: JobCardProps) => {
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <Paper
            sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(4),
                padding: spacing(6),
            }}>
            <img
                alt='organization logo'
                src={
                    imageLoadError
                        ? OrganizationPlaceholder
                        : jobData?.organizationLogoUrl
                }
                width={56}
                height={56}
                style={{
                    borderRadius: '5px',
                }}
                onError={handleImageError}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing(2),
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Paragraph
                        size='large'
                        weight='semi-bold'
                        sx={{
                            textWrap: 'nowrap',
                        }}>
                        {jobData?.title}
                    </Paragraph>
                    <Box
                        sx={{
                            width: spacing(1),
                            height: spacing(1),
                            backgroundColor: color.midNeutral300,
                            borderRadius: '50%',
                        }}
                    />

                    <Paragraph
                        sx={{
                            color: color.midNeutral300,
                            textWrap: 'nowrap',
                        }}>
                        {jobData?.reference}
                    </Paragraph>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(2),
                    }}>
                    <Paragraph
                        sx={{
                            textWrap: 'nowrap',
                            color: color.midNeutral300,
                        }}>
                        {jobData?.organization}
                    </Paragraph>
                    <Box
                        sx={{
                            width: spacing(1),
                            height: spacing(1),
                            backgroundColor: color.midNeutral300,
                            borderRadius: '50%',
                        }}
                    />
                    <Badge text={jobData?.jobType} variant='orange' />
                    <Badge text={jobData?.jobLevel} variant='blue' />
                    <Badge text={jobData?.workplaceType} variant='neonBlue' />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: spacing(4),
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(2),
                        }}>
                        <BankNotIcon
                            style={{
                                minWidth: spacing(5),
                                color: color.darkNeutral500,
                            }}
                        />
                        <Paragraph
                            sx={{
                                textWrap: 'nowrap',
                            }}>
                            {`$${jobData?.minimumSalary?.toLocaleString(
                                'en-IN',
                            )} - $${jobData?.maximumSalary?.toLocaleString('en-IN')}`}
                        </Paragraph>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(2),
                        }}>
                        <ClockIcon
                            style={{
                                minWidth: spacing(5),
                                color: color.darkNeutral500,
                            }}
                        />
                        <Paragraph>{friendlyTime(jobData?.postingDate)}</Paragraph>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: spacing(2),
                        }}>
                        <UserGroupIcon
                            style={{
                                minWidth: spacing(5),
                                color: color.darkNeutral500,
                            }}
                        />
                        <Paragraph
                            sx={{
                                textWrap: 'nowrap',
                            }}>{`${jobData?.jobApplicationsCount} Applications`}</Paragraph>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: spacing(2),
                        marginTop: spacing(1),
                    }}>
                    <LocationIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    {jobData?.locations?.map((location, index) => (
                        <>
                            <Paragraph key={index}>{location}</Paragraph>
                            {index !== jobData?.locations?.length - 1 && (
                                <Box
                                    sx={{
                                        width: spacing(1),
                                        height: spacing(1),
                                        backgroundColor: color.midNeutral300,
                                        borderRadius: '50%',
                                    }}
                                />
                            )}
                        </>
                    ))}
                </Box>
            </Box>
            {allowActionMenu && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        bottom: '24px',
                        right: '24px',
                    }}>
                    <ActionMenu
                        alignment='bottom-left'
                        isOpen={isUserMenuOpened}
                        openMenuHandler={() => setIsUserMenuOpened(!isUserMenuOpened)}
                        closeMenuHandler={() => setIsUserMenuOpened(false)}>
                        <Button variant='ghost' icon='view' href={`/jobs/${jobData?.id}`}>
                            View details
                        </Button>
                    </ActionMenu>
                </Box>
            )}
        </Paper>
    );
};
export default JobCard;
