import { useState } from 'react';
import { Box, Button, Headline, Paragraph, color, spacing } from '@pelpr/pelpr-ui';
import { EmploymentType } from 'src/modal/candidate';
import { format, parseJSON } from 'date-fns';

import { ReactComponent as WorkIcon } from 'src/assets/vectors/commons/breif-case-icon.svg';

interface CandidateWorkProps {
    employments: EmploymentType[];
}

const CandidateWork = ({ employments }: CandidateWorkProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const employmentsList = expanded ? employments : employments?.slice(0, 3);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: spacing(5),
            }}>
            <Headline variant='h6'>Work Experience</Headline>
            {employmentsList?.map((employment, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: spacing(6),
                    }}>
                    <WorkIcon
                        style={{
                            minWidth: spacing(5),
                            color: color.darkNeutral500,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Paragraph size='regular' weight='semi-bold'>
                            {employment?.employerName}
                        </Paragraph>
                        <Paragraph>{employment?.position}</Paragraph>
                        <Paragraph>
                            {`${format(parseJSON(employment?.startDate), 'MMM yyyy')} - ${
                                employment?.isCurrent
                                    ? 'Present'
                                    : format(parseJSON(employment?.endDate), 'MMM yyyy')
                            }`}
                        </Paragraph>

                        <Paragraph>{employment?.location}</Paragraph>
                        <Paragraph
                            sx={{
                                marginTop: spacing(2),
                            }}>
                            {employment?.description}
                        </Paragraph>
                    </Box>
                </Box>
            ))}
            {employments?.length > 3 && (
                <Button
                    variant='link'
                    sx={{
                        margin: '0 auto',
                    }}
                    onClick={handleExpandClick}>
                    {expanded ? 'See less' : 'See more'}
                </Button>
            )}
        </Box>
    );
};

export default CandidateWork;
