import { useAppSelector } from 'src/hooks';
import {
    Box,
    Paper,
    PageHeader,
    PageContainer,
    spacing,
    Button,
    SectionHeader,
} from '@pelpr/pelpr-ui';
import Skeleton from 'react-loading-skeleton';
import UsersTable from 'src/components/users/UsersTable';

const UsersList = () => {
    const { fetchingUsersAccounts } = useAppSelector((state) => state.user);

    return (
        <PageContainer
            pageHeader={<PageHeader title='Users' />}
            sx={{
                width: '100%',
                minHeight: '100vh',
                padding: 0,
                overflow: 'visible',
            }}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingLeft: spacing(6),
                    paddingRight: spacing(6),
                }}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: spacing(6),
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <SectionHeader
                            title='User Management'
                            subtitle='Create or manage platform users'
                        />

                        {fetchingUsersAccounts ? (
                            <Skeleton width={100} height={40} />
                        ) : (
                            <Button
                                icon='add'
                                variant='primary'
                                disabled={fetchingUsersAccounts}>
                                Create user
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                        }}>
                        <UsersTable />
                    </Box>
                </Paper>
            </Box>
        </PageContainer>
    );
};

export default UsersList;
